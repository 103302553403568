import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { i18n } from '@ecster/i18n';
import { formatDate } from '@ecster/util';
import { Spinner, GeneralErrorMessagePanel } from '@ecster/components';
import { useParams } from 'react-router-dom';
import * as actions from '../redux/actions';
import {
    CollectionInfoPanel,
    InvoiceDetailsPanel,
    InvoiceHeader,
    InvoiceRowsPanel,
    RemindedMessage,
} from '../one-off-invoice/v2';
import AuthenticatedSubPageTemplate from '../../common/templates/AuthenticatedSubPageTemplate';
import { DEBT_COLLECTION, DEBT_COLLECTION_COMPLETED, REMINDED } from '../invoiceStatus';
import { PAID } from '../paymentStatus';
import { getDebugInfo } from '../../../common/util/getDebugInfo';

export const OneOffInvoicePageV2 = () => {
    const dispatch = useDispatch();

    // redux state
    const { invoice, getInvoiceIsDone, getInvoiceIsError, getInvoicePending } = useSelector(
        state => state.invoice,
        shallowEqual
    );
    const { id: customerId } = useSelector(state => state.authentication?.person, shallowEqual);

    const { invoiceId } = useParams();

    // didMount
    useEffect(() => {
        dispatch(actions.getInvoice(customerId, invoiceId));
    }, [customerId, dispatch, invoiceId]);

    // willUnmount
    useEffect(
        () => () => {
            dispatch(actions.clearGetInvoiceState());
        },
        [dispatch]
    );

    // const headerProps = getHeaderProps(invoice);

    // ----- status (invoice status)
    // ACTIVE // LÖPANDE/CURRENT
    // TRANSFERRED
    // DEBT_COLLECTION // INKASSO/COLLECTION
    // DEBT_COLLECTION_COMPLETED // AVSLUTAT INKASSO/COMPLETED DEBT COLLECTION
    // DEPRECIATED // AVSKRIVEN
    // REMINDED
    // DUE_NOT_REMINDED // FÖRFALLEN EJ PÅMIND

    // ----- payment status
    // PAID
    // NOT_PAID
    // PARTIALLY_PAID

    const isCollection = invoice?.status === DEBT_COLLECTION || invoice?.status === DEBT_COLLECTION_COMPLETED;

    const showRemindedMessage = invoice?.status === REMINDED && invoice?.paymentStatus !== PAID;
    const showCollectionInfoPanel = isCollection;
    const showInvoiceHeader = !isCollection;
    const showInvoiceDetailsPanel = !isCollection;
    const showInvoiceRowsPanel = !isCollection;

    return (
        <AuthenticatedSubPageTemplate
            className="invoice-one-off-invoice-page-v-2"
            header={i18n(`invoice.one-off-invoice-v2.page-header`)}
        >
            {getInvoicePending && <Spinner id="oneoff-invoice-spinner" data-testid="loading" isCenterX isVisible />}
            {getInvoiceIsError && (
                <GeneralErrorMessagePanel
                    data-testid="error"
                    header={i18n('general.error.general.header')}
                    body={i18n('general.error.general.body')}
                    buttonText={i18n('general.back')}
                    isLink
                    getAppInfo={getDebugInfo}
                />
            )}
            {getInvoiceIsDone && (
                <>
                    {showRemindedMessage && <RemindedMessage date={formatDate(invoice.dueDate)} />}
                    {showCollectionInfoPanel && <CollectionInfoPanel invoice={invoice} />}
                    {showInvoiceHeader && <InvoiceHeader invoice={invoice} />}
                    {showInvoiceDetailsPanel && <InvoiceDetailsPanel invoice={invoice} />}
                    {showInvoiceRowsPanel && <InvoiceRowsPanel invoice={invoice} />}
                </>
            )}
        </AuthenticatedSubPageTemplate>
    );
};

export default OneOffInvoicePageV2;
