import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { BankIdAuthentication, Link, Logo } from '@ecster/components';
import { Cookie, setAdditionalHeaders, X_ECSTER_SESSION_KEY_NAME } from '@ecster/net';
import { reduxActionSucceeded } from '@ecster/util';

import { i18n } from '@ecster/i18n';
import history from '../../../common/history';

import { reportError } from '../../../common/reportError';
import { getDebugInfo } from '../../../common/util/getDebugInfo';
import { getCanaryValue } from '../../../common/canaryValues';
import { createSessionCookie } from '../../../common/sessionCookie';
import { LoginResponsivePanel } from '../components/LoginResponsivePanel';
import { AppIsClosedMessage } from '../components/AppIsClosedMessage';
import { AppWillCloseMessage } from '../components/AppWillCloseMessage';
import LoginFooter from '../../home/LoginFooter';
import GlobalNotificationsPanel from '../../../common/GlobalNotificationsPanel';

const urlMapper = url => {
    let bankIdUrl = url;

    if (!bankIdUrl.match(/&redirect=/)) {
        bankIdUrl += '&redirect=null';
    }

    return bankIdUrl;
};

class LoginFormSE extends Component {
    state = {
        ssn: '',
        isBankIdInProgress: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { getCustomerProperty, person, loginStatus, userIsCustomer } = this.props;

        if (person && reduxActionSucceeded('getSession', prevProps, this.props)) {
            getCustomerProperty(person.id, 'LOGIN_MYPAGES');
        }

        if (loginStatus.isLoggedIn && userIsCustomer) {
            // private route saved (attempted by user while not logged in)?
            const nextRoute = Cookie.read('nextRoute');

            if (nextRoute) {
                Cookie.remove('nextRoute');
                history.replace(nextRoute);
            } else {
                history.replace('/account/overview');
            }
        }

        if (loginStatus.isLoggedIn && userIsCustomer === false) {
            history.replace('/authentication/logout/false');
        }
    }

    onChangeSSN = value => {
        this.setState({ ssn: value });
    };

    onStart = type => {
        this.setState({ isBankIdInProgress: true });
    };

    onOpenApp = url => {
        window.location.href = url;
    };

    onSuccess = data => {
        const { getSession } = this.props;
        getSession(data); // sets session data without ajax call
    };

    onCancelLogin = () => {
        const { resetLoginState } = this.props;

        resetLoginState();
        this.setState({ isBankIdInProgress: false });
    };

    onFailure = err => {
        reportError(err, 'BankIdAuthentication failure');
    };

    onUserConfirmedFailure = () => {
        const { resetLoginState } = this.props;

        resetLoginState();
        this.setState({ isBankIdInProgress: false });
    };

    onTimeout = err => {
        reportError(err, 'BankIdAuthentication timeout');
    };

    onCreateSessionSuccess = res => {
        setAdditionalHeaders({ [X_ECSTER_SESSION_KEY_NAME]: res.response.key });
        createSessionCookie(res.response.key, 1);
    };

    render() {
        const { isReviewMode } = this.props;
        const { isBankIdInProgress } = this.state;
        const canaryValue = getCanaryValue();

        return (
            <div className="login-form-se-wrapper">
                <AppIsClosedMessage />
                <div className="login-responsive-panel-ctr">
                    {!isBankIdInProgress && <AppWillCloseMessage />}
                    <GlobalNotificationsPanel onlyForcePopup />
                    <LoginResponsivePanel id="login-page-panel" fullscreenInMobile={isBankIdInProgress}>
                        <div className="login-form-se-wrapper-children">
                            <div id="login-form-se" className="login-form-se login-form">
                                <div
                                    className={`login-form-se-container ${
                                        isBankIdInProgress ? 'login-in-progress' : ''
                                    }`}
                                >
                                    {!isBankIdInProgress && (
                                        <>
                                            <div className="ecster-logo-container">
                                                <Logo className="ecster-logo-desktop" withName width="190px" />
                                            </div>
                                            <h1 className="login-heading e-yellow">
                                                {i18n('authentication.login.se.desktop.header')}
                                            </h1>
                                        </>
                                    )}
                                    <BankIdAuthentication
                                        // config
                                        isReviewMode={isReviewMode}
                                        allowBankIdThisDevice
                                        allowMobileBankIdThisDevice
                                        showUserFeedback
                                        stack
                                        minAge={isReviewMode ? 1 : 18}
                                        buttonIcon={false}
                                        buttonProps={{ round: true }}
                                        buttonLinkProps={{ white: true }}
                                        focusInputWhenSsnIsEmpty
                                        autoFocusSsnInputOnMount
                                        dontValidateEmptySsn
                                        useFloatLabel
                                        trimSSN
                                        urlMapper={urlMapper}
                                        canaryValue={canaryValue}
                                        // callbacks
                                        onOpenApp={this.onOpenApp}
                                        onStart={this.onStart}
                                        onSuccess={this.onSuccess}
                                        onCancelLogin={this.onCancelLogin}
                                        onFailure={this.onFailure}
                                        onUserConfirmedFailure={this.onUserConfirmedFailure}
                                        onTimeout={this.onTimeout}
                                        onChangeSSN={this.onChangeSSN}
                                        onCreateSessionSuccess={this.onCreateSessionSuccess}
                                        // texts
                                        ssnLabel={i18n('authentication.login.se.login-form.ssn-label')}
                                        ssnValidationMsg={i18n('authentication.login.se.login-form.ssn-validation-msg')}
                                        bankIdThisDeviceLinkText={i18n(
                                            'authentication.login.se.login-form.bid-this-link-text'
                                        )}
                                        mobileBankIdOtherDeviceLinkText={i18n(
                                            'authentication.login.se.login-form.mbid-other-link-text',
                                            { danger: true }
                                        )}
                                        methods={{
                                            desktop: { thisDevice: true, otherDevice: true },
                                            tablet: { thisDevice: true, otherDevice: true },
                                            mobile: { thisDevice: true, otherDevice: false },
                                        }}
                                        extras={
                                            <Link white id="login-help-button" to="/authentication/help">
                                                {i18n('general.help')}
                                            </Link>
                                        }
                                        getAppInfo={getDebugInfo}
                                        errorInfoDarkMode
                                    />
                                </div>
                            </div>
                        </div>
                        {!isBankIdInProgress && (
                            <LoginFooter text={i18n('authentication.login.se.login-form.terms', { danger: true })} />
                        )}
                    </LoginResponsivePanel>
                </div>
            </div>
        );
    }
}

LoginFormSE.propTypes = {
    resetLoginState: PropTypes.func.isRequired,
    person: PropTypes.object,
    userIsCustomer: PropTypes.bool,

    loginStatus: PropTypes.object.isRequired,

    // pending and error props used by operationSucceeded
    getSession: PropTypes.func.isRequired,
    getSessionPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getSessionError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

    getCustomerProperty: PropTypes.func.isRequired,
    isReviewMode: PropTypes.bool,
};

LoginFormSE.defaultProps = {
    isReviewMode: false,
    person: undefined,
    userIsCustomer: undefined,
    getSessionError: undefined,
};

export default LoginFormSE;
