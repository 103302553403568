import { get } from '@ecster/net/v2';

import {
    ACCOUNT_GET_LOYALTY_DETAILS_BEGIN,
    ACCOUNT_GET_LOYALTY_DETAILS_SUCCESS,
    ACCOUNT_GET_LOYALTY_DETAILS_FAILURE,
    ACCOUNT_GET_LOYALTY_DETAILS_DISMISS_ERROR,
} from './constants';
import { GET_LOYALTY_DETAILS_URL } from './urls';
import { reportError } from '../../../common/reportError';

const CLEAR_GET_LOYALTY_DETAILS_STATE = 'CLEAR_GET_LOYALTY_DETAILS_STATE';
const CLEAR_GET_LOYALTY_DETAILS_REDUX_STATE = 'CLEAR_GET_LOYALTY_DETAILS_REDUX_STATE';

export const getLoyaltyDetails = (customerId, accountRef, loyaltyType, loyaltyId) => async dispatch => {
    dispatch({
        type: ACCOUNT_GET_LOYALTY_DETAILS_BEGIN,
    });

    try {
        const res = await get(GET_LOYALTY_DETAILS_URL(customerId, accountRef, loyaltyType, loyaltyId));

        dispatch({
            type: ACCOUNT_GET_LOYALTY_DETAILS_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getLoyaltyDetails');

        // FI: responds 404 for non-connected accounts
        // Treat these as in SE. No bonus program. No data
        if (err?.status === 404) {
            dispatch({
                type: ACCOUNT_GET_LOYALTY_DETAILS_SUCCESS,
                data: null,
            });
        } else {
            dispatch({
                type: ACCOUNT_GET_LOYALTY_DETAILS_FAILURE,
                data: {
                    error: {
                        message: err,
                        action: ACCOUNT_GET_LOYALTY_DETAILS_FAILURE,
                    },
                },
            });
        }
    }
};

export const dismissGetLoyaltyDetailsError = () => ({ type: ACCOUNT_GET_LOYALTY_DETAILS_DISMISS_ERROR });

export const clearGetLoyaltyDetailsState = () => ({ type: CLEAR_GET_LOYALTY_DETAILS_STATE });

export const clearGetLoyaltyDetailsReduxState = () => ({ type: CLEAR_GET_LOYALTY_DETAILS_REDUX_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case ACCOUNT_GET_LOYALTY_DETAILS_BEGIN:
            return {
                ...state,
                getLoyaltyDetailsPending: true,
                getLoyaltyDetailsError: null,
                getLoyaltyDetailsIsDone: false,
                getLoyaltyDetailsIsError: false,
            };

        case ACCOUNT_GET_LOYALTY_DETAILS_SUCCESS:
            return {
                ...state,
                loyaltyDetails: action.data,
                getLoyaltyDetailsPending: false,
                getLoyaltyDetailsError: null,
                getLoyaltyDetailsIsDone: true,
            };

        case CLEAR_GET_LOYALTY_DETAILS_STATE:
            return {
                ...state,
                loyaltyDetails: null,
                getLoyaltyDetailsPending: false,
                getLoyaltyDetailsError: null,
                getLoyaltyDetailsIsDone: false,
                getLoyaltyDetailsIsError: false,
            };

        case CLEAR_GET_LOYALTY_DETAILS_REDUX_STATE:
            return {
                ...state,
                getLoyaltyDetailsPending: false,
                getLoyaltyDetailsError: null,
                getLoyaltyDetailsIsDone: false,
                getLoyaltyDetailsIsError: false,
            };

        case ACCOUNT_GET_LOYALTY_DETAILS_FAILURE:
            return {
                ...state,
                getLoyaltyDetailsPending: false,
                getLoyaltyDetailsError: action.data.error,
                getLoyaltyDetailsIsError: true,
            };

        case ACCOUNT_GET_LOYALTY_DETAILS_DISMISS_ERROR:
            return {
                ...state,
                getLoyaltyDetailsError: null,
                getLoyaltyDetailsIsError: false,
            };

        default:
            return state;
    }
}
