import PropTypes from 'prop-types';
import React from 'react';
import {
    Panel,
    PanelContent,
    ResponsivePanel,
    Mobile,
    CopyToClipboardButton,
    LinkButton,
    detectDevice,
} from '@ecster/components';
import { i18n } from '@ecster/i18n';
import './FinalActivationPanel.scss';
import { APP_STORE_URL, GOOGLE_PLAY_URL } from '../constants';

const FinalActivationPanel = ({ activationKey, number }) => {
    const { isIOS } = detectDevice();

    const appUrl = isIOS ? APP_STORE_URL : GOOGLE_PLAY_URL;

    return (
        <Panel className="final-activation-panel" stretchInMobile>
            <PanelContent>
                <h3 className="heading">{i18n('secure-id.activation.activation-panel.heading')}</h3>

                <ResponsivePanel>
                    <div>
                        <h5>{i18n('secure-id.activation.activation-panel.activation-key')}</h5>
                        <Panel blueBG className="code-panel key">
                            <h3 className="content">{activationKey}</h3>
                        </Panel>
                        <Mobile>
                            <div className="clipboard-button">
                                <CopyToClipboardButton
                                    id="activation-key-tooltip"
                                    noIcon
                                    tooltip={i18n('secure-id.activation.activation-panel.copy')}
                                    tooltipSuccess={i18n('secure-id.activation.activation-panel.copy-success')}
                                    value={activationKey}
                                >
                                    {i18n('secure-id.activation.activation-panel.copy')}
                                </CopyToClipboardButton>
                            </div>
                        </Mobile>
                    </div>

                    <div>
                        <h5>{i18n('secure-id.activation.activation-panel.activation-code')}</h5>
                        <Panel blueBG className="code-panel sms">
                            <p className="content">
                                {i18n('secure-id.activation.activation-panel.sent-to', { number })}
                            </p>
                        </Panel>
                    </div>
                </ResponsivePanel>

                <p className="paragraph">{i18n('secure-id.activation.activation-panel.paragraph')}</p>

                <Mobile>
                    <div className="button-wrapper">
                        <LinkButton green round href={appUrl} target="_blank">
                            {i18n('secure-id.activation.activation-panel.open-app-link')}
                        </LinkButton>
                    </div>
                </Mobile>
            </PanelContent>
        </Panel>
    );
};

FinalActivationPanel.propTypes = {
    activationKey: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
};

export default FinalActivationPanel;
