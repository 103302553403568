import { Panel, PanelContent, Link } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import React from 'react';
import './ActivationStepsPanel.scss';
import { APP_STORE_URL, FI_FAQ_URL, GOOGLE_PLAY_URL, SV_FAQ_URL } from '../constants';

const ActivationStepsPanel = () => {
    const { locale } = window.EcsterConfig;

    const FAQ_URL = locale === 'fi-FI' ? FI_FAQ_URL : SV_FAQ_URL;

    return (
        <Panel className="activation-steps-panel">
            <PanelContent centered>
                <h3 className="heading">{i18n('secure-id.how-to.activation.heading')}</h3>
                <h4 className="sub-heading">{i18n('secure-id.how-to.activation.sub-heading-1')}</h4>
                <p>
                    {i18n('secure-id.how-to.activation.download-content')}
                    <Link underline href={APP_STORE_URL} ariaLabel={i18n('secure-id.common.app-store')} target="_blank">
                        {i18n('secure-id.common.app-store')}
                    </Link>
                    {i18n('secure-id.how-to.activation.or')}
                    <Link
                        underline
                        href={GOOGLE_PLAY_URL}
                        ariaLabel={i18n('secure-id.how-to.activation.google-play')}
                        target="_blank"
                    >
                        {i18n('secure-id.how-to.activation.google-play')}
                    </Link>
                    .
                </p>
                <h4 className="sub-heading">{i18n('secure-id.how-to.activation.sub-heading-2')}</h4>
                <p>{i18n('secure-id.how-to.activation.begin-activation-content')}</p>
                <h4 className="sub-heading">{i18n('secure-id.how-to.activation.sub-heading-3')}</h4>
                <p>{i18n('secure-id.how-to.activation.finish-activation-content')}</p>
                <h4 className="sub-heading">{i18n('secure-id.how-to.activation.sub-heading-4')}</h4>
                <p>
                    {i18n('secure-id.how-to.activation.shop-secure-content')}
                    <Link underline href={FAQ_URL} ariaLabel={i18n('secure-id.how-to.activation.faq')} target="_blank">
                        {i18n('secure-id.how-to.activation.faq')}
                    </Link>
                    .
                </p>
            </PanelContent>
        </Panel>
    );
};

export default ActivationStepsPanel;
