import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogBody, DialogFooter } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import history from '../../../common/history';
import './SecureIdOnboardingDialog.scss';

const SecureIdOnboardingDialog = ({ isOpen, onClose, buttonRef }) => {
    const url = '/secure-id/overview';

    const onClickActivate = () => {
        onClose();
        history.push(url);
    };

    return (
        <Dialog
            rounded5x
            maxWidth="500px"
            fullscreenMobile
            open={isOpen}
            className="secure-id-onboarding-dialog"
            fadeEffect={false}
        >
            <DialogBody className="dialog">
                <img className="fullwidth-image" src="./images/backgrounds/fi/onboarding-sca.jpg" />
                <button
                    tabIndex={0}
                    type="button"
                    className="close"
                    onClick={onClose}
                    aria-label={i18n('general.close')}
                    ref={buttonRef}
                />
                <div className="onboarding-content">
                    <h2>{i18n('secure-id.onboarding.header')}</h2>
                    <p>{i18n('secure-id.onboarding.p1')}</p>
                    <p>{i18n('secure-id.onboarding.p2')}</p>
                </div>
            </DialogBody>
            <DialogFooter>
                <div className="dialog-button-group">
                    <Button
                        className="button-close"
                        round
                        green
                        transparent
                        onClick={onClose}
                        name="close-button"
                        ariaLabel={i18n('general.close')}
                    >
                        {i18n('general.close')}
                    </Button>
                    <Button
                        className="button-enroll"
                        round
                        onClick={onClickActivate}
                        ariaLabel={i18n('secure-id.onboarding.button')}
                    >
                        {i18n('secure-id.onboarding.button')}
                    </Button>
                </div>
            </DialogFooter>
        </Dialog>
    );
};

SecureIdOnboardingDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    buttonRef: PropTypes.object.isRequired,
};

export default SecureIdOnboardingDialog;
