import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ecster/components';
import { i18n } from '@ecster/i18n';

const formAction = `multicard/index/login?r=${new Date().getTime()}`;
const usernameField = 'username';
const passwordField = 'password';

export const BonusShopLoginForm = ({ username, password, baseUrl }) => {
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            <form action={`${baseUrl}/${formAction}`} method="POST" target="_blank">
                <input type="hidden" name={usernameField} value={username} />
                <input type="hidden" name={passwordField} value={password} />
                <Button
                    type="submit"
                    onClick={() => {}}
                    round
                    outline
                    id="login-bonus-shop-button"
                    name="loginButton"
                    iconRight="icon-external-link"
                >
                    {i18n('account.bonus-overview-page.info-panel.bonus-shop-link')}
                </Button>
            </form>
        </>
    );
};

BonusShopLoginForm.propTypes = {
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
};
