export const ACCOUNT_DELETE_ACCOUNT_BEGIN = 'ACCOUNT_DELETE_ACCOUNT_BEGIN';
export const ACCOUNT_DELETE_ACCOUNT_SUCCESS = 'ACCOUNT_DELETE_ACCOUNT_SUCCESS';
export const ACCOUNT_DELETE_ACCOUNT_FAILURE = 'ACCOUNT_DELETE_ACCOUNT_FAILURE';
export const ACCOUNT_DELETE_ACCOUNT_DISMISS_ERROR = 'ACCOUNT_DELETE_ACCOUNT_DISMISS_ERROR';
export const ACCOUNT_GET_ACCOUNT_TERMS_BEGIN = 'ACCOUNT_GET_ACCOUNT_TERMS_BEGIN';
export const ACCOUNT_GET_ACCOUNT_TERMS_SUCCESS = 'ACCOUNT_GET_ACCOUNT_TERMS_SUCCESS';
export const ACCOUNT_GET_ACCOUNT_TERMS_FAILURE = 'ACCOUNT_GET_ACCOUNT_TERMS_FAILURE';
export const ACCOUNT_GET_ACCOUNT_TERMS_DISMISS_ERROR = 'ACCOUNT_GET_ACCOUNT_TERMS_DISMISS_ERROR';
export const ACCOUNT_GET_ACCOUNT_BEGIN = 'ACCOUNT_GET_ACCOUNT_BEGIN';
export const ACCOUNT_GET_ACCOUNT_SUCCESS = 'ACCOUNT_GET_ACCOUNT_SUCCESS';
export const ACCOUNT_CLEAR_BRICKID = 'ACCOUNT_CLEAR_BRICKID';
export const ACCOUNT_GET_ACCOUNT_FAILURE = 'ACCOUNT_GET_ACCOUNT_FAILURE';
export const ACCOUNT_GET_ACCOUNT_DISMISS_ERROR = 'ACCOUNT_GET_ACCOUNT_DISMISS_ERROR';
export const ACCOUNT_GET_ACCOUNT_ALLOWED_PART_PAYMENTS_BEGIN = 'ACCOUNT_GET_ACCOUNT_ALLOWED_PART_PAYMENTS_BEGIN';
export const ACCOUNT_GET_ACCOUNT_ALLOWED_PART_PAYMENTS_SUCCESS = 'ACCOUNT_GET_ACCOUNT_ALLOWED_PART_PAYMENTS_SUCCESS';
export const ACCOUNT_GET_ACCOUNT_ALLOWED_PART_PAYMENTS_FAILURE = 'ACCOUNT_GET_ACCOUNT_ALLOWED_PART_PAYMENTS_FAILURE';
export const ACCOUNT_GET_ACCOUNT_ALLOWED_PART_PAYMENTS_DISMISS_ERROR =
    'ACCOUNT_GET_ACCOUNT_ALLOWED_PART_PAYMENTS_DISMISS_ERROR';
export const ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_BEGIN = 'ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_BEGIN';
export const ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_SUCCESS = 'ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_SUCCESS';
export const ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_FAILURE = 'ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_FAILURE';
export const ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_DISMISS_ERROR = 'ACCOUNT_GET_ACCOUNT_BILL_OVERVIEW_DISMISS_ERROR';
export const ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_BEGIN = 'ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_BEGIN';
export const ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_SUCCESS = 'ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_SUCCESS';
export const ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_FAILURE = 'ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_FAILURE';
export const ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_DISMISS_ERROR =
    'ACCOUNT_GET_ACCOUNT_CAMPAIGN_PURCHASES_DISMISS_ERROR';
export const ACCOUNT_GET_ACCOUNT_CARDS_BEGIN = 'ACCOUNT_GET_ACCOUNT_CARDS_BEGIN';
export const ACCOUNT_GET_ACCOUNT_CARDS_SUCCESS = 'ACCOUNT_GET_ACCOUNT_CARDS_SUCCESS';
export const ACCOUNT_GET_ACCOUNT_CARDS_FAILURE = 'ACCOUNT_GET_ACCOUNT_CARDS_FAILURE';
export const ACCOUNT_GET_ACCOUNT_CARDS_DISMISS_ERROR = 'ACCOUNT_GET_ACCOUNT_CARDS_DISMISS_ERROR';
export const CLEAR_GET_ACCOUNT_CARDS_STATE = 'CLEAR_GET_ACCOUNT_CARDS_STATE';
export const CLEAR_GET_ACCOUNT_CARDS_REDUX_STATE = 'CLEAR_GET_ACCOUNT_CARDS_REDUX_STATE';

export const ACCOUNT_GET_ACCOUNT_TRANSACTIONS_BEGIN = 'ACCOUNT_GET_ACCOUNT_TRANSACTIONS_BEGIN';
export const ACCOUNT_GET_ACCOUNT_TRANSACTIONS_SUCCESS = 'ACCOUNT_GET_ACCOUNT_TRANSACTIONS_SUCCESS';
export const ACCOUNT_GET_ACCOUNT_TRANSACTIONS_FAILURE = 'ACCOUNT_GET_ACCOUNT_TRANSACTIONS_FAILURE';
export const ACCOUNT_GET_ACCOUNT_TRANSACTIONS_DISMISS_ERROR = 'ACCOUNT_GET_ACCOUNT_TRANSACTIONS_DISMISS_ERROR';
export const ACCOUNT_GET_ACCOUNTS_BEGIN = 'ACCOUNT_GET_ACCOUNTS_BEGIN';
export const ACCOUNT_GET_ACCOUNTS_SUCCESS = 'ACCOUNT_GET_ACCOUNTS_SUCCESS';
export const ACCOUNT_GET_ACCOUNTS_FAILURE = 'ACCOUNT_GET_ACCOUNTS_FAILURE';
export const ACCOUNT_GET_ACCOUNTS_DISMISS_ERROR = 'ACCOUNT_GET_ACCOUNTS_DISMISS_ERROR';
export const ACCOUNT_GET_EXTRA_CARD_HOLDERS_BEGIN = 'ACCOUNT_GET_EXTRA_CARD_HOLDERS_BEGIN';
export const ACCOUNT_GET_EXTRA_CARD_HOLDERS_SUCCESS = 'ACCOUNT_GET_EXTRA_CARD_HOLDERS_SUCCESS';
export const ACCOUNT_GET_EXTRA_CARD_HOLDERS_FAILURE = 'ACCOUNT_GET_EXTRA_CARD_HOLDERS_FAILURE';
export const ACCOUNT_GET_EXTRA_CARD_HOLDERS_DISMISS_ERROR = 'ACCOUNT_GET_EXTRA_CARD_HOLDERS_DISMISS_ERROR';
export const ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_BEGIN = 'ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_BEGIN';
export const ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_SUCCESS = 'ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_SUCCESS';
export const ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_FAILURE = 'ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_FAILURE';
export const ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_DISMISS_ERROR = 'ACCOUNT_GET_ACCOUNT_PAYMENT_TERMS_DISMISS_ERROR';
export const ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_BEGIN = 'ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_BEGIN';
export const ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_SUCCESS = 'ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_SUCCESS';
export const ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_FAILURE = 'ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_FAILURE';
export const ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_DISMISS_ERROR =
    'ACCOUNT_GET_ACCOUNT_LIMIT_RAISE_TERMS_DISMISS_ERROR';
export const ACCOUNT_UPDATE_ACCOUNT_BEGIN = 'ACCOUNT_UPDATE_ACCOUNT_BEGIN';
export const ACCOUNT_UPDATE_ACCOUNT_SUCCESS = 'ACCOUNT_UPDATE_ACCOUNT_SUCCESS';
export const ACCOUNT_UPDATE_ACCOUNT_FAILURE = 'ACCOUNT_UPDATE_ACCOUNT_FAILURE';
export const ACCOUNT_UPDATE_ACCOUNT_DISMISS_ERROR = 'ACCOUNT_UPDATE_ACCOUNT_DISMISS_ERROR';
export const ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_BEGIN =
    'ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_BEGIN';
export const ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_SUCCESS =
    'ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_SUCCESS';
export const ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_FAILURE =
    'ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_FAILURE';
export const ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_DISMISS_ERROR =
    'ACCOUNT_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_DISMISS_ERROR';
export const CLEAR_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_STATE =
    'CLEAR_UPDATE_ACCOUNT_TRANSACTION_PART_PAYMENT_STATE';
export const ACCOUNT_UPDATE_ACCOUNT_CARD_BEGIN = 'ACCOUNT_UPDATE_ACCOUNT_CARD_BEGIN';
export const ACCOUNT_UPDATE_ACCOUNT_CARD_SUCCESS = 'ACCOUNT_UPDATE_ACCOUNT_CARD_SUCCESS';
export const ACCOUNT_UPDATE_ACCOUNT_CARD_FAILURE = 'ACCOUNT_UPDATE_ACCOUNT_CARD_FAILURE';
export const ACCOUNT_UPDATE_ACCOUNT_CARD_DISMISS_ERROR = 'ACCOUNT_UPDATE_ACCOUNT_CARD_DISMISS_ERROR';

export const APPLY_ACCOUNT_TRANSACTIONS_FILTER = 'APPLY_ACCOUNT_TRANSACTIONS_FILTER';
export const ACCOUNT_CREATE_ACCOUNT_CARD_BEGIN = 'ACCOUNT_CREATE_ACCOUNT_CARD_BEGIN';
export const ACCOUNT_CREATE_ACCOUNT_CARD_SUCCESS = 'ACCOUNT_CREATE_ACCOUNT_CARD_SUCCESS';
export const ACCOUNT_CREATE_ACCOUNT_CARD_FAILURE = 'ACCOUNT_CREATE_ACCOUNT_CARD_FAILURE';
export const ACCOUNT_CREATE_ACCOUNT_CARD_DISMISS_ERROR = 'ACCOUNT_CREATE_ACCOUNT_CARD_DISMISS_ERROR';
export const ACCOUNT_SET_PART_PAYMENT_AMOUNT = 'ACCOUNT_SET_PART_PAYMENT_AMOUNT';
export const ACCOUNT_GET_PROMISSORY_NOTES_BEGIN = 'ACCOUNT_GET_PROMISSORY_NOTES_BEGIN';
export const ACCOUNT_GET_PROMISSORY_NOTES_SUCCESS = 'ACCOUNT_GET_PROMISSORY_NOTES_SUCCESS';
export const ACCOUNT_GET_PROMISSORY_NOTES_FAILURE = 'ACCOUNT_GET_PROMISSORY_NOTES_FAILURE';
export const ACCOUNT_GET_PROMISSORY_NOTES_DISMISS_ERROR = 'ACCOUNT_GET_PROMISSORY_NOTES_DISMISS_ERROR';

export const CLEAR_GET_ACCOUNTS_STATE = 'CLEAR_GET_ACCOUNTS_STATE';
export const CLEAR_GET_ACCOUNT_STATE = 'CLEAR_GET_ACCOUNT_STATE';
export const CLEAR_GET_ACCOUNT_REDUX_STATE = 'CLEAR_GET_ACCOUNT_REDUX_STATE';
export const ACCOUNT_CREATE_AMORTIZATION_FREE_MONTH_BEGIN = 'ACCOUNT_CREATE_AMORTIZATION_FREE_MONTH_BEGIN';
export const ACCOUNT_CREATE_AMORTIZATION_FREE_MONTH_SUCCESS = 'ACCOUNT_CREATE_AMORTIZATION_FREE_MONTH_SUCCESS';
export const ACCOUNT_CREATE_AMORTIZATION_FREE_MONTH_FAILURE = 'ACCOUNT_CREATE_AMORTIZATION_FREE_MONTH_FAILURE';
export const ACCOUNT_CREATE_AMORTIZATION_FREE_MONTH_DISMISS_ERROR =
    'ACCOUNT_CREATE_AMORTIZATION_FREE_MONTH_DISMISS_ERROR';
export const ACCOUNT_GET_LOYALTY_DETAILS_BEGIN = 'ACCOUNT_GET_LOYALTY_DETAILS_BEGIN';
export const ACCOUNT_GET_LOYALTY_DETAILS_SUCCESS = 'ACCOUNT_GET_LOYALTY_DETAILS_SUCCESS';
export const ACCOUNT_GET_LOYALTY_DETAILS_FAILURE = 'ACCOUNT_GET_LOYALTY_DETAILS_FAILURE';
export const ACCOUNT_GET_LOYALTY_DETAILS_DISMISS_ERROR = 'ACCOUNT_GET_LOYALTY_DETAILS_DISMISS_ERROR';
export const ACCOUNT_SET_LOYALTY_DETAILS_BEGIN = 'ACCOUNT_SET_LOYALTY_DETAILS_BEGIN';
export const ACCOUNT_SET_LOYALTY_DETAILS_SUCCESS = 'ACCOUNT_SET_LOYALTY_DETAILS_SUCCESS';
export const ACCOUNT_SET_LOYALTY_DETAILS_FAILURE = 'ACCOUNT_SET_LOYALTY_DETAILS_FAILURE';
export const ACCOUNT_SET_LOYALTY_DETAILS_DISMISS_ERROR = 'ACCOUNT_SET_LOYALTY_DETAILS_DISMISS_ERROR';
export const ACCOUNT_CLOSE_ACCOUNT_BEGIN = 'ACCOUNT_CLOSE_ACCOUNT_BEGIN';
export const ACCOUNT_CLOSE_ACCOUNT_SUCCESS = 'ACCOUNT_CLOSE_ACCOUNT_SUCCESS';
export const ACCOUNT_CLOSE_ACCOUNT_FAILURE = 'ACCOUNT_CLOSE_ACCOUNT_FAILURE';
export const ACCOUNT_CLOSE_ACCOUNT_DISMISS_ERROR = 'ACCOUNT_CLOSE_ACCOUNT_DISMISS_ERROR';
export const ACCOUNT_GET_ACCOUNT_BILL_DETAILS_BEGIN = 'ACCOUNT_GET_ACCOUNT_BILL_DETAILS_BEGIN';
export const ACCOUNT_GET_ACCOUNT_BILL_DETAILS_SUCCESS = 'ACCOUNT_GET_ACCOUNT_BILL_DETAILS_SUCCESS';
export const ACCOUNT_GET_ACCOUNT_BILL_DETAILS_FAILURE = 'ACCOUNT_GET_ACCOUNT_BILL_DETAILS_FAILURE';
export const ACCOUNT_GET_ACCOUNT_BILL_DETAILS_DISMISS_ERROR = 'ACCOUNT_GET_ACCOUNT_BILL_DETAILS_DISMISS_ERROR';
