import React from 'react';
import PropTypes from 'prop-types';
import './RemoveSecureIdPanel.scss';
import {
    Button,
    ButtonGroup,
    Panel,
    PanelContent,
    Dialog,
    DialogFooter,
    DialogCloseButton,
    DialogBody,
    Message,
} from '@ecster/components';
import { secureRemoveIcon } from '@ecster/icons/secure';
import { i18n } from '@ecster/i18n';
import { connect } from 'react-redux';
import { reduxActionSucceeded, reduxActionFailed } from '@ecster/util';
import { deleteSecureId } from '../redux/actions';

class RemoveSecureIdPanel extends React.Component {
    state = {
        isOpen: false,
        hasError: false,
    };

    componentDidUpdate(prevProps) {
        const { isOpen } = this.state;

        if (isOpen && reduxActionSucceeded('deleteSecureId', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ hasError: false, isOpen: false });
            window.scrollTo(0, 0);
        }
        if (isOpen && reduxActionFailed('deleteSecureId', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ hasError: true, isOpen: false });
        }
    }

    onConfirm() {
        const {
            person: { id },
            deleteSecureId,
            enrolledDevices: { devices },
        } = this.props;

        const deviceId = devices[0].id;

        deleteSecureId(id, deviceId);
    }

    render() {
        const { isOpen, hasError } = this.state;

        return (
            <Panel className="remove-secure-id-panel" stretchInMobile paddingLeftright="small" paddingTopBottom="large">
                <PanelContent centered>
                    <img src={secureRemoveIcon} className="remove-icon" alt="" />
                    <h3 className="heading">{i18n('secure-id.overview.remove.header')}</h3>
                    <p className="content">{i18n('secure-id.overview.remove.content')}</p>

                    <Button round outline green onClick={() => this.setState({ isOpen: true })}>
                        {i18n('secure-id.overview.remove.button')}
                    </Button>

                    {hasError && (
                        <Message
                            exclamation
                            purple
                            className="delete-error-message"
                            header={i18n('general.error.general.header')}
                            message={i18n('general.error.general.body')}
                        />
                    )}

                    <Dialog
                        className="remove-dialog"
                        maxWidth="520px"
                        open={isOpen}
                        onRequestClose={() => this.setState({ isOpen: false })}
                    >
                        <DialogCloseButton onRequestClose={() => this.setState({ isOpen: false })} />
                        <DialogBody centeredContent>
                            <h3>{i18n('secure-id.overview.remove.dialog.header')}</h3>
                            <p>{i18n('secure-id.overview.remove.dialog.content')}</p>
                        </DialogBody>
                        <DialogFooter alignCenter>
                            <ButtonGroup alignCenter>
                                <Button green round onClick={() => this.onConfirm()}>
                                    {i18n('secure-id.overview.remove.dialog.confirm')}
                                </Button>
                            </ButtonGroup>
                        </DialogFooter>
                    </Dialog>
                </PanelContent>
            </Panel>
        );
    }
}

RemoveSecureIdPanel.propTypes = {
    person: PropTypes.object.isRequired,
    enrolledDevices: PropTypes.object.isRequired,
    deleteSecureId: PropTypes.func.isRequired,
    deleteSecureIdPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    deleteSecureIdError: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
};

/* istanbul ignore next */
// eslint-disable-next-line
function mapStateToProps({ authentication, secureId }) {
    return {
        person: authentication.person,
        enrolledDevices: secureId.enrolledDevices,
        deleteSecureId: secureId.deleteSecureId,
        deleteSecureIdPending: secureId.deleteSecureIdPending,
        deleteSecureIdError: secureId.deleteSecureIdError,
    };
}

/* istanbul ignore next */
// eslint-disable-next-line
function mapDispatchToProps(dispatch) {
    return {
        deleteSecureId: (customerId, deviceId) => dispatch(deleteSecureId(customerId, deviceId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveSecureIdPanel);
