import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { i18n } from '@ecster/i18n';
import './LatestTransactions.scss';
import { Link, GeneralErrorMessagePanel } from '@ecster/components';
import { formatDateShort } from '../../../common/util/format-date';
import { formatAmount } from '../../../common/util/format-amount';
import { getObjectWithKeyFromArray } from '../../../common/util/get-object-with-key-from-array';
import { getDebugInfo } from '../../../common/util/getDebugInfo';

const Wrapper = ({ children, classes, ...rest }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest} className={classes}>
        {children}
    </div>
);

Wrapper.propTypes = {
    children: PropTypes.object.isRequired,
    classes: PropTypes.string.isRequired,
};

export const LatestTransactions = ({
    className,
    transactions,
    totalTransactions,
    account,
    getAccountTransactionsError,
    dismissGetAccountTransactionsError,
}) => {
    const classes = classNames({
        'latest-transactions': true,
        [className]: className,
    });

    const hasTransactions = transactions.length !== 0;

    const hasGetAccountTransactionsError = getObjectWithKeyFromArray(
        getAccountTransactionsError,
        'accountRef',
        account,
        'reference'
    );
    if (hasGetAccountTransactionsError) {
        return (
            <GeneralErrorMessagePanel
                icon={null}
                header={null}
                body={i18n('general.error.general.body')}
                error={hasGetAccountTransactionsError}
                dismissError={dismissGetAccountTransactionsError}
                isLink
                getAppInfo={getDebugInfo}
            />
        );
    }

    return (
        <Wrapper classes={classes}>
            <strong className="text-[24px]">{i18n('account.latest-transactions.header')}</strong>
            <div className="w-full">
                {hasTransactions ? (
                    transactions.map(trans => (
                        <div className="flex flex-row justify-between w-full my-4" key={`${trans.id}-${trans.amount}`}>
                            <div className="w-1/3 latest-transactions__date">
                                {formatDateShort(trans.date)}
                            </div>
                            <div className="w-1/3">{trans.description}</div>
                            <div className="w-1/3 text-right font-bold">
                                {formatAmount(
                                    trans.type === 'CREDIT' ||
                                        trans.transactionType === 'CREDIT' ||
                                        trans.transactionType === 'RESERVED_AMOUNT_CREDIT'
                                        ? trans.amount
                                        : -trans.amount,
                                    {
                                        hasCurrency: false,
                                    }
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <div>{i18n('account.latest-transactions.missing', 'p')}</div>
                )}
            </div>
            {totalTransactions > transactions.length && (
                <div className="flex justify-end mt-6">
                    <Link
                        purple
                        iconRight="icon-chevron-right"
                        iconColorClass="e-black"
                        underline={false}
                        to={`/account/${account.reference}/transactions`}
                        id="show-more-transactions"
                    >
                        {i18n('account.transactions.show-more')}
                    </Link>
                </div>
            )}
        </Wrapper>
    );
};

LatestTransactions.propTypes = {
    className: PropTypes.string,
    transactions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    totalTransactions: PropTypes.number.isRequired,
    account: PropTypes.shape().isRequired,
    getAccountTransactionsError: PropTypes.shape(),
    dismissGetAccountTransactionsError: PropTypes.func,
};

LatestTransactions.defaultProps = {
    className: '',
    getAccountTransactionsError: null,
    dismissGetAccountTransactionsError: null,
};
