import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Mobile, TabletOrDesktop } from '@ecster/components';
import MessagePanel from '../MessagePanel';
import MobileNavigation from '../navigation/MobileNavigation';
import TabletDesktopNavigation from '../navigation/TabletDesktopNavigation';
import { Footer } from '../footer';
import { useAuthenticatedPageTemplateState } from './hooks/useAuthenticatedPageTemplateState';
import { ComCheck } from '../index';

const AuthenticatedPageTemplate = ({ className, header, children }) => {
    const { showSecureIdNotification, showInvoiceNotification, hasZeroAccounts } = useAuthenticatedPageTemplateState();

    const contentRef = useRef();

    useEffect(() => {
        const currentRef = contentRef?.current;
        currentRef.classList?.add('visible');

        return () => {
            currentRef.classList?.remove('visible');
        };
    }, []);

    const classes = classNames({
        'common-authenticated-page': true,
        [className]: className,
    });

    const thisHeader = header && (
        <div className="page-header">
            <div className="header">
                <h1>{header}</h1>
            </div>
        </div>
    );

    return (
        <>
            <div role="main">
                <div className={classes}>
                    <TabletOrDesktop>
                        <TabletDesktopNavigation
                            hasZeroAccounts={hasZeroAccounts}
                            hasSecureID={!showSecureIdNotification}
                            hasHamburgerNotification={showSecureIdNotification}
                            hasInvoiceNotification={showInvoiceNotification}
                        />
                    </TabletOrDesktop>
                    <div className="page-container">
                        {thisHeader}
                        <div className="page-content" ref={contentRef}>
                            <ComCheck />
                            {children}
                        </div>
                    </div>
                    <Mobile>
                        <MobileNavigation
                            hasZeroAccounts={hasZeroAccounts}
                            hasSecureID={!showSecureIdNotification}
                            hasHamburgerNotification={showSecureIdNotification}
                            hasInvoiceNotification={showInvoiceNotification}
                        />
                    </Mobile>
                </div>
                <MessagePanel />
            </div>
            <Footer />
        </>
    );
};

AuthenticatedPageTemplate.propTypes = {
    className: PropTypes.string,
    header: PropTypes.string,
    children: PropTypes.node.isRequired,
};

AuthenticatedPageTemplate.defaultProps = {
    className: '',
    header: undefined,
};

export default AuthenticatedPageTemplate;
