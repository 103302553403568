import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { i18n } from '@ecster/i18n';
import { Link, TabletOrDesktop, Mobile, LabelValue, GeneralErrorMessagePanel } from '@ecster/components';
import './NextPaymentPanel.scss';

import { formatAmount, formatDate } from '../../../common/util/format';

import { getObjectWithKeyFromArray } from '../../../common/util/get-object-with-key-from-array';
import { getBillProperties } from '../util';
import { getDebugInfo } from '../../../common/util/getDebugInfo';

const TheHeader = ({ icon, heading, billProps }) => {
    return (
        <strong className="heading">
            <span>{heading} </span>
            <span>
                {billProps.badge}
                {icon && <i className={icon} />}
            </span>
        </strong>
    );
};

TheHeader.propTypes = {
    heading: PropTypes.string.isRequired,
    icon: PropTypes.string,
    billProps: PropTypes.shape({ badge: PropTypes.object }),
};

TheHeader.defaultProps = { icon: undefined, billProps: { badge: null } };

const TheData = ({
    bgNumber,
    ocr,
    bill,
    billProps: {
        canPaySomeAmount,
        dueDate,
        hasNotPaid,
        hasPaidAll,
        isZeroInvoice,
        lab2,
        lab3,
        paidBetweenLab1Lab3,
        paidLessLab1,
        paidMoreLab3,
    },
}) => {
    const toPay = window.EcsterConfig.applicationCountry === 'FI' ? lab2 : lab3;
    const { amountPaidByCustomer = 0 } = bill;

    return (
        <>
            {!isZeroInvoice && (
                <LabelValue label={`${i18n('account.next-payment.amount')}`} value={formatAmount(toPay)} />
            )}

            {(hasNotPaid || canPaySomeAmount) && (
                <LabelValue label={i18n('account.next-payment.due-date')} value={formatDate(dueDate)} />
            )}

            {(paidLessLab1 || paidBetweenLab1Lab3 || hasPaidAll) && (
                <LabelValue
                    label={
                        paidMoreLab3 ? i18n('account.next-payment.paid-more') : i18n('account.next-payment.amount-paid')
                    }
                    value={formatAmount(amountPaidByCustomer)}
                />
            )}

            {(paidLessLab1 || hasNotPaid) && bgNumber && (
                <LabelValue label={i18n('account.next-payment.bgNumber')} value={bgNumber} />
            )}

            {(paidLessLab1 || hasNotPaid) && ocr && <LabelValue label={i18n('account.next-payment.ocr')} value={ocr} />}
        </>
    );
};

TheData.propTypes = {
    bill: PropTypes.object,
    billProps: PropTypes.shape({
        canPaySomeAmount: PropTypes.bool,
        dueDate: PropTypes.string,
        hasNotPaid: PropTypes.bool.isRequired,
        hasPaidAll: PropTypes.bool.isRequired,
        isZeroInvoice: PropTypes.bool.isRequired,
        lab2: PropTypes.number.isRequired,
        lab3: PropTypes.number.isRequired,
        paidBetweenLab1Lab3: PropTypes.bool.isRequired,
        paidLessLab1: PropTypes.bool.isRequired,
        paidMoreLab3: PropTypes.bool.isRequired,
    }).isRequired,
    bgNumber: PropTypes.string,
    ocr: PropTypes.string,
};

TheData.defaultProps = {
    bill: {},
    bgNumber: null,
    ocr: null,
};

export const NextPaymentPanel = ({ account, className, bill, getAccountBillError, dismissGetAccountBillError }) => {
    const classes = classNames({
        'next-payment-panel': true,
        [className]: className,
    });

    const billProps = getBillProperties(bill);

    const monthlyInvoiceRoute = `/account/${account.reference}/invoice`;

    const hasGetAccountBillError = getObjectWithKeyFromArray(getAccountBillError, 'accountRef', account, 'reference');

    const isBillNotFoundError = hasGetAccountBillError?.error?.message?.code === 4100;

    if (isBillNotFoundError) {
        // Don't show error, The bill has not yet been created.
        return (
            <div className={classes}>
                <TheHeader heading={`${i18n('account.next-payment.no-invoice.header')}`} />
                {i18n('account.next-payment.no-invoice.text')}
            </div>
        );
    }

    if (hasGetAccountBillError) {
        const accountBillError = hasGetAccountBillError.error;

        return (
            <GeneralErrorMessagePanel
                icon={null}
                header={null}
                body={i18n('general.error.general.body')}
                error={accountBillError}
                dismissError={dismissGetAccountBillError}
                isLink
                getAppInfo={getDebugInfo}
            />
        );
    }

    const detailLink = (
        <Link
            className="detail-link"
            purple
            iconRight="icon-chevron-right"
            iconColorClass="e-black"
            to={monthlyInvoiceRoute}
            underline={false}
            id="show-detailed-invoice-tablet-or-desktop"
        >
            {i18n('account.next-payment.show-details')}
        </Link>
    );

    return (
        <div className={classes}>
            {billProps.isZeroInvoice ? (
                <>
                    <TheHeader heading={`${i18n('account.next-payment.zero-invoice.header')}`} billProps={billProps} />
                    {i18n('account.next-payment.zero-invoice.text')}
                </>
            ) : billProps.hasBill ? (
                <>
                    <Mobile>
                        <Link
                            to={monthlyInvoiceRoute}
                            className="link-no-style"
                            id="show-detailed-invoice-mobile"
                            underline={false}
                        >
                            <TheHeader
                                icon="icon-chevron-right"
                                heading={`${i18n('account.next-payment.header')}`}
                                billProps={billProps}
                            />
                            <TheData billProps={billProps} bill={bill} />
                        </Link>
                    </Mobile>
                    <TabletOrDesktop>
                        <TheHeader heading={`${i18n('account.next-payment.header')}`} billProps={billProps} />
                        <TheData billProps={billProps} bill={bill} />
                        {detailLink}
                    </TabletOrDesktop>
                </>
            ) : (
                <>
                    <TheHeader heading={`${i18n('account.next-payment.no-invoice.header')}`} billProps={billProps} />
                    {i18n('account.next-payment.no-invoice.text')}
                </>
            )}
        </div>
    );
};

NextPaymentPanel.propTypes = {
    account: PropTypes.object.isRequired,
    bill: PropTypes.object,
    className: PropTypes.string,
    getAccountBillError: PropTypes.any,
    dismissGetAccountBillError: PropTypes.func,
};

NextPaymentPanel.defaultProps = {
    className: '',
    bill: {},
    getAccountBillError: null,
    dismissGetAccountBillError: () => {},
};
