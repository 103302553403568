import React from 'react';
import { i18n } from '@ecster/i18n';
import { Mobile } from '@ecster/components';
import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import ActivationStepsPanel from './components/ActivationStepsPanel';
import DownloadAppPanel from './components/DownloadAppPanel';
import NoSupportPanel from '../common/NoSupportPanel';
import { APP_STORE_URL, GOOGLE_PLAY_URL } from './constants';

export default class HowToPage extends React.Component {
    state = {};

    render() {
        return (
            <AuthenticatedSubPageTemplate header={i18n('secure-id.how-to.page-header')}>
                <div className="sca-how-to-page">
                    <ActivationStepsPanel />
                    <DownloadAppPanel />
                    <Mobile>
                        <NoSupportPanel
                            header={i18n('secure-id.no-support.no-support-panel.header')}
                            content={i18n('secure-id.no-support.no-support-panel.content')}
                            iosUrl={APP_STORE_URL}
                            androidUrl={GOOGLE_PLAY_URL}
                        />
                    </Mobile>
                </div>
            </AuthenticatedSubPageTemplate>
        );
    }
}
