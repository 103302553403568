import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton, Panel, PanelContent } from '@ecster/components';
import { i18n } from '@ecster/i18n';

import { rewardIcon } from '@ecster/icons/H80/green';

import './ExternalBonusSiteHeader.scss';

export const ExternalBonusSiteHeader = ({ loyaltyDetails, loyaltyId }) => {
    return (
        <Panel paddingAll="large" className="external-bonus-site-header" sideMarginsInMobile>
            <PanelContent fullWidth>
                <img src={rewardIcon} alt="reward icon" />
                <div className="content">
                    <h2 className="h3">{i18n('account.bonus-overview-page.external-site-header.header')}</h2>
                    <p>{i18n(`account.bonus-overview-page.external-site-header.${loyaltyId}.text`)}</p>
                    <LinkButton href={loyaltyDetails.bonusSiteUrl} round outline iconRight="icon-external-link">
                        {i18n(`account.bonus-overview-page.external-site-header.${loyaltyId}.button`)}
                    </LinkButton>
                </div>
            </PanelContent>
        </Panel>
    );
};

ExternalBonusSiteHeader.propTypes = {
    loyaltyDetails: PropTypes.object.isRequired,
    loyaltyId: PropTypes.string.isRequired,
};
