import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './PanelContent.scss';
import { getWidthClasses } from './content-width-classes';

interface PanelContentProps {
    /** className **/
    className?: string;

    /** the content */
    children: ReactNode;

    /** width 325px (100% in mobile) */
    xNarrow?: boolean;

    /** width 500px (100% in mobile) */
    narrow?: boolean;

    /** width 680px (100% in mobile) */
    wide?: boolean;

    /** width 720px (100% in mobile) */
    xWide?: boolean;

    /** width 1078px (100% in mobile) */
    xxWide?: boolean;

    /** width 100% */
    fullWidth?: boolean;

    /** width 100% with 15px extra space left and right */
    fullWidthPadded?: boolean;

    /** pad 15px top, bottom, left & right, useful if bg color */
    padded?: boolean;

    /** extra css classes */
    classname?: string;

    /**
     * Centered content
     */
    centered?: boolean;
}

export const PanelContent = ({ children, className, padded, centered, ...rest }: PanelContentProps) => {
    const widthClasses = getWidthClasses(rest);
    const classes = classNames({
        'ec-panel-content': true,
        'all-padded': padded,
        'centered-content': centered,
        [className]: true,
        [widthClasses]: true,
    });

    return <div className={classes}>{children}</div>;
};

PanelContent.defaultProps = {
    xNarrow: false,
    narrow: false,
    wide: false,
    xWide: false,
    xxWide: false,
    fullWidth: false,
    fullWidthPadded: false,
    padded: false,
    className: '',
    centered: false,
};
