import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-slideshow-image';

export default class SlideShow extends React.Component {
    componentDidMount() {
        this.onChange();
    }

    onChange = () => {
        const remove = obj => obj.removeAttribute('tabIndex');
        const add = obj => obj.setAttribute('tabIndex', '-1');

        const wrapper = document.querySelector('.react-slideshow-container');

        if (!wrapper) return;

        const showing = wrapper?.querySelector('[aria-hidden="false"]');
        [...showing.querySelectorAll('a')].map(focusable => {
            remove(focusable);
            return null;
        });
        remove(showing);

        const hidden = [...(wrapper?.querySelectorAll('[aria-hidden="true"]') || [])];
        hidden.map(wrap => {
            [...wrap.querySelectorAll('a')].map(focusable => {
                add(focusable);
                return null;
            });
            add(wrap);
            return null;
        });
    };

    render() {
        const { children, duration, startIndex, transitionDuration, infinite, autoplay, indicators, arrows, onChange } =
            this.props;

        const properties = {
            duration,
            transitionDuration,
            infinite,
            autoplay,
            indicators,
            arrows,
            defaultIndex: startIndex,
            onChange,
        };

        if (!children || children.length === 0) return null;

        return (
            <div className="common-slide-show slide-container">
                {children.length === 1 ? (
                    children[0]
                ) : (
                    <Fade {...properties} onChange={this.onChange}>
                        {children}
                    </Fade>
                )}
            </div>
        );
    }
}

SlideShow.propTypes = {
    children: PropTypes.node.isRequired,
    duration: PropTypes.number,
    transitionDuration: PropTypes.number,
    startIndex: PropTypes.number,
    infinite: PropTypes.bool,
    autoplay: PropTypes.bool,
    indicators: PropTypes.bool,
    arrows: PropTypes.bool,
    onChange: PropTypes.func,
};
SlideShow.defaultProps = {
    duration: 999999,
    transitionDuration: 500,
    startIndex: 0,
    infinite: true,
    autoplay: true,
    indicators: true,
    arrows: true,
    onChange: () => {},
};
