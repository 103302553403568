import React from 'react';

import { blockCardIcon } from '@ecster/icons/H15/green';
import { i18n } from '@ecster/i18n';
import { ExpandableContainer, LabelValue } from '@ecster/components';

import { ExpandHeader } from './ExpandHeader';

export const BlockCardInfo = () => {
    return (
        <ExpandableContainer
            id="contact-us-loan-leasing-contract"
            header={
                <ExpandHeader
                    icon={<img className="adjust-top" src={blockCardIcon} alt="" width="24px" height="18px" />}
                    text={i18n('customer.contact-us.block-card.header')}
                    info={i18n('customer.contact-us.block-card.opening-hours')}
                />
            }
            stretch
        >
            <div className="expanded-content w67">
                <LabelValue
                    label={i18n('customer.contact-us.block-card.from-domestic-label')}
                    value={i18n('customer.contact-us.block-card.from-domestic-number', {
                        danger: true,
                    })}
                />
                <LabelValue
                    label={i18n('customer.contact-us.block-card.from-abroad-label')}
                    value={i18n('customer.contact-us.block-card.from-abroad-number', {
                        danger: true,
                    })}
                />
            </div>
        </ExpandableContainer>
    );
};
