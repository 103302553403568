import React from 'react';
import PropTypes from 'prop-types';
import { Link, Message } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { bonusIcon } from '@ecster/icons/H40/purple';

import './UserMessage.scss';

const UserMessage = ({ header, text, linkText, url }) => {
    return (
        <Message whiteBg icon={<img src={bonusIcon} alt="" />} purple header={header}>
            <p>{text}</p>
            <Link to={url} purple iconRight="icon-chevron-right">
                {linkText}
            </Link>
        </Message>
    );
};

UserMessage.propTypes = {
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export const UserMessageInactiveCard = ({ accountRef }) => (
    <UserMessage
        header={i18n('account.bonus-overview-page.user-message.inactive-card.header')}
        text={i18n('account.bonus-overview-page.user-message.inactive-card.text')}
        linkText={i18n('account.bonus-overview-page.user-message.inactive-card.link-text')}
        url={`/card/${accountRef}`}
    />
);
UserMessageInactiveCard.propTypes = { accountRef: PropTypes.string.isRequired };
