// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { LicensePage2 } from '.';

const routes = {
    path: 'about',
    name: 'About',
    childRoutes: [{ path: 'license', name: 'License page', component: LicensePage2, isPublic: true }],
};

export default routes;
