import { detectDevice, Link, LinkButton, Panel, PanelContent, Mobile, TabletOrDesktop } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import PropTypes from 'prop-types';
import React from 'react';
import './DownloadAppPanel.scss';
import { secureAppIcon } from '@ecster/icons/secure';
import QrCode from 'qrcode.react';
import { appStoreIcon } from '@ecster/icons/ios';
import { googlePlayIcon } from '@ecster/icons/android';
import classNames from 'classnames';
import { APP_STORE_URL, GOOGLE_PLAY_URL, QR_URL } from '../constants';

const DownloadAppPanel = ({ withLink }) => {
    const ACTIVATION_ROUTE_URL = '/secure-id/verify';
    const { isIOS, isAndroid } = detectDevice();
    const noSupport = !isIOS && !isAndroid;

    return (
        <Panel className="download-app-panel" stretchInMobile>
            <PanelContent centered>
                <img src={secureAppIcon} className="sca-icon" alt={i18n('secure-id.download.header')} />
                <h3>{i18n('secure-id.download.header')}</h3>
                <p>{i18n('secure-id.download.content')}</p>
                <div
                    className={classNames({
                        'store-icon-container': true,
                        noSupport,
                    })}
                >
                    <Mobile>
                        {isIOS && (
                            <Link
                                noUnderlineOnFocus
                                href={APP_STORE_URL}
                                className="store-icon-link"
                                ariaLabel={i18n('secure-id.common.app-store')}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={appStoreIcon} className="appstore-icon" alt="Appstore" />
                            </Link>
                        )}
                        {isAndroid && (
                            <Link
                                noUnderlineOnFocus
                                href={GOOGLE_PLAY_URL}
                                className="store-icon-link"
                                ariaLabel={i18n('secure-id.common.google-play')}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={googlePlayIcon} className="googleplay-icon" alt="Google play" />
                            </Link>
                        )}
                    </Mobile>
                    <TabletOrDesktop>
                        <QrCode
                            id="card-link-qr-code"
                            size={130}
                            value={QR_URL}
                            bgColor="#fff"
                            fgColor="#333"
                            level="Q"
                            renderAs="svg"
                        />
                        <p className="qr-text">{i18n('secure-id.download.scan')}</p>
                    </TabletOrDesktop>
                </div>
                {withLink && (
                    <div className="link-container">
                        <LinkButton to={ACTIVATION_ROUTE_URL} ariaLabel={i18n('secure-id.download.link')} round green>
                            {i18n('secure-id.download.link')}
                        </LinkButton>
                    </div>
                )}
            </PanelContent>
        </Panel>
    );
};

DownloadAppPanel.propTypes = {
    withLink: PropTypes.bool,
};

DownloadAppPanel.defaultProps = {
    withLink: false,
};

export default DownloadAppPanel;
