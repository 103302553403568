// NOTE: don't rename initialState constant, needed when rekit adds actions.
const initialState = {
    enrolledDevices: null,
    isDeviceEnrolled: undefined,
    getSecureActivationKeyData: null,

    // generated
    getEnrolledDevicesPending: false,
    getEnrolledDevicesError: null,
    getSecureActivationKeyPending: false,
    getSecureActivationKeyError: null,
    deleteSecureIdPending: false,
    deleteSecureIdError: null,
};

export default initialState;
