import PropTypes from 'prop-types';
import { LinkButton, ButtonGroup, Panel, PanelContent, withMediaQueries } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import React from 'react';
import './ActivationPanel.scss';
import { secureAppIcon } from '@ecster/icons/secure';

const ActivationPanel = ({ media, hasError }) => {
    const { onMobile } = media;

    const HOW_TO_ROUTE = '/secure-id/how-to';
    const ACTIVATION_ROUTE = '/secure-id/download';

    return (
        <Panel className="activation-panel" stretchInMobile>
            <PanelContent centered>
                <img src={secureAppIcon} className="sca-icon" alt={i18n('secure-id.overview.page-header')} />
                <h3>{i18n('secure-id.overview.activation.header')}</h3>
                <p>{i18n('secure-id.overview.activation.paragraph')}</p>
                <ButtonGroup alignColumn={onMobile} alignCenter={!onMobile} spaceBelow={false}>
                    <LinkButton
                        to={HOW_TO_ROUTE}
                        ariaLabel={i18n('secure-id.overview.activation.secondary')}
                        transparent
                        round
                        green
                    >
                        {i18n('secure-id.overview.activation.secondary')}
                    </LinkButton>
                    {!hasError && (
                        <LinkButton
                            to={ACTIVATION_ROUTE}
                            ariaLabel={i18n('secure-id.overview.activation.primary')}
                            round
                        >
                            {i18n('secure-id.overview.activation.primary')}
                        </LinkButton>
                    )}
                </ButtonGroup>
            </PanelContent>
        </Panel>
    );
};

ActivationPanel.propTypes = {
    media: PropTypes.object.isRequired,
    hasError: PropTypes.bool,
};

ActivationPanel.defaultProps = {
    hasError: false,
};

export default withMediaQueries(ActivationPanel);
