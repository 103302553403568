import React, { Children, ReactNode, useState } from 'react';
import classNames from 'classnames';

import { detectView } from '../DeviceDetect/DeviceTypes';

import './Tabs.scss';

interface TabButtonProps {
    width: string;
    label: string;
    index: number;
    disabled?: boolean;
    activeIndex: number;
    onClick: Function;
    id?: string;
    themeClasses: string;
    isMobile: boolean;
}

const TabButton = ({
    activeIndex,
    label,
    onClick,
    index,
    width,
    id = '',
    themeClasses,
    disabled = false,
    isMobile,
}: TabButtonProps) => {
    const classes = classNames({
        'ec-tab-button': true,
        'is-active': index === activeIndex,
    });

    let separatorClasses = 'ec-separator';
    if (index === activeIndex || index + 1 === activeIndex) {
        separatorClasses += ` is-hidden`;
    }

    return (
        <div className={`${classes} ${themeClasses}`} style={isMobile ? { width } : {}}>
            <button
                id={id}
                type="button"
                disabled={disabled}
                style={isMobile ? {} : { width }}
                onClick={() => {
                    onClick(label, index, id);
                }}
            >
                {label}
            </button>
            {!isMobile && <div className={separatorClasses} />}
        </div>
    );
};

interface TabsProps {
    /** usage method #1 - two or more <Tab> components as children */
    children?: ReactNode;

    /**
     * usage method #2 - labels array (and an event handler).
     *
     * an array of strings or
     *
     * an array of objects { label, id }, id is optional
     */
    labels?: string[] | { label: string; id?: string }[];

    /** usage method #2 - an event handler (and a labels array).
     *
     * @param index - the zero based tab index of the clicked tab
     * @param prevIndex - the zero based index of the previously active tab
     */
    onTabChange?: Function;

    /** usage method #2 - Control active tab state externally, otherwise internally */
    activeIndex?: number;

    // appearance options

    /** show the */
    border?: boolean;

    /** smaller design */
    small?: boolean;

    /** 100% rounded corners on outer tabs + indicator gets same height as background */
    round?: boolean;

    /**
     * Add extra side margins in mobile.
     */
    sideMarginsInMobile?: boolean;

    /** dark design */
    dark?: boolean;

    /** An array of tab indexes to be disabled. Note: indexes are zero based */
    disabled?: number[];

    // colors

    /** green design */
    green?: boolean;

    /** purple design */
    purple?: boolean;

    /** red design */
    red?: boolean;

    /** blue design */
    blue?: boolean;

    /** payGreen design */
    payGreen?: boolean;

    /** dark colored text on light colored indicator */
    invertColors?: boolean;

    /**
     * Button width in desktop and tablet.
     * Numeric pixel value without the 'px' suffix
     */
    buttonWidth?: number;

    /**
     * Button width in mobile
     * Numeric pixel value without the 'px' suffix
     */
    buttonWidthMobile?: number;
}

export const Tabs = ({
    // usage #1
    children,
    // usage #2
    labels,
    activeIndex: activeIndexProp,
    // appearance,
    border,
    small,
    round,
    dark,
    disabled,
    sideMarginsInMobile,
    // colors
    green,
    purple,
    red,
    blue,
    payGreen,
    invertColors,
    // widths
    buttonWidth: buttonWidthDesktopTablet,
    buttonWidthMobile,
    // event handler
    onTabChange,
}: TabsProps) => {
    const [state, setState] = useState({
        activeTab:
            labels.length > 0 ? (typeof labels[0] === 'string' ? labels[0] : labels[0].label) : children[0].props.label,
        activeIndex: activeIndexProp || 0,
    });

    const onClickTabItem = (label: string, index: number, id: string) => {
        const { activeIndex: prevIndex } = state;

        setState({ activeTab: label, activeIndex: index });

        if (typeof onTabChange === 'function') {
            onTabChange(index, prevIndex);
        }
    };

    const view = detectView();
    const desktopExtra = 100;

    const { activeTab, activeIndex: activeIndexState } = state;

    // label array or Tab children:
    // filter out non Tab objects, e.g. "false" entries from code like this: {showTabX && <Tab> ... </Tab>}
    const activeIndex = activeIndexProp !== null ? activeIndexProp : activeIndexState;
    const tabs =
        labels.length > 0
            ? labels.map((item, index) => {
                  return typeof item === 'string' ? { label: item, id: `ec-tab-id-${index}}` } : item;
              })
            : Children.toArray(children).filter(
                  child => typeof child === 'object' && ('props' in child ? child.props.label : false)
              );

    const noOfTabs = tabs.length;
    const buttonWidth = view.isMobileView ? 100 / noOfTabs : small ? buttonWidthMobile : buttonWidthDesktopTablet;

    const paddingWidth = round ? 0 : 4;
    const separatorWidth = round ? 2 : 1;
    const noOfSeparators = tabs.length - 1;

    // space occupied by padding, separators and separator margins
    // [ (  foo  ) | (  Bar  ) | (  Bee  ) ]]
    const nonButtonSpace = 2 * paddingWidth + noOfSeparators * separatorWidth + noOfSeparators * paddingWidth * 2;

    const allItemsWidth = view.isMobileView
        ? sideMarginsInMobile
            ? 'calc(100% - 30px)'
            : '100%'
        : `${noOfTabs * buttonWidth + nonButtonSpace}px`;
    const contentWidth = view.isMobileView
        ? sideMarginsInMobile
            ? 'calc(100% - 30px)'
            : '100%'
        : `${noOfTabs * buttonWidth + desktopExtra}px`;

    const indicatorPosition = paddingWidth + activeIndex * (2 * paddingWidth + separatorWidth + buttonWidth);

    // arrow above content
    const arrowPosition = view.isMobileView
        ? `${activeIndex * buttonWidth + buttonWidth / 2}%`
        : `${activeIndex * buttonWidth + desktopExtra / 2 + buttonWidth / 2}px`;

    const themeClasses = classNames({
        'is-green': green && !purple && !red && !blue && !payGreen,
        'is-purple': purple,
        'is-red': red,
        'is-blue': blue,
        'is-pay-green': payGreen,
        'is-small': small || view.isMobileView,
        'is-round': round,
        'is-dark': dark && round, // dark is used with round only
        'has-border': border,
        'has-inverted-colors': invertColors && !dark, // inverted colors not valid together with dark
        'first-is-active': activeIndex === 0,
        'last-is-active': activeIndex === tabs.length - 1,
    });

    // const buttonWidthCss = buttonWidth + (view.isMobileView ? '%' : 'px');
    const buttonWidthCss = view.isMobileView
        ? `calc((100% - 2 * ${paddingWidth}px) / ${noOfTabs})`
        : `${buttonWidth}px`;

    return (
        <div className={`ec-tabs ${themeClasses}`}>
            <div className={`ec-tab-nav ${themeClasses}`} style={{ width: allItemsWidth }}>
                {!view.isMobileView && (
                    <div
                        className={`ec-tab-active-indicator z1 ${themeClasses}`}
                        style={{ left: indicatorPosition, width: buttonWidthCss }}
                    />
                )}

                <div className="ec-tab-buttons z2">
                    {tabs.map((tab, index) => {
                        const { label, id } = tab.props ? tab.props : tab;
                        return (
                            <TabButton
                                activeIndex={activeIndex}
                                key={label}
                                label={label}
                                onClick={onClickTabItem}
                                index={index}
                                disabled={disabled.includes(index)}
                                width={buttonWidthCss}
                                id={id}
                                themeClasses={themeClasses}
                                isMobile={view.isMobileView}
                            />
                        );
                    })}
                </div>
            </div>
            {children && Children.count(children) > 0 && (
                <div
                    className={`ec-tab-content-container ${themeClasses}`}
                    style={border ? { width: contentWidth } : {}}
                >
                    {border && (
                        <div className="ec-tab-content-arrow-pos" style={{ left: arrowPosition }}>
                            <div className={`ec-tab-content-arrow ${themeClasses}`} />
                        </div>
                    )}
                    {/* TODO: can we change to matching index === activeIndex? tabs.map((child, index) => ... etc */}
                    {tabs.map(child => {
                        const show = child.props.label === activeTab;
                        return show && child;
                    })}
                </div>
            )}
        </div>
    );
};

Tabs.defaultProps = {
    // usage method #1
    children: null,

    // usage method #2
    labels: [],
    onTabChange: () => {},
    activeIndex: null,
    // appearance options
    border: false,
    small: false,
    round: false,
    dark: false,
    disabled: [],
    sideMarginsInMobile: false,
    // colors
    green: true,
    purple: false,
    red: false,
    // yellow: false,
    blue: false,
    // beige: false,
    payGreen: false,
    invertColors: false,

    // widths
    buttonWidth: 180,
    buttonWidthMobile: 150,
};
