import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { ExpandableContainer, Panel, PanelContent } from '@ecster/components';

import { getLoyaltyProgramKey, getLoyaltyProgramPointValidity } from './getLoyaltyProgramInfo';

import './FaqPanel.scss';

const Faq = ({ i18nKey, i18nOptions }) => (
    <ExpandableContainer
        id={`faq-${i18nKey}`}
        className="bonus-faq"
        stretch
        header={i18n(`account.bonus-overview-page.faq.${i18nKey}.q`)}
    >
        {i18n(`account.bonus-overview-page.faq.${i18nKey}.a`, 'p', i18nOptions)}
    </ExpandableContainer>
);
Faq.propTypes = { i18nKey: PropTypes.string.isRequired, i18nOptions: PropTypes.object };
Faq.defaultProps = { i18nOptions: {} };

export const FaqPanel = ({ loyaltyId, bonusSiteType }) => {
    return (
        <Panel className="bonus-page-faq" stretchInMobile>
            <PanelContent narrow>
                <h2 className="h3">{i18n('account.bonus-overview-page.faq.header')}</h2>
                <Faq i18nKey={getLoyaltyProgramKey(loyaltyId)} />
                {bonusSiteType !== 'EXTERNAL' && (
                    <>
                        <Faq i18nKey="general.q1" i18nOptions={{ valid: getLoyaltyProgramPointValidity(loyaltyId) }} />
                        <Faq i18nKey="general.q2" />
                    </>
                )}
            </PanelContent>
        </Panel>
    );
};

FaqPanel.propTypes = {
    loyaltyId: PropTypes.string.isRequired,
    bonusSiteType: PropTypes.string.isRequired,
};

FaqPanel.defaultProps = {};
