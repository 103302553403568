/* eslint-disable react/prop-types */
import React from 'react';
import { Link, LabelValue } from '@ecster/components';
import { i18n } from '@ecster/i18n';

// hardcoded sv-FI fi-FI, it's an FI specific component
export const LoginHelpFIFI = ({ footer }) => (
    <div className="login-help">
        <h1> Tarvitsetko apua sisäänkirjautumisessa? </h1>

        <h2> Kirjaudu sisään pankkitunnuksilla</h2>

        <p>Kirjautuaksesi sinun tulee olla Ecsterin asiakas. Tarvittaessa voit olla yhteydessä asiakaspalveluumme.</p>

        <h2>Onko sinulla muita kysymyksiä?</h2>

        <p>
            Mikäli sinulla on kysymyksiä tai tarvitset apua, voit vierailla&nbsp;
            <Link
                href="https://ecster.fi/asiakaspalvelu"
                target="_blank"
                id="link-customer-support-page-link-fifi"
                iconRight="icon-external-link"
            >
                kotisivuillamme
            </Link>
        </p>
        <div className="w50">
            <LabelValue
                label="Puhelinpalvelu"
                value={
                    <>
                        {i18n('general.phone-link-0104442600', { danger: true })}
                        <span> (pvm/mpm)</span>
                    </>
                }
            />
        </div>

        <h2> Henkilötietojen käsittely </h2>

        <p className="mb-3x">Pidämme yksilön oikeutta suojata yksityisyytensä ensiarvoisen tärkeänä. Lue lisää:</p>

        <Link
            href="https://www.ecster.fi/henkilotietojen-kasittely"
            target="_blank"
            id="link-ecster-fifi-gdpr"
            iconRight="icon-external-link"
        >
            Henkilötietojen käsittely
        </Link>

        {footer}
    </div>
);

export const LoginHelpSVFI = ({ footer }) => (
    <div className="login-help">
        <h1> Behöver du hjälp med att logga in?</h1>

        <h2> Logga in med dina nätbankskoder</h2>

        <p>För att logga in behöver du vara kund hos Ecster. Vid behov kan du kontakta vår kundservice.</p>

        <h2>Har du några andra frågor?</h2>

        <p>
            Om du har några frågor eller behöver hjälp kan du besöka vår&nbsp;
            <Link
                href="https://www.ecster.fi/sv/Kundservice"
                target="_blank"
                id="link-customer-support-page-link-svfi"
                iconRight="icon-external-link"
            >
                kundservice-sida
            </Link>
        </p>

        <div className="w50">
            <LabelValue
                label="Telefontjänst"
                value={
                    <>
                        {i18n('general.phone-link-0104442600', { danger: true })}
                        <span> (lna/msa)</span>
                    </>
                }
            />
        </div>

        <h2> Hantering personuppgifter </h2>

        <p className="mb-3x">
            Vi vill att du ska känna dig trygg när du lämnar dina personuppgifter till oss. Läs mer:
        </p>

        <Link
            href="https://www.ecster.fi/sv/hantering-av-personuppgifter"
            target="_blank"
            id="link-ecster-svfi-gdpr"
            iconRight="icon-external-link"
        >
            Hantering av personuppgifter
        </Link>

        {footer}
    </div>
);
