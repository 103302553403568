import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonGroup, LinkButton, Mobile, TabletOrDesktop } from '@ecster/components';

const Wrapper = ({ url, children }) => {
    return (
        <div className="iw-bg-wrapper" style={{ backgroundImage: `url(${url})` }}>
            {children}
        </div>
    );
};
Wrapper.propTypes = {
    children: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
};

const Content = ({ classes, stripeText, header, text, children, linkProps, linkText, linkTextScreenreader }) => (
    <div className={classes}>
        {stripeText && <div className="iw-stripe">{stripeText}</div>}
        <h2 className="iw-header">{header}</h2>
        <p className="iw-text">{text || children}</p>
        <ButtonGroup alignCenter spaceBelow={false} spaceAbove={false}>
            <LinkButton {...linkProps}>
                {linkText} {linkTextScreenreader && <span className="screenreader">{linkTextScreenreader}</span>}
            </LinkButton>
        </ButtonGroup>
    </div>
);

Content.propTypes = {
    classes: PropTypes.string.isRequired,
    stripeText: PropTypes.string,
    header: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.node,
    linkProps: PropTypes.object,
    linkText: PropTypes.string.isRequired,
    linkTextScreenreader: PropTypes.string,
};

Content.defaultProps = {
    text: null,
    children: null,
    linkProps: null,
    linkTextScreenreader: '',
    stripeText: null,
};

export default class InfoWidget extends React.Component {
    state = {};

    render() {
        const {
            id,
            header,
            text,
            children,
            bgImage,
            bgImageMobile,
            linkUrl,
            linkText,
            linkTextScreenreader,
            linkTarget,
            stripeText,
            greenTheme,
            whiteTheme,
        } = this.props;

        if (!text && !children) return null;

        const linkProps = {
            white: whiteTheme || !greenTheme,
            green: greenTheme,
            outline: true,
            round: true,
            href: linkUrl,
            target: linkTarget && linkTarget.match(/blank/) ? '_blank' : null,
            iconRight: linkTarget && linkTarget.match(/blank/) ? 'icon-external-link' : null,
        };

        const classes = classNames({
            'each-fade': true,
            'green-theme': greenTheme,
            'white-theme': whiteTheme || !greenTheme,
        });

        return (
            <div id={id} className="common-info-widget">
                <Mobile>
                    <Wrapper url={bgImageMobile || bgImage}>
                        <Content
                            header={header}
                            linkTextScreenreader={linkTextScreenreader}
                            text={text || children}
                            linkText={linkText}
                            classes={classes}
                            linkProps={linkProps}
                            stripeText={stripeText}
                        />
                    </Wrapper>
                </Mobile>
                <TabletOrDesktop>
                    <Wrapper url={bgImage}>
                        <Content
                            header={header}
                            linkTextScreenreader={linkTextScreenreader}
                            text={text || children}
                            linkText={linkText}
                            classes={classes}
                            linkProps={linkProps}
                            stripeText={stripeText}
                        />
                    </Wrapper>
                </TabletOrDesktop>
            </div>
        );
    }
}

InfoWidget.propTypes = {
    id: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    bgImageMobile: PropTypes.string,
    /** text content as prop */
    text: PropTypes.string,
    /** text content as 'children' */
    children: PropTypes.node,

    stripeText: PropTypes.string,

    linkUrl: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    linkTextScreenreader: PropTypes.string,
    linkTarget: PropTypes.string,
    whiteTheme: PropTypes.bool,
    greenTheme: PropTypes.bool,
};

InfoWidget.defaultProps = {
    bgImageMobile: null,
    text: null,
    children: null,
    stripeText: null,
    linkTarget: null,
    whiteTheme: false,
    greenTheme: false,
    linkTextScreenreader: '',
};
