import React from 'react';
import { detectDevice, Message } from '@ecster/components';
import { useMyPagesConfig } from '../hooks/useMyPagesConfig';
import './AppWillCloseMessage.scss';
import PropTypes from 'prop-types';

// TODO: 2024-03-01: keep or remove? Use later for closing Inloggat web?
export const AppWillCloseMessage = ({ infoInEnglish }) => {
    const { EcsterConfig: conf } = window;
    const { isDesktop } = detectDevice();

    const myPagesConfig = useMyPagesConfig();

    const mobileAppShutdownConfig = myPagesConfig?.mobileAppShutdownConfig;

    const devOrTest = conf.env === 'dev' || conf.env === 'test';

    if (mobileAppShutdownConfig?.status === 'WARNING' && (conf.isApp || (devOrTest && !isDesktop))) {
        return (
            <div className="app-closing-ctr">
                <Message icon={false} blue header={mobileAppShutdownConfig.title}>
                    <div dangerouslySetInnerHTML={{ __html: mobileAppShutdownConfig.text }} />
                </Message>
            </div>
        );
    }
    return null;
};

AppWillCloseMessage.propTypes = {
    infoInEnglish: PropTypes.bool,
};

AppWillCloseMessage.defaultProps = {
    infoInEnglish: false,
};
