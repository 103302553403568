import React from 'react';
import './ActivateNewDevicePanel.scss';
import {
    Panel,
    PanelContent,
    Button,
    DialogCloseButton,
    DialogBody,
    DialogFooter,
    ButtonGroup,
    Dialog,
    Message,
} from '@ecster/components';
import { secureActivateNewIcon } from '@ecster/icons/secure';
import { i18n } from '@ecster/i18n';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxActionFailed, reduxActionSucceeded } from '@ecster/util';
import { deleteSecureId } from '../redux/deleteSecureId';
import history from '../../../common/history';

const VERIFY_ROUTE = '/secure-id/verify';

class ActivateNewDevicePanel extends React.Component {
    state = {
        isOpen: false,
        hasError: false,
    };

    componentDidUpdate(prevProps) {
        const { isOpen } = this.state;

        if (isOpen && reduxActionSucceeded('deleteSecureId', prevProps, this.props)) {
            history.push(VERIFY_ROUTE);
        }
        if (isOpen && reduxActionFailed('deleteSecureId', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ hasError: true, isOpen: false });
        }
    }

    onConfirm() {
        const {
            person: { id },
            deleteSecureId,
            enrolledDevices: { devices },
        } = this.props;

        const deviceId = devices[0].id;

        deleteSecureId(id, deviceId);
    }

    render() {
        const { isOpen, hasError } = this.state;

        return (
            <Panel className="activate-new-device-panel" stretchInMobile>
                <PanelContent centered>
                    <img src={secureActivateNewIcon} className="activate-icon" alt="" />
                    <h3 className="heading">{i18n('secure-id.overview.activate-new.header')}</h3>
                    <p className="content">{i18n('secure-id.overview.activate-new.content')}</p>

                    <Button round outline green onClick={() => this.setState({ isOpen: true })}>
                        {i18n('secure-id.overview.activate-new.button')}
                    </Button>

                    {hasError && (
                        <Message
                            exclamation
                            purple
                            className="delete-error-message"
                            header={i18n('general.error.general.header')}
                            message={i18n('general.error.general.body')}
                        />
                    )}
                </PanelContent>

                <Dialog
                    className="activate-new-dialog"
                    maxWidth="520px"
                    open={isOpen}
                    onRequestClose={() => this.setState({ isOpen: false })}
                >
                    <DialogCloseButton onRequestClose={() => this.setState({ isOpen: false })} />
                    <DialogBody centeredContent>
                        <h3>{i18n('secure-id.overview.activate-new.dialog.header')}</h3>
                        <p>{i18n('secure-id.overview.activate-new.dialog.content-1')}</p>
                        <p>{i18n('secure-id.overview.activate-new.dialog.content-2')}</p>
                    </DialogBody>
                    <DialogFooter alignCenter>
                        <ButtonGroup alignCenter>
                            <Button green round onClick={() => this.onConfirm()}>
                                {i18n('secure-id.overview.activate-new.dialog.confirm')}
                            </Button>
                        </ButtonGroup>
                    </DialogFooter>
                </Dialog>
            </Panel>
        );
    }
}

ActivateNewDevicePanel.propTypes = {
    person: PropTypes.object.isRequired,
    enrolledDevices: PropTypes.object.isRequired,
    deleteSecureId: PropTypes.func.isRequired,
    deleteSecureIdPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    deleteSecureIdError: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
};

/* istanbul ignore next */
function mapStateToProps({ authentication, secureId }) {
    return {
        person: authentication.person,
        enrolledDevices: secureId.enrolledDevices,
        deleteSecureId: secureId.deleteSecureId,
        deleteSecureIdPending: secureId.deleteSecureIdPending,
        deleteSecureIdError: secureId.deleteSecureIdError,
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        deleteSecureId: (customerId, deviceId) => dispatch(deleteSecureId(customerId, deviceId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateNewDevicePanel);
