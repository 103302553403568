import React from 'react';
import PropTypes from 'prop-types';

import AMEX from './logos/AMEX.svg';
import DANKORT from './logos/DANKORT.svg';
import DINERS from './logos/DINERS.svg';
import DISCOVER from './logos/DISCOVER.svg';
import JCB from './logos/JCB.svg';
import MAESTRO from './logos/MAESTRO.svg';
import MASTER from './logos/MASTER.svg';
import MASTERPASS from './logos/MASTERPASS.svg';
import NO_BRAND from './logos/NO-BRAND.svg';
import VISA from './logos/VISA.svg';

interface CardLogoProps {
    provider:
        | 'AMEX'
        | 'DANKORT'
        | 'DINERS'
        | 'DISCOVER'
        | 'JCB'
        | 'MAESTRO'
        | 'MASTER'
        | 'MASTERPASS'
        | 'VISA'
        | 'NO_BRAND';
    width?: number | string;
    height?: number | string;
    className?: string;
}

function CardLogo(props: CardLogoProps) {
    const urls = {
        AMEX,
        DANKORT,
        DINERS,
        DISCOVER,
        JCB,
        MAESTRO,
        MASTER,
        MASTERPASS,
        VISA,
        NO_BRAND,
    };

    const { provider, className } = props;

    const url = urls[provider];
    const { width } = props;
    const { height } = props;

    return <img className={className} src={url} alt={provider} width={width} height={height} />;
}

CardLogo.propTypes = {
    provider: PropTypes.oneOf([
        'AMEX',
        'DANKORT',
        'DINERS',
        'DISCOVER',
        'JCB',
        'MAESTRO',
        'MASTER',
        'MASTERPASS',
        'VISA',
        'NO_BRAND',
    ]).isRequired,

    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
};

CardLogo.defaultProps = {
    width: 'auto',
    height: 'auto',
    className: undefined,
};

export default CardLogo;
