import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link, Message, Panel, ResponsivePanel } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { NextPaymentPanel } from './NextPaymentPanel';
import { OverviewPanelHeader } from '../../common';
import { getAccountBillOverview } from '../redux/actions';

import './AccountPanel.scss';
import {
    LinkToAccountInvoices,
    LinkToAccountInvoice,
    LinkToAccountTerms,
    LinkToTransactions,
    LinkToCardBonus,
} from '../account-links/Links';
import { isFI, isSE } from '../../../common/country';
import { PaymentDialog } from '../PaymentDialog';
import { isValidSurplusAmount } from '../../../common/util/is-valid-surplus-amount';

class AccountPanelTerminatedAccount extends Component {
    state = {
        isPaymentDialogOpen: false,
    };

    componentDidMount() {
        const { getAccountBill, user, account } = this.props;
        getAccountBill(user.id, account.reference);
    }

    onStartRefund = e => {
        e.preventDefault() && e.stopPropagation();
        this.setState({ isPaymentDialogOpen: true });
    };

    onPaymentDialogRequestClose = () => {
        this.setState({ isPaymentDialogOpen: false });
    };

    render() {
        const {
            className,
            account,
            account: { loyaltiesAllowed },
            bill,
        } = this.props;

        const { isPaymentDialogOpen } = this.state;

        const classes = classNames({
            'account-panel': true,
            'terminated-account-panel': true,
            [className]: className,
        });

        const hasDebt = account.used > 0;
        const hasSurplusBalance = account.used < -100; // Överskott under 1kr räknas ej som överskott
        const hasBonusProgram = loyaltiesAllowed?.length > 0;
        const loyaltyId = hasBonusProgram && loyaltiesAllowed[0]?.loyaltyId?.toLowerCase();
        const loyaltyType = hasBonusProgram && loyaltiesAllowed[0]?.loyaltyType?.toLowerCase();

        const amountLabel = hasSurplusBalance
            ? i18n('account.terminated-account.label-refundable')
            : i18n('account.terminated-account.label-debt');

        const message = hasDebt ? (
            i18n('account.terminated-account.debt-info', 'p', { danger: true })
        ) : hasSurplusBalance ? (
            <>
                {i18n('account.terminated-account.refundable-info', 'p', { danger: true, link: 'https://scrive.com' })}
                {!isValidSurplusAmount(-account.used) &&
                    i18n('account.terminated-account.refundable-info-exceeded-limit', { danger: true })}
            </>
        ) : (
            <p>{i18n('account.terminated-account.terminated-info')}</p>
        );
        const header = hasSurplusBalance
            ? i18n('account.terminated-account.message-header-refundable')
            : i18n('account.terminated-account.message-header');

        return (
            <Panel paddingTopBottom="small" className={classes}>
                <OverviewPanelHeader
                    header={account.product.name}
                    amount1={Math.abs(account.used)}
                    amount1Label={amountLabel}
                />
                <Message className="mt-3x" blue={!hasDebt} purple={hasDebt} header={header}>
                    <>
                        {message}
                        {hasSurplusBalance && isValidSurplusAmount(-account.used) && (
                            <Link href="#" underline blue onClick={this.onStartRefund}>
                                {i18n('account.terminated-account.refundable-link-text')}
                            </Link>
                        )}
                    </>
                </Message>
                <ResponsivePanel separator className="account-panel-content" reverseInMobile>
                    {hasDebt ? (
                        <NextPaymentPanel account={account} bill={bill} bg={i18n('general.bgNumber.account')} />
                    ) : (
                        <div />
                    )}
                    <div className="arrow-links-panel">
                        <LinkToTransactions accountRef={account.reference} />
                        {hasDebt && isSE() && <LinkToAccountInvoice accountRef={account.reference} />}
                        {hasDebt && isFI() && <LinkToAccountInvoices accountRef={account.reference} />}
                        {hasBonusProgram && isSE() && (
                            <LinkToCardBonus
                                accountRef={account.reference}
                                loyaltyId={loyaltyId}
                                loyaltyType={loyaltyType}
                            />
                        )}
                        <LinkToAccountTerms accountRef={account.reference} />
                    </div>
                </ResponsivePanel>
                {isSE() && (
                    <PaymentDialog
                        account={account}
                        isOpen={isPaymentDialogOpen}
                        onRequestClose={this.onPaymentDialogRequestClose}
                    />
                )}
            </Panel>
        );
    }
}

AccountPanelTerminatedAccount.propTypes = {
    className: PropTypes.string,
    account: PropTypes.shape().isRequired,
    bill: PropTypes.shape(),
    getAccountBill: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
};

AccountPanelTerminatedAccount.defaultProps = {
    className: '',
    bill: {},
};

/* istanbul ignore next */
function mapStateToProps({ account }, ownProps) {
    return {
        bill: account.accountBillOverview[ownProps.account.reference],
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        getAccountBill: (customerId, reference) => dispatch(getAccountBillOverview(customerId, reference)),
    };
}

export { AccountPanelTerminatedAccount as Component };

export default connect(mapStateToProps, mapDispatchToProps)(AccountPanelTerminatedAccount);
