import { LoginPage, NoSupportPage } from '.';

const routes = {
    path: '/',
    name: 'Home',
    childRoutes: [
        {
            path: 'start',
            name: 'Login page',
            component: LoginPage,
            isIndex: true,
            isPublic: true,
            childRoutes: [],
        },
        {
            path: 'no-support',
            name: 'No support page',
            component: NoSupportPage,
            isPublic: true,
            childRoutes: [],
        },
    ],
};
export default routes;
