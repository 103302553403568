import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Ecster imports
import { i18n } from '@ecster/i18n';
import { reduxActionSucceeded } from '@ecster/util';
import { getCustomer } from '../customer/redux/getCustomer';
import history from '../../common/history';
import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import FinalActivationPanel from './components/FinalActivationPanel';
import DownloadAppPanel from './components/DownloadAppPanel';
import RetryPanel from './components/RetryPanel';
import { clearGetSecureActivationKeyState, dismissGetSecureActivationKeyError } from './redux/getSecureActivationKey';
import { getEnrolledDevices } from './redux/getEnrolledDevices';
import { ArraysOfObjectsAreEqualByKey } from './util';

const POLL_TIME_LIMIT = 1000 * 60 * 15;
const POLL_INTERVAL = 1000 * 5;

const OVERVIEW_ROUTE = '/secure-id/overview';

export class ActivatePage extends React.Component {
    state = {};

    componentDidMount() {
        this.startPolling();

        setTimeout(() => {
            this.stopPolling();
            history.push(OVERVIEW_ROUTE);
        }, POLL_TIME_LIMIT);
    }

    componentDidUpdate(prevProps) {
        if (reduxActionSucceeded('getEnrolledDevices', prevProps, this.props)) {
            const { enrolledDevices: { devices: prevDeviceList = [] } = {} } = prevProps;
            const { enrolledDevices: { devices: nextDeviceList = [] } = {}, isDeviceEnrolled } = this.props;

            const isDeviceListsEqual = ArraysOfObjectsAreEqualByKey(prevDeviceList, nextDeviceList, 'id');

            if (!isDeviceListsEqual && isDeviceEnrolled) {
                this.stopPolling();
                history.push(OVERVIEW_ROUTE);
            }
        }
    }

    componentWillUnmount() {
        const { clearGetSecureActivationKeyState, dismissGetSecureActivationKeyError } = this.props;
        clearGetSecureActivationKeyState();
        dismissGetSecureActivationKeyError();

        this.stopPolling();
    }

    startPolling = () => {
        if (this.pollInterval) {
            return;
        }
        this.pollInterval = setInterval(() => {
            const {
                person: { id },
                getEnrolledDevices,
            } = this.props;

            getEnrolledDevices(id);
        }, POLL_INTERVAL);
    };

    stopPolling = () => {
        if (this.pollInterval) {
            clearInterval(this.pollInterval);
            delete this.pollInterval;
        }
    };

    render() {
        const {
            enrolledDevices,
            activationKey,
            customer: { contactInformation: { phoneNumber = {} } = {} } = {},
        } = this.props;

        if (!enrolledDevices || !activationKey || !phoneNumber.countryCallingCode || !phoneNumber.number) {
            // user navigates directly to this page without previous activation steps and data fetching
            history.push(OVERVIEW_ROUTE);
            return null;
        }

        const number = phoneNumber.countryCallingCode + phoneNumber.number;
        const key = activationKey.activationKey;

        return (
            <AuthenticatedSubPageTemplate header={i18n('secure-id.activation.page-header')}>
                <div className="sca-activate-page">
                    <FinalActivationPanel activationKey={key} number={number} />
                    <DownloadAppPanel />
                    <RetryPanel />
                </div>
            </AuthenticatedSubPageTemplate>
        );
    }
}

ActivatePage.propTypes = {
    person: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    enrolledDevices: PropTypes.object.isRequired,
    isDeviceEnrolled: PropTypes.bool.isRequired,
    getEnrolledDevices: PropTypes.func.isRequired,
    getEnrolledDevicesPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getEnrolledDevicesError: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
    activationKey: PropTypes.object.isRequired,
    clearGetSecureActivationKeyState: PropTypes.func.isRequired,
    dismissGetSecureActivationKeyError: PropTypes.object.isRequired,
};

ActivatePage.defaultProps = {};

/* istanbul ignore next */
// eslint-disable-next-line
function mapStateToProps({ authentication, customer, secureId }) {
    return {
        person: authentication.person,
        customer: customer.customer,
        enrolledDevices: secureId.enrolledDevices,
        isDeviceEnrolled: secureId.isDeviceEnrolled,
        getEnrolledDevicesPending: secureId.getEnrolledDevicesPending,
        getEnrolledDevicesError: secureId.getEnrolledDevicesError,
        activationKey: secureId.activationKey,
    };
}

/* istanbul ignore next */
// eslint-disable-next-line
function mapDispatchToProps(dispatch) {
    return {
        getCustomer: customerId => dispatch(getCustomer(customerId)),
        getEnrolledDevices: customerId => dispatch(getEnrolledDevices(customerId)),
        clearGetSecureActivationKeyState: () => dispatch(clearGetSecureActivationKeyState()),
        dismissGetSecureActivationKeyError: () => dispatch(dismissGetSecureActivationKeyError()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivatePage);
