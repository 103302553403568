// TODO: this should not be calculated here,
//   it should be a key in the loyaltiesAllowed section of the BE response

/**
 * Get a
 * @param loyaltyId {string} Mcard code for the loyalty program (card)
 * @returns {string} E.g "porsche" or "euronics"
 */
export const getLoyaltyProgramKey = loyaltyId => {
    // no switch cases for these (no bonus page or faq)
    //   bcf - Barncancerfonden
    //   ssrs - Sjöräddningssällskapet
    //   rb - Rädda Barnen
    //   chalmers
    //   mq - MarQet

    switch (loyaltyId) {
        case 'mcard728':
        case 'mcard729':
        case 'mcard929':
            return 'porsche';

        case 'mcard740':
        case 'mcard741':
        case 'mcard742':
        case 'mcard930':
            return 'probike';

        case 'mcard025':
        case 'mcard031':
        case 'mcard792':
        case 'mcard793':
        case 'mcard935':
            return 'euronics';

        case 'mcard730':
        case 'mcard731':
        case 'mcard732':
            return 'aik';

        case 'mcard726':
        case 'mcard727':
            return 'ifk-gbg';

        case 'mcard743':
        case 'mcard744':
        case 'mcard763':
        case 'mcard931':
        case 'mcard932':
            return 'mb';

        case 'mcard770':
        case 'mcard771':
        case 'mcard772':
            return 'first';

        default:
            return 'ecster';
    }
};

/**
 * Get a text string with valid time for bonus points based on loyalty program
 * @param loyaltyId {string} Mcard code for the loyalty program (card)
 * @returns {string} E.g "24 månader", "12 månader"
 */
export const getLoyaltyProgramPointValidity = loyaltyId => {
    // no switch cases for these (no bonus page or faq)
    //   bcf - Barncancerfonden
    //   ssrs - Sjöräddningssällskapet
    //   rb - Rädda Barnen
    //   chalmers
    //   mq - MarQet

    switch (loyaltyId) {
        // Euronics
        case 'mcard025':
        case 'mcard031':
        case 'mcard792':
        case 'mcard793':
        case 'mcard935':
            return '12 månader';

        default:
            return '24 månader';
    }
};
