import React, { ReactNode, useState } from 'react';
// import { Tooltip } from '../Tooltip';
import { copyIcon } from '@ecster/icons/H15/green';
import { IconButton } from '@ecster/components';

import './SimpleCopyToClipboardButton.scss';

const { navigator: nav } = window;

const isIOS = !!/ipad|iphone|ipod/i.test(nav.userAgent);
const isIE11 = !!nav.userAgent.match(/trident\/7/i);

const supportsClipboardAPI = () => !!nav.clipboard;
const supportsInputFieldCopy = () => !isIOS && !isIE11; // ios flickers onscreen keyboard, IE11 copies value but crashes
const noSupport = () => !supportsClipboardAPI() && !supportsInputFieldCopy();

// copy with native API in newer browsers
const copyWithClipboardAPI = (text: string) => {
    nav.clipboard.writeText(text);
};

// copy with a tmp input field, fallback for browsers or apps not supporting APIs
const copyWithInputField = (text: string) => {
    const { document: doc } = window;

    const input = doc.createElement('input');

    input.setAttribute('value', text ? text.trim() : '');
    input.style.cssText = 'position: absolute; width: 10px; top: -25px; left: -25px;';

    doc.body.appendChild(input);
    input.select();

    document.execCommand('copy');

    setTimeout(() => {
        document.body.removeChild(input);
    }, 20);
};

export const copyToClipboard = (text: string) => {
    if  (supportsClipboardAPI()) {
        copyWithClipboardAPI(text);
    } else if (supportsInputFieldCopy()) {
        copyWithInputField(text);
    }
};

interface SimpleCopyToClipboardButtonProps {
    /**
     * Unique ID for this particular tip
     */
    id?: string;

    /**
     * Optional child elements, place to the left of the clipboard icon
     */
    children?: ReactNode;

    /**
     * Value to be copied
     */
    value: string;

    /**
     * Tooltip text
     */
    tooltip: string;

    /**
     * Tooltip text on copy success
     */
    tooltipSuccess?: string;

    /**
     * Icon class for the copy icon or a jsx node (img tag)
     */
    icon?: string;

    /**
     * No icon. If true, only child elements are clickable
     */
    noIcon?: boolean;
}

/**
 * An IconButton that takes a value as property.
 * Value is copied to clipboard when button is clicked
 */
export const SimpleCopyToClipboardButton = ({
    children = null,
    id,
    noIcon = false,
    icon = '',
    value,
    tooltip,
    tooltipSuccess = '',
}: SimpleCopyToClipboardButtonProps) => {
    const [tooltipText, setTooltipText] = useState('');

    const onClick = () => {
        copyToClipboard(value);
        setTooltipText(tooltipSuccess);
        setTimeout(() => {
            setTooltipText('');
        }, 20000); // there's no 'mouse out' event in mobiles
        return false;
    };

    const onMouseOver = () => {
        if (tooltipText === '')
            // don't overwrite success tooltip
            setTooltipText(tooltip);
    };

    const onMouseOut = () => {
        setTooltipText('');
    };

    if (noSupport()) {
        return <>{children}</>;
    }

    return (
            <span className="copy-to-cb-btn" onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                {children}
                {!noIcon && (
                    <span className="ml-2x">
                        {!icon && <img src={copyIcon} alt="" />}
                        {typeof icon === 'string' && <IconButton onClick={() => {}} icon={icon} />}
                        {typeof icon !== 'string' && <>{icon}</>}
                    </span>
                )}
                {tooltipText !== '' && <div className="tooltip">{tooltipText}</div>}
            </span>
    );
};
