import React from 'react';
import { Panel, PanelContent, ButtonGroup, LinkButton } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import './RetryPanel.scss';

const RetryPanel = () => {
    const HOW_TO_ROUTE = '/secure-id/how-to';
    const VERIFY_ROUTE = '/secure-id/verify';

    return (
        <Panel className="retry-panel" stretchInMobile>
            <PanelContent centered>
                <h3>{i18n('secure-id.activation.retry-panel.heading')}</h3>
                <ButtonGroup>
                    <LinkButton transparent green round className="how-to-button" to={HOW_TO_ROUTE}>
                        {i18n('secure-id.activation.retry-panel.how-to')}
                    </LinkButton>
                    <LinkButton round className="verify-button" to={VERIFY_ROUTE}>
                        {i18n('secure-id.activation.retry-panel.retry')}
                    </LinkButton>
                </ButtonGroup>
            </PanelContent>
        </Panel>
    );
};

export default RetryPanel;
