import React, { Component, ComponentType, ReactNode } from 'react';
import { createHashHistory } from 'history';
import { warningIcon } from '@ecster/icons/H80/purple';
import UserMessagePanel from './UserMessagePanel';
import './GeneralErrorMessagePanel.scss';
import { TechInfoPanel } from './TechInfoPanel';

const ErrorMessage = ({
    icon,
    header,
    body,
    buttonText,
    isLink,
    onButtonClick,
    getAppInfo,
}: GeneralErrorMessagePanelProps) => {
    // Default event is history.back(-1)
    const history = createHashHistory();
    const handleButtonClick = buttonText && !onButtonClick ? history.goBack : onButtonClick;

    let appInfo = getAppInfo && getAppInfo();
    const bodyWithInfo = appInfo ? (
        <>
            {body}
            <TechInfoPanel appInfo={appInfo} />
        </>
    ) : (
        body
    );

    return (
        <div className="ec-general-error-message-panel">
            <UserMessagePanel
                header={header}
                body={bodyWithInfo}
                buttonText={buttonText}
                onButtonClick={handleButtonClick}
                isLink={isLink}
                icon={icon}
                iconLeft="icon-chevron-left"
            >
                {!icon && icon !== null && <img src={warningIcon} alt="" />}
            </UserMessagePanel>
        </div>
    );
};

const DisplayErrorMessage = (props: GeneralErrorMessagePanelProps) => {
    const { Wrapper, isLogOnly } = props;

    if (isLogOnly === true) {
        return null;
    }
    return Wrapper ? (
        <Wrapper>
            <ErrorMessage {...props} />
        </Wrapper>
    ) : (
        <ErrorMessage {...props} />
    );
};

interface GeneralErrorMessagePanelProps {
    error?: { action: string };
    onButtonClick?: Function;
    dismissError?: Function;
    header?: string;
    icon?: string;
    Wrapper?: ComponentType<{ children: ReactNode }>;
    isLink?: boolean;
    isFatal?: boolean;
    isLogOnly?: boolean;
    body?: ReactNode;
    buttonText?: string;
    getAppInfo?: Function;
}

class GeneralErrorMessagePanel extends Component<GeneralErrorMessagePanelProps> {
    historyListener = undefined;

    componentDidMount() {
        const history = createHashHistory();
        this.historyListener = history.listen(this.onUnload);
    }

    componentWillUnmount() {
        this.historyListener();
        this.onUnload();
    }

    onUnload = () => {
        const { dismissError } = this.props;

        // Remove the error from the persisted state
        if (typeof dismissError === 'function') {
            dismissError();
        }
    };

    render() {
        return <DisplayErrorMessage {...this.props} />;
    }
}

// @ts-expect-error class defaultProps
GeneralErrorMessagePanel.defaultProps = {
    error: undefined,
    icon: undefined,
    onButtonClick: undefined,
    dismissError: () => {},
    header: null,
    Wrapper: 'div',
    isLink: false,
    isFatal: false,
    isLogOnly: false,
};

export default GeneralErrorMessagePanel;
