// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { OverviewPage, HowToPage, DownloadAppPage, VerifyPhonePage, ActivatePage, NoSupportPage } from '.';

import { isFI, isSE } from '../../common/country';
import dayjs from 'dayjs';

const seRoutes = isSE() ? [] : [];

const fiRoutes =
    // remove all secure-ID routes after 1/5 2024
    isFI() && dayjs().isBefore('2024-05-01 00:00')
        ? [
              { path: 'overview', name: 'Overview', component: OverviewPage },
              { path: 'how-to', name: 'How to', component: HowToPage },
              { path: 'download', name: 'Download App', component: DownloadAppPage },
              { path: 'verify', name: 'Verify Phone Number', component: VerifyPhonePage },
              { path: 'activate', name: 'Activate Secure Id', component: ActivatePage },
              { path: 'no-support', name: 'No Support', component: NoSupportPage, isPublic: true },
          ]
        : [];

const routes = {
    path: 'secure-id',
    name: 'Secure ID',
    childRoutes: [...seRoutes, ...fiRoutes],
};
export default routes;
