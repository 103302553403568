// @ts-nocheck
import React, { ComponentType } from 'react';
import PropTypes from 'prop-types';

interface LegacyComponentProps {
    /**
     * The legacy component to render
     */
    component: ComponentType;

    /**
     * Properties to pass to the legacy component.
     *
     * Typically this is this.props in the parent component.
     */
    props: object;
}

class LegacyComponent extends React.Component<LegacyComponentProps> {
    componentDidMount() {
        const { component } = this.props;
        if (typeof component.componentDidMount === 'function') {
            component.componentDidMount();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { component } = this.props;
        if (typeof component.componentDidUpdate === 'function') {
            component.componentDidUpdate(prevProps, prevState, snapshot);
        }
    }

    componentWillUnmount() {
        const { component } = this.props;
        if (typeof component.componentWillUnmount === 'function') {
            component.componentWillUnmount();
        }
    }

    start = el => {
        const { component, props } = this.props;
        component.start(el, props);
    };

    render() {
        return <div className="ec-react-legacy-component" ref={this.start} />;
    }
}

LegacyComponent.propTypes = {
    /**
     * The legacy component to render
     */
    component: PropTypes.object.isRequired,

    /**
     * Properties to pass to the legacy component.
     *
     * Typically this is this.props in the parent component.
     */
    props: PropTypes.object,
};

LegacyComponent.defaultProps = {
    props: {},
};

export default LegacyComponent;
