import React from 'react';
import PropTypes from 'prop-types';
// import { Button } from '@ecster/components';
import { ButtonGroup, LinkButton } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import LoginDetails from './LoginDetails';

// const formName = 'aspnetForm';
// const formAction = 'login.aspx';
// const usernameField = 'ctl00$ContentPlaceHolder1$txtAccount';
// const passwordField = 'ctl00$ContentPlaceHolder1$txtPassword';

export const BonusuttagLoginForm = ({ username, password, baseUrl }) => {
    return (
        <>
            <LoginDetails username={username} password={password} />
            <ButtonGroup alignCenter>
                <LinkButton round iconRight="icon-external-link" outline href={baseUrl} target="_blank">
                    {i18n('account.bonus-overview-page.info-panel.bonus-uttag-link')}
                </LinkButton>
            </ButtonGroup>
        </>
    );
};

BonusuttagLoginForm.propTypes = {
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
};

// TODO: keep non working single sign on code for a while
//         <form name={formName} action={`${baseUrl}/${formAction}`} method="POST" target="_blank">
//             <input type="hidden" name={usernameField} value={username} />
//             <input type="hidden" name={passwordField} value={password} />
//             <Button
//                 type="submit"
//                 round
//                 outline
//                 id="login-bonusuttag-button"
//                 name="loginButton"
//                 iconRight="icon-external-link"
//             >
//                 {i18n('account.bonus-overview-page.info-panel.bonus-uttag-link')}
//             </Button>
//         </form>
