import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';

import { i18n } from '@ecster/i18n';
import { Button } from '@ecster/components';
import LicenseList from './LicenseList';

export const LicensePage = () => {
    // component state
    const [webData, setWebData] = useState(null);
    const topOfPageRef = useRef();

    /**
     * Load license files generated with 'npm run license' in ecster-login
     *
     * Data is a big object with module names as keys
     *   { "@namespace/module@1.2.3": { "licenses": ..., "licenseFile": ... }, "another@3.2.1": { ... }, ... }
     *
     * Create a new object with grouped modules
     *   { "@babel": [ {}, {}, {} ], "react": [ ... ]
     */
    const loadData = (file, setData) => {
        axios
            .get(file, { responseType: 'json' })
            .then(response => {
                const result = {};
                Object.keys(response.data).map(name => {
                    // Figure out a group name from module name
                    const group = name.startsWith('@')
                        ? name.replace(/\/.*/, '') // @prefix/name@version deps will always have a /
                        : name.replace(/[/\-_@].*/, ''); // no-prefix@version deps has -, _ or the version @
                    if (!result[group]) result[group] = [];
                    const module = { name, ...response.data[name] };
                    result[group].push(module);
                    return null;
                });
                setData(result);
            })
            .catch(() => {
                setData({ content: 'Oops.. license info could not be loaded' });
            });
    };

    useEffect(() => {
        loadData('license/web/licenses-processed.json', setWebData);
    }, []);

    const scrollToTop = () => {
        topOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="about-license-page">
            <div ref={topOfPageRef} className="content">
                <p>
                    {i18n('about.license-list.links.help-page', { danger: true })} |{' '}
                    {i18n('about.license-list.links.login-page', { danger: true })}
                </p>
                <h1>{i18n('about.license-list.header')}</h1>
                <p>{i18n('about.license-list.info')}</p>

                <LicenseList groups={webData} />

                <Button className="scroll-to-top-button" stretch green icon="icon-chevron-up" onClick={scrollToTop}>
                    {' '}
                </Button>
            </div>
        </div>
    );
};

LicensePage.propTypes = {};

LicensePage.defaultProps = {};

export default LicensePage;
