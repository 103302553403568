import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { i18n } from '@ecster/i18n';
import { reduxActionFailed, reduxActionSucceeded } from '@ecster/util';

import {
    Button,
    ButtonGroup,
    DatePicker,
    Form,
    Input,
    Message,
    Option,
    Panel,
    PanelContent,
    ResponsivePanel,
    Select,
    Tooltip,
} from '@ecster/components';
import { handWithMoneyIcon } from '@ecster/icons/H80/green';
import { Redirect, withRouter } from 'react-router-dom';
import {
    clearGetRepaymentInquiryState,
    createRepaymentInquiry,
    dismissCreateRepaymentInquiryError,
    getRepaymentInquiry,
} from './redux/actions';

import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import { NO_ID } from './constants';
import { RepaymentInfoPanel } from './RepaymentInfoPanel';

export class ContractRepaymentPage extends React.Component {
    static propTypes = {
        // router: PropTypes.shape({
        //     params: PropTypes.shape({
        //         contractNumber: PropTypes.string.isRequired,
        //         newRequest: PropTypes.string,
        //         id: PropTypes.string.isRequired,
        //     }).isRequired,
        // }).isRequired,
        contractNumber: PropTypes.string.isRequired,
        newRequest: PropTypes.string,
        id: PropTypes.string.isRequired,

        customerId: PropTypes.number.isRequired,
        // contract: PropTypes.object.isRequired,
        currentRepaymentTerms: PropTypes.object,

        createRepaymentInquiry: PropTypes.func.isRequired,
        getRepaymentInquiry: PropTypes.func.isRequired,
        clearGetRepaymentInquiryState: PropTypes.func.isRequired,
        getRepaymentInquiryError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

        createRepaymentInquiryPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
        createRepaymentInquiryError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
    };

    static defaultProps = {
        currentRepaymentTerms: null,
        getRepaymentInquiryError: null,
        createRepaymentInquiryError: null,
        newRequest: false,
    };

    formRef = React.createRef();

    dateRef = React.createRef();

    selectedReasonRef = React.createRef();

    infoTextRef = React.createRef();

    minDate = dayjs().add(1, 'days');

    maxDate = dayjs().add(14, 'days');

    state = {
        selectedDate: '',
        selectedReason: '',
        infoText: '',
        unexpectedError: false,
    };

    componentDidMount() {
        const {
            getRepaymentInquiry,
            customerId,
            contractNumber,
            newRequest,
            // router: {
            //     params: { contractNumber, newRequest },
            // },
        } = this.props;
        if (!newRequest) {
            // is there an existing repayment inquiry?
            getRepaymentInquiry(customerId, contractNumber);
        }
    }

    componentDidUpdate(prevProps) {
        const {
            contractNumber,
            id,
            // router: {
            //     params: { contractNumber, id },
            // },
        } = this.props;
        const url = `#/contract/${contractNumber}/${id}/repayment-terms`;

        if (reduxActionSucceeded('createRepaymentInquiry', prevProps, this.props)) {
            window.location.href = url;
        }

        if (reduxActionFailed('createRepaymentInquiry', prevProps, this.props)) {
            this.setState({ unexpectedError: true }); // eslint-disable-line react/no-did-update-set-state
        }
    }

    componentWillUnmount() {
        const { clearGetRepaymentInquiryState } = this.props;
        clearGetRepaymentInquiryState();
    }

    onDateChanged = ({ target }) => {
        this.setState({ selectedDate: target.value });
    };

    onReasonChanged = ({ target }) => {
        this.setState({ selectedReason: target.value });
    };

    oninfoTextChanged = ({ target }) => {
        this.setState({ infoText: target.value });
    };

    onClick = () => {
        if (this.formRef.current.validate()) {
            const {
                createRepaymentInquiry,
                customerId,
                contractNumber,
                id,
                // router: {
                //     params: { contractNumber, id },
                // },
            } = this.props;
            const { selectedDate, selectedReason, infoText } = this.state;

            const data = {
                date: dayjs(`${selectedDate}T12:00:00.000Z`), // set 12:00 to avoid timezone glitch in NETX/ECR
                amlSourceOfMoney: selectedReason,
                id,
                infoText: selectedReason === 'OTHER' ? infoText : undefined,
            };
            createRepaymentInquiry(customerId, contractNumber, data);
        }
    };

    validateSelectedDate = value => {
        if (!value) return false;

        const theDate = dayjs(value).startOf('day');
        const maxDatePlus1 = this.maxDate.add(1, 'days').startOf('day');
        const today = dayjs().endOf('day');

        // note: must validate, any date can be selected in mobile
        return theDate.isAfter(today) && theDate.isBefore(maxDatePlus1);
    };

    render() {
        const { selectedDate, selectedReason, infoText, unexpectedError } = this.state;
        const {
            currentRepaymentTerms,
            createRepaymentInquiryPending,
            contractNumber,
            id,
            newRequest,
            // router: {
            //     params: { contractNumber, id, newRequest },
            // },
        } = this.props;
        const contractRepaymentTermsUrl = `/contract/${contractNumber}/${id}/repayment-terms`;

        const termsDate =
            currentRepaymentTerms && currentRepaymentTerms.date
                ? new Date(currentRepaymentTerms.date.substring(0, 10)).getTime()
                : 0;
        const currentDate = new Date(dayjs().format('YYYY-MM-DD')).getTime();

        const validateRefs =
            selectedReason === 'OTHER'
                ? [this.dateRef, this.selectedReasonRef, this.infoTextRef]
                : [this.dateRef, this.selectedReasonRef];

        if (currentRepaymentTerms && currentDate <= termsDate && !newRequest) {
            return <Redirect to={contractRepaymentTermsUrl} />;
        }

        return (
            <AuthenticatedSubPageTemplate
                className="contract-repayment-page"
                header={i18n('contract.repayment.top-header')}
            >
                <div className="contract-contract-repayment-page">
                    {id === NO_ID ? (
                        <RepaymentInfoPanel id="repayment-info-panel" />
                    ) : (
                        <>
                            {unexpectedError && (
                                <Message
                                    className="mb-4x"
                                    purple
                                    header={i18n('contract.repayment.errors.apply-for-terms.header')}
                                    message={i18n('contract.repayment.errors.apply-for-terms.message')}
                                />
                            )}
                            <Panel stretchInMobile>
                                <Form ref={this.formRef} validateRefs={validateRefs}>
                                    <PanelContent>
                                        <div className="header-term">
                                            <img src={handWithMoneyIcon} alt="" />
                                            <h3 className="pt-2x"> {i18n('contract.repayment.header')}</h3>
                                            <p className="pb-4x"> {i18n('contract.repayment.text')}</p>
                                        </div>
                                        <ResponsivePanel className="mb-5x">
                                            <div>
                                                <p>{i18n('contract.repayment.left-panel-question')}</p>
                                                <DatePicker
                                                    id="crp-datepicker"
                                                    showIndicator
                                                    placeholder="Datum"
                                                    config={{
                                                        disableWeekends: true,
                                                        minDate: this.minDate.toDate(),
                                                        maxDate: this.maxDate.toDate(),
                                                    }}
                                                    value={selectedDate}
                                                    ref={this.dateRef}
                                                    required
                                                    validationMessage={i18n(
                                                        'contract.repayment.validation-message-date'
                                                    )}
                                                    onChange={this.onDateChanged}
                                                    autocomplete="off"
                                                    validator={this.validateSelectedDate}
                                                />
                                            </div>
                                            <div>
                                                <p>
                                                    {i18n('contract.repayment.right-panel-question')}

                                                    <Tooltip
                                                        width="320px"
                                                        id="info-tooltip"
                                                        position={['right center']}
                                                        theme="light"
                                                        trigger={<i className="icon-info e-green" />}
                                                    >
                                                        <p>{i18n('contract.repayment.info-text')}</p>
                                                    </Tooltip>
                                                </p>

                                                <Select
                                                    id="crp-select"
                                                    value={selectedReason}
                                                    ref={this.selectedReasonRef}
                                                    required
                                                    validationMessage={i18n(
                                                        'contract.repayment.validation-message-selected-reason'
                                                    )}
                                                    onChange={this.onReasonChanged}
                                                    defaultOption={i18n('contract.repayment.default-option')}
                                                >
                                                    <Option
                                                        value="SELL_TRADE_OBJECT"
                                                        label={i18n('contract.repayment.option-1')}
                                                    />
                                                    <Option
                                                        value="LOAN_OTHER_COMPANY"
                                                        label={i18n('contract.repayment.option-2')}
                                                    />
                                                    <Option
                                                        value="OWN_EQUITY_INHERITANCE"
                                                        label={i18n('contract.repayment.option-3')}
                                                    />
                                                    <Option
                                                        value="DIVIDEND_BONUS_SHARES"
                                                        label={i18n('contract.repayment.option-4')}
                                                    />
                                                    <Option value="OTHER" label={i18n('contract.repayment.option-5')} />
                                                </Select>
                                                {selectedReason === 'OTHER' && (
                                                    <Input
                                                        value={infoText}
                                                        ref={this.infoTextRef}
                                                        required
                                                        validationMessage={i18n(
                                                            'contract.repayment.validation-message-reason-description'
                                                        )}
                                                        className="pt-2x"
                                                        placeholder={i18n('contract.repayment.placeholder-input')}
                                                        onChange={this.oninfoTextChanged}
                                                    >
                                                        {' '}
                                                    </Input>
                                                )}
                                            </div>
                                        </ResponsivePanel>
                                        <ButtonGroup alignCenter>
                                            <Button
                                                round
                                                onClick={this.onClick}
                                                ignoreClick={createRepaymentInquiryPending}
                                                spinnerRight={createRepaymentInquiryPending}
                                            >
                                                {i18n('contract.repayment.button-text')}
                                            </Button>
                                        </ButtonGroup>
                                    </PanelContent>
                                </Form>
                            </Panel>
                        </>
                    )}
                </div>
            </AuthenticatedSubPageTemplate>
        );
    }
}

function mapStateToProps({ contract, authentication }, { match }) {
    const { contractNumber, id, newRequest } = match.params;
    return {
        // Varför stod dom här mappningarna här???
        // router: PropTypes.shape({
        //     params: PropTypes.shape({
        //         contractNumber: PropTypes.string.isRequired,
        //         id: PropTypes.string.isRequired,
        //         newRequest: PropTypes.string.isRequired,
        //     }).isRequired,
        // }).isRequired,
        // contractNumber: PropTypes.string.isRequired,
        // id: PropTypes.string.isRequired,
        // newRequest: PropTypes.string.isRequired,
        contractNumber,
        id,
        newRequest,
        customerId: authentication.person.id,
        contract: contract.contract,
        currentRepaymentTerms: contract.currentRepaymentTerms,

        createRepaymentInquiryPending: contract.createRepaymentInquiryPending,
        createRepaymentInquiryError: contract.createRepaymentInquiryError,

        getRepaymentInquiryPending: contract.getRepaymentInquiryPending,
        getRepaymentInquiryError: contract.getRepaymentInquiryError,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createRepaymentInquiry: (customerId, contractNumber, data) =>
            dispatch(createRepaymentInquiry(customerId, contractNumber, data)),
        getRepaymentInquiry: (customerId, contractNumber) => dispatch(getRepaymentInquiry(customerId, contractNumber)),
        dismissCreateRepaymentInquiryError: () => dispatch(dismissCreateRepaymentInquiryError()),
        clearGetRepaymentInquiryState: () => dispatch(clearGetRepaymentInquiryState()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContractRepaymentPage));
