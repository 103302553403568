import React from 'react';
import PropTypes from 'prop-types';

import MaterialSlider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';

import './Slider.scss';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

interface SliderProps {
    /**
     * Slider min value
     */
    min: number;

    /**
     * Slider max value
     */
    max: number;

    /**
     * Slider value
     */
    value: number;

    /**
     * Function called when slider value changes
     */
    onChange: Function;

    /**
     * Function called *after* slider value has changed
     */
    onAfterChange: Function;

    /**
     * Steps when sliding
     */
    step?: number;

    /**
     * Default value if no value is set
     */
    defaultValue?: number;
}

const Slider = ({ onChange, onAfterChange, ...rest }: SliderProps) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <MaterialSlider
                {...rest}
                className="ec-slider"
                onChangeCommitted={(e, value) => onAfterChange(value)}
                onChange={(e, value) => onChange(value)}
            />
        </div>
    );
};

Slider.propTypes = {
    /**
     * Slider min value
     */
    min: PropTypes.number.isRequired,

    /**
     * Slider max value
     */
    max: PropTypes.number.isRequired,

    /**
     * Function called when slider value changes
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Steps when sliding
     */
    step: PropTypes.number,

    /**
     * Default value if no value is set
     */
    defaultValue: PropTypes.number,

    /**
     * Slider value
     */
    value: PropTypes.number,

    /**
     * Function called *after* slider value has changed
     */
    onAfterChange: PropTypes.func,
};

Slider.defaultProps = {
    step: 1,
    defaultValue: 0,
    value: 0,
    onAfterChange: () => {},
};

export default Slider;
