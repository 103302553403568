import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Ecster imports
import { i18n } from '@ecster/i18n';
import { GeneralErrorMessagePanel, Spinner } from '@ecster/components';
import { reduxActionSucceeded, reduxActionFailed } from '@ecster/util';
import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import { getCustomer } from '../customer/redux/actions';
import history from '../../common/history';
import VerifyPhonePanel from './components/VerifyPhonePanel';
import { getDebugInfo } from '../../common/util/getDebugInfo';

const Wrapper = ({ children }) => (
    <AuthenticatedSubPageTemplate header={i18n('secure-id.download.page-header')}>
        {children}
    </AuthenticatedSubPageTemplate>
);

Wrapper.propTypes = {
    children: PropTypes.array.isRequired,
};

const OVERVIEW_ROUTE = '/secure-id/overview';

export class VerifyPhonePage extends React.Component {
    state = {
        getCustomerDone: false,
        hasError: false,
    };

    componentDidMount() {
        const {
            person: { id },
            getCustomer,
            enrolledDevices,
        } = this.props;

        if (!enrolledDevices) {
            history.push(OVERVIEW_ROUTE);
        }

        if (id) {
            getCustomer(id);
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps) {
        if (reduxActionSucceeded('getCustomer', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ getCustomerDone: true });
        }
        if (reduxActionFailed('getCustomer', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ hasError: true });
        }
    }

    render() {
        const {
            person: { id },
            customer: {
                contactInformation: { phoneNumber },
            },
            getCustomerError,
            getCustomerPending,
        } = this.props;

        const { getCustomerDone, hasError } = this.state;

        if (hasError) {
            return (
                <Wrapper>
                    <GeneralErrorMessagePanel
                        header={i18n('general.error.general.header')}
                        body={i18n('general.error.general.body')}
                        error={getCustomerError}
                        isFatal
                        getAppInfo={getDebugInfo}
                    />
                </Wrapper>
            );
        }

        return (
            <Wrapper>
                <div className="sca-verify-phone-page">
                    {getCustomerPending && <Spinner id="sca-verify-phone-page-spinner" isCenterPage isVisible />}
                    {getCustomerDone && <VerifyPhonePanel customerId={id} phoneNumber={phoneNumber} />}
                </div>
            </Wrapper>
        );
    }
}

VerifyPhonePage.propTypes = {
    person: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    getCustomer: PropTypes.func.isRequired,
    getCustomerPending: PropTypes.bool.isRequired,
    getCustomerError: PropTypes.object.isRequired,
    enrolledDevices: PropTypes.object.isRequired,
};

VerifyPhonePage.defaultProps = {};

/* istanbul ignore next */
// eslint-disable-next-line
function mapStateToProps({ authentication, customer, secureId }) {
    return {
        person: authentication.person,
        customer: customer.customer,
        getCustomerPending: customer.getCustomerPending,
        getCustomerError: customer.getCustomerError,
        enrolledDevices: secureId.enrolledDevices,
    };
}

/* istanbul ignore next */
// eslint-disable-next-line
function mapDispatchToProps(dispatch) {
    return {
        getCustomer: customerId => dispatch(getCustomer(customerId, true)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhonePage);
