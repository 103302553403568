import { i18n } from '@ecster/i18n';
import React from 'react';
import PropTypes from 'prop-types';
import { Link, Panel, PanelContent, detectDevice } from '@ecster/components';
import { appStoreIcon } from '@ecster/icons/ios';
import { googlePlayIcon } from '@ecster/icons/android';
import './NoSupportPanel.scss';

const NoSupportPanel = ({ forceShow, iosUrl, androidUrl, header, content }) => {
    const { isIOS, isAndroid } = detectDevice();

    const noSupport = !isIOS && !isAndroid;

    return (
        // Needs to return something if the condition is false
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {(noSupport || forceShow) && (
                <Panel className="no-support-panel" stretchInMobile>
                    <PanelContent centered>
                        <h3>{header}</h3>
                        <p>{content}</p>
                        <div className="icon-wrapper">
                            <Link
                                noUnderlineOnFocus
                                href={iosUrl}
                                className="store-icon-link"
                                ariaLabel={i18n('secure-id.common.app-store')}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={appStoreIcon} className="appstore-icon" alt="Appstore" />
                            </Link>
                            <Link
                                noUnderlineOnFocus
                                href={androidUrl}
                                className="store-icon-link"
                                ariaLabel={i18n('secure-id.common.google-play')}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={googlePlayIcon} className="googleplay-icon" alt="Google play" />
                            </Link>
                        </div>
                    </PanelContent>
                </Panel>
            )}
        </>
    );
};

NoSupportPanel.propTypes = {
    forceShow: PropTypes.bool,
    iosUrl: PropTypes.string.isRequired,
    androidUrl: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

NoSupportPanel.defaultProps = {
    forceShow: false,
};

export default NoSupportPanel;
