/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { connect } from 'react-redux';
import { SelectBackend } from '../common';
import { getCanaryValue, setCanaryValue } from '../../common/canaryValues';
import { setReviewMode } from '../common/redux/actions';

const { EcsterConfig: conf } = window;

class LoginHelpFooter extends React.Component {
    state = {
        count: 0,
        initialBaseUrl: window.sessionStorage.getItem('initialBaseUrl'), // set in index.js
        canaryValue: getCanaryValue(),
    };

    cvRef = React.createRef();

    onChangeCanaryValue = () => {
        const canaryValue = this.cvRef.current.value;
        console.log('canaryValue = ', canaryValue);
        setCanaryValue(canaryValue);
        this.setState({ canaryValue });
    };

    render() {
        const { canaryValue } = this.state;
        return (
            <>
                <h2>{i18n('authentication.help-page.license.header')}</h2>
                <p>{i18n('authentication.help-page.license.info', { danger: true })}</p>

                {conf && (
                    <p className="version-info">
                        version: {conf.version} {conf.build ? ` / ${conf.build}` : ''}
                    </p>
                )}

                {conf.env !== 'prod' && (
                    <>
                        <SelectBackend />
                        {window.device && (
                            <>
                                <p>Cordova device info (test only)</p>
                                <code>
                                    <pre>{JSON.stringify(window.device, undefined, 4)}</pre>
                                </code>
                                <p>EcsterConfig (test only)</p>
                                <code>
                                    <pre>{JSON.stringify(conf, undefined, 4)}</pre>
                                </code>
                            </>
                        )}
                        <label style={{ color: '#fff' }} htmlFor="canary-value">
                            Canary value
                        </label>
                        <div className="flex flex-row mt-1x">
                            <input
                                ref={this.cvRef}
                                id="canary-value"
                                onChange={({ target }) => this.setState({ canaryValue: target.value })}
                                value={canaryValue}
                                style={{ padding: '8px' }}
                            />
                            <Button red onClick={this.onChangeCanaryValue} outline round small>
                                Update
                            </Button>
                        </div>
                    </>
                )}
            </>
        );
    }
}

LoginHelpFooter.propTypes = {
    setReviewMode: PropTypes.func.isRequired,
    isReviewMode: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ common }) => ({
    isReviewMode: common.isReviewMode,
});

function mapDispatchToProps(dispatch) {
    return {
        setReviewMode: isReviewMode => dispatch(setReviewMode(isReviewMode)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginHelpFooter);
