import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './Badge.scss';

interface BadgeProps {
    /** The badge text */
    children: ReactNode;

    /** font icon class */
    iconLeft?: string;

    /** font icon class */
    iconRight?: string;

    /**
     * Action indicates that an action needs to be taken.
     *
     * true => color purple
     *
     * false => other color props
     */
    action?: boolean;

    /**
     * Override default behavior with light background and darker text in the same color
     *
     * I.e. use darker colors with white text
     */
    dark?: boolean;

    /**
     * Less side padding and bold text, suitable for counter badges
     */
    counter?: boolean;

    /**
     * Circular appearance. One character content only!
     */
    circular?: boolean;

    // colors
    green?: boolean;
    payGreen?: boolean;
    blue?: boolean;
    purple?: boolean;
    yellow?: boolean;
    red?: boolean;
    beige?: boolean;
    gray?: boolean;
}

const Badge = ({
    children,
    action,
    iconLeft,
    iconRight,
    dark,
    counter,
    circular,
    green,
    payGreen,
    blue,
    purple,
    yellow,
    red,
    beige,
    gray,
}: BadgeProps) => {
    const classes = classNames({
        'ec-badge': true,
        'ec-dark': dark || counter,
        'ec-counter': counter,
        'ec-circular': circular,
        'ec-green': green && !payGreen && !blue && !purple && !yellow && !red && !beige && !gray,
        'ec-pay-green': payGreen,
        'ec-blue': blue,
        'ec-purple': purple || action,
        'ec-yellow': yellow,
        'ec-red': red,
        'ec-beige': beige,
        'ec-gray': gray,
    });

    const iconLeftClasses = classNames({
        icon: true,
        'icon-left': true,
        [iconLeft]: iconLeft,
    });

    const iconRightClasses = classNames({
        icon: true,
        'icon-right': true,
        [iconRight]: iconRight,
    });

    return (
        <span className={classes}>
            {iconLeft && <i className={iconLeftClasses} />}
            {children}
            {iconRight && <i className={iconRightClasses} />}
        </span>
    );
};

Badge.defaultProps = {
    iconLeft: null,
    iconRight: null,
    action: false,
    dark: false,
    counter: false,
    circular: false,
    // colors
    green: true,
    payGreen: false,
    blue: false,
    purple: false,
    yellow: false,
    red: false,
    beige: false,
    gray: false,
};

export default Badge;
