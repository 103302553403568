import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const GatewayContext = createContext(null);

export const GatewayContextProvider = ({ children, value }) => {
    return <GatewayContext.Provider value={{ value }}>{children}</GatewayContext.Provider>;
};

GatewayContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.shape({
        isNewGui: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
    }).isRequired,
};

export const useGatewayContext = () => {
    const context = useContext(GatewayContext);
    if (!context) {
        throw new Error('useGatewayContext must be used within a GatewayContextProvider');
    }
    return context;
};