import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Ecster imports
import { i18n } from '@ecster/i18n';
import { Message, Spinner } from '@ecster/components';
import { reduxActionFailed, reduxActionSucceeded } from '@ecster/util';
import { getEnrolledDevices } from './redux/actions';
import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import Header from './components/Header';
import ActivationPanel from './components/ActivationPanel';
import ActivateNewDevicePanel from './components/ActivateNewDevicePanel';
import RemoveSecureIdPanel from './components/RemoveSecureIdPanel';

const Wrapper = ({ children }) => (
    <AuthenticatedSubPageTemplate header={i18n('secure-id.overview.page-header')}>
        {children}
    </AuthenticatedSubPageTemplate>
);

Wrapper.propTypes = {
    children: PropTypes.array.isRequired,
};

export class OverviewPage extends React.Component {
    state = {
        hasError: false,
        getEnrolledDevicesDone: false,
    };

    componentDidMount() {
        const {
            person: { id },
            getEnrolledDevices,
        } = this.props;

        if (id) {
            getEnrolledDevices(id);
        }
    }

    componentDidUpdate(prevProps) {
        if (reduxActionSucceeded('getEnrolledDevices', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ hasError: false, getEnrolledDevicesDone: true });
        }
        if (reduxActionFailed('getEnrolledDevices', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ hasError: true, getEnrolledDevicesDone: true });
        }
    }

    render() {
        const { isDeviceEnrolled } = this.props;
        const { getEnrolledDevicesDone, hasError } = this.state;

        if (!getEnrolledDevicesDone) {
            return (
                <Wrapper>
                    <Spinner id="sca-overview-page-spinner" isCenterPage isVisible />
                </Wrapper>
            );
        }

        return (
            <Wrapper>
                <div className="sca-overview-page">
                    {hasError && (
                        <Message
                            exclamation
                            purple
                            header={i18n('secure-id.overview.error.header')}
                            message={i18n('secure-id.overview.error.message')}
                        />
                    )}

                    <Header isDeviceEnrolled={isDeviceEnrolled} hasError={hasError} />

                    {isDeviceEnrolled && !hasError && (
                        <>
                            <ActivateNewDevicePanel />
                            <RemoveSecureIdPanel />
                        </>
                    )}

                    {!isDeviceEnrolled && <ActivationPanel hasError={hasError} />}
                </div>
            </Wrapper>
        );
    }
}

OverviewPage.propTypes = {
    person: PropTypes.object.isRequired,
    getEnrolledDevices: PropTypes.func.isRequired,
    isDeviceEnrolled: PropTypes.bool.isRequired,
    getEnrolledDevicesError: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
    getEnrolledDevicesPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
};

/* istanbul ignore next */
// eslint-disable-next-line
function mapStateToProps({ authentication, secureId }) {
    return {
        person: authentication.person,
        getEnrolledDevicesPending: secureId.getEnrolledDevicesPending,
        getEnrolledDevicesError: secureId.getEnrolledDevicesError,
        isDeviceEnrolled: secureId.isDeviceEnrolled,
    };
}

/* istanbul ignore next */
// eslint-disable-next-line
function mapDispatchToProps(dispatch) {
    return {
        getEnrolledDevices: customerId => dispatch(getEnrolledDevices(customerId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
