import React from 'react';
import PropTypes from 'prop-types';
import { detectDevice, LabelValue } from '@ecster/components';

export default class ColumnSummaryPanel extends React.Component {
    static propTypes = {
        // labels
        label1: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        label2: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        label3: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

        // values
        value1: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        value2: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        value3: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    };

    static defaultProps = {
        value3: null,
        label3: null,
    };

    state = {};

    render() {
        const { label1, label2, label3, value1, value2, value3 } = this.props;
        const { isMobile } = detectDevice();

        return (
            <div className="common-column-summary-panel ec-panel">
                <article key={1} className="first">
                    <LabelValue label={label1} value={value1} green stacked={!isMobile} />
                </article>
                <div className="separator"> </div>
                <article key={2}>
                    <LabelValue label={label2} value={value2} green stacked={!isMobile} />
                </article>
                {(value3 || label3) && (
                    <>
                        <div className="separator"> </div>
                        <article key={3} className="last">
                            <LabelValue label={label3} value={value3} green stacked={!isMobile} />
                        </article>
                    </>
                )}
            </div>
        );
    }
}
