import { post } from '@ecster/net/v2'; // or post, put, del
import {
    SECURE_ID_GET_SECURE_ACTIVATION_KEY_BEGIN,
    SECURE_ID_GET_SECURE_ACTIVATION_KEY_SUCCESS,
    SECURE_ID_GET_SECURE_ACTIVATION_KEY_FAILURE,
    SECURE_ID_GET_SECURE_ACTIVATION_KEY_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';

import { GET_SECURE_ACTIVATION_KEY_URL } from './urls';

const CLEAR_GET_SECURE_ACTIVATION_KEY_STATE = 'CLEAR_GET_SECURE_ACTIVATION_KEY_STATE';

export const getSecureActivationKey = (customerId, data) => async dispatch => {
    dispatch({
        type: SECURE_ID_GET_SECURE_ACTIVATION_KEY_BEGIN,
    });

    try {
        const res = await post(GET_SECURE_ACTIVATION_KEY_URL(customerId), data);

        dispatch({
            type: SECURE_ID_GET_SECURE_ACTIVATION_KEY_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getSecureActivationKey');
        dispatch({
            type: SECURE_ID_GET_SECURE_ACTIVATION_KEY_FAILURE,
            data: {
                error: {
                    message: err,
                    action: SECURE_ID_GET_SECURE_ACTIVATION_KEY_FAILURE,
                },
            },
        });
    }
};

export const dismissGetSecureActivationKeyError = () => ({ type: SECURE_ID_GET_SECURE_ACTIVATION_KEY_DISMISS_ERROR });

export const clearGetSecureActivationKeyState = () => ({ type: CLEAR_GET_SECURE_ACTIVATION_KEY_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case SECURE_ID_GET_SECURE_ACTIVATION_KEY_BEGIN:
            return {
                ...state,
                getSecureActivationKeyPending: true,
                getSecureActivationKeyError: null,
            };

        case SECURE_ID_GET_SECURE_ACTIVATION_KEY_SUCCESS:
            return {
                ...state,
                activationKey: action.data,
                getSecureActivationKeyPending: false,
                getSecureActivationKeyError: null,
            };

        case CLEAR_GET_SECURE_ACTIVATION_KEY_STATE:
            return {
                ...state,
                activationKey: null,
            };

        case SECURE_ID_GET_SECURE_ACTIVATION_KEY_FAILURE:
            return {
                ...state,
                getSecureActivationKeyPending: false,
                getSecureActivationKeyError: action.data.error,
            };

        case SECURE_ID_GET_SECURE_ACTIVATION_KEY_DISMISS_ERROR:
            return {
                ...state,
                getSecureActivationKeyError: null,
            };

        default:
            return state;
    }
}
