import React from 'react';
import PropTypes from 'prop-types';
import { LabelValue, Panel, PanelContent, ResponsivePanel } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { capitalize } from '../../../common/util/capitalize';
import { formatNumber, formatDate, formatDateMonth } from '../../../common/util/format';
import { isNumber } from '../../../common/util/is-number';

// get points value from rest response
const getPoints = (prop, loyaltyDetails) => loyaltyDetails && loyaltyDetails[prop] && loyaltyDetails[prop].points;

export const BonusDetailsHeader = ({ loyaltyDetails }) => {
    const balanceMonth = formatDateMonth(loyaltyDetails.currentPointBalanceDate);
    const expireMonth = loyaltyDetails.pointsExpire && formatDateMonth(loyaltyDetails.pointsExpire.from);

    const pointsEarned = getPoints('pointsEarnedInPeriod', loyaltyDetails);
    const pointsUsed = getPoints('pointsUsedInPeriod', loyaltyDetails);
    const pointsExpire = getPoints('pointsExpire', loyaltyDetails);

    return (
        <Panel greenBG sideMarginsInMobile>
            <PanelContent>
                <h2 className="h3 centered e-green140">
                    {i18n('account.bonus-overview-page.header-panel.header', {
                        month: capitalize(balanceMonth),
                    })}
                </h2>
                <ResponsivePanel verticalLines alignItems="center">
                    <div className="centered e-green140">
                        <p className="large-text">
                            {i18n('account.bonus-overview-page.header-panel.points', {
                                points: formatNumber(loyaltyDetails.currentPointBalance),
                            })}
                        </p>
                        <p className="small-text">
                            {i18n('account.bonus-overview-page.header-panel.labels.balance', {
                                date: formatDate(loyaltyDetails.currentPointBalanceDate),
                            })}
                        </p>
                    </div>
                    <div className="e-green140">
                        {isNumber(pointsEarned) && (
                            <LabelValue
                                label={i18n('account.bonus-overview-page.header-panel.labels.new-points')}
                                value={formatNumber(pointsEarned)}
                            />
                        )}
                        {isNumber(pointsUsed) && (
                            <LabelValue
                                label={i18n('account.bonus-overview-page.header-panel.labels.used-points')}
                                value={formatNumber(pointsUsed)}
                            />
                        )}
                        {isNumber(pointsExpire) && (
                            <LabelValue
                                label={i18n('account.bonus-overview-page.header-panel.labels.expiring-points', {
                                    month: expireMonth,
                                })}
                                value={formatNumber(pointsExpire)}
                            />
                        )}
                    </div>
                </ResponsivePanel>
            </PanelContent>
        </Panel>
    );
};

BonusDetailsHeader.propTypes = {
    loyaltyDetails: PropTypes.object.isRequired,
};

BonusDetailsHeader.defaultProps = {};
