import { ExpandableContainer } from '../Panel';
import { CopyToClipboardButton } from '../Clickable';
import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import './TechInfoPanel.scss';

interface TechInfoPanelProps {
    darkMode?: boolean;
    appInfo?: object;
}

const getTechInfo = () => {
    const { navigator: nav, EcsterConfig: config, location } = window;
    const info = {
        config,
        cookiesEnabled: nav.cookieEnabled,
        language: nav.language,
        location,
        platform: nav.platform,
        userAgent: nav.userAgent,
        // @ts-expect-error
        userAgentData: nav.userAgentData, // exists for some browsers
        vendor: nav.vendor,
        timestamp: dayjs().format(),
        stackTrace: [],
        appInfo: null,
    };
    info.stackTrace = new Error().stack.substring(6).split('    at ');
    return info;
};

/**
 * Present debug info in base64 format.
 * Unpack with console.log(JSON.stringify(JSON.parse(atob(techInfoEncoded)), undefined, 2))
 */
export const TechInfoPanel = ({ darkMode = false, appInfo = null }: TechInfoPanelProps) => {
    const techInfo = getTechInfo();
    const classes = classNames({
        'ec-tech-info-panel': true,
        dark: darkMode,
        light: !darkMode,
    });

    techInfo.appInfo = appInfo;

    const techInfoEncoded = btoa(JSON.stringify(techInfo, undefined, 2));

    return (
        <div className={classes}>
            <ExpandableContainer id="more-info" className={`${darkMode ? 'dark' : 'light'}`} header="Mer info">
                <CopyToClipboardButton
                    tooltip="Kopiera info"
                    tooltipSuccess="Info har kopierats"
                    value={techInfoEncoded}
                    noIcon={true}
                >
                    <span className={`copy-btn ${darkMode ? 'dark' : 'light'}`}>Kopiera info</span>
                </CopyToClipboardButton>
                <div className="app-info">{techInfoEncoded}</div>
            </ExpandableContainer>
        </div>
    );
};
