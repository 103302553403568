import { getOptions } from './get-options';

export const formatNumberNew = (number, options) => {
    if (Number.isNaN(number)) {
        return '';
    }

    const theOptions = options ? { ...options } : {};
    let theNumber = Number(number);

    const { roundUp, roundDown, round, positive, strip00 } = theOptions;
    let { decimals } = theOptions;

    if (theOptions.currency) {
        theOptions.style = 'currency';
    } else {
        theOptions.style = 'decimal';
        theOptions.minimumFractionDigits = decimals || 0;
    }

    // round value either way before formatting
    theNumber = roundUp ? Math.ceil(theNumber) : theNumber;
    theNumber = roundDown ? Math.floor(theNumber) : theNumber;
    theNumber = round ? Math.round(theNumber) : theNumber;
    theNumber = positive ? Math.abs(theNumber) : theNumber;

    // strip 00 decimals?
    // value === 0 or rounded value x 100 ends with "00" then strip 00 decimals by changing no of decimals to 0

    if (strip00 && (`${Math.round(theNumber * 100)}`.match(/00$/) || theNumber === 0)) {
        decimals = 0;
    }

    if (typeof decimals === 'number') {
        theOptions.minimumFractionDigits = decimals;
        theOptions.maximumFractionDigits = decimals;
    }

    const formatted = Intl.NumberFormat(options.locale, theOptions).format(theNumber);

    // replace ugly minus sign with a better one! (campton problem),
    // note: the − and - characters below are different
    return formatted.replace('−', '-');
};

/**
 * Format number correctly based on locale
 *
 * @param value {number}
 * @param options {object} an options object (also accepts a locale string for bw compatible use)
 * @param optionsIfLocale {object} options object if bw compatible use
 *     options as described here: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat#Parameters
 *   and
 *     locale: sv-SE, fi-FI, sv-FI, en-GB etc
 *     currency: undefined | SEK | EUR | etc (options.style='currency' is added automatically if set)
 *     decimals: 0 | 1 | 2 | etc
 *     strip00: true | false (default)
 *     round: true | false (default)
 *     roundUp: true | false (default)
 *     roundDown: true | false (default)
 *     positive: true | false (default)
 * @param global {object} window is default, pass only when testing
 * @returns {string}
 */
export const formatNumber = (value, options, optionsIfLocale, global = window) => {
    if (typeof options === 'string') {
        const opt = getOptions({ ...optionsIfLocale, locale: options }, global);
        return formatNumberNew(value, opt);
    }

    if (!options && optionsIfLocale) {
        const opt = getOptions(optionsIfLocale, global);
        return formatNumberNew(value, opt);
    }

    return formatNumberNew(value, getOptions(options, global));
};
