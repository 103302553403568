import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { /* Checkbox, */ LabelValue, Panel, Radio, RadioGroup } from '@ecster/components';
import { Cookie } from '@ecster/net';

import './CustomerSettingsPanel.scss';
import ChangeLanguageContext from './ChangeLanguageContext';

export default class CustomerSettingsPanel extends React.Component {
    static propTypes = {
        isEditMode: PropTypes.bool,
        // hasSMS: PropTypes.bool,
        // hasEmail: PropTypes.bool,
    };

    static defaultProps = {
        isEditMode: false,
        // hasSMS: false,
        // hasEmail: false,
    };

    state = {
        locale: Cookie.read('locale'),
    };

    // hide temporarily
    // onChange = obj => {
    //     const { onChange } = this.props;
    //     onChange(obj);
    // };

    onChangeLanguage = (event, changeLanguage) => {
        const { locale } = this.state;

        const {
            target: { value: newLocale },
        } = event;

        if (newLocale !== locale) {
            changeLanguage(newLocale);
            this.setState({ locale: newLocale });
        }
    };

    render() {
        const { locale } = this.state;
        const { isEditMode /* , hasEmail, hasSMS */ } = this.props;

        /* HIDE MARKETING SETTINGS TEMPORARILY
        const marketingSMS = i18n('customer.profile.edit-settings.marketing.sms');
        const marketingEmail = i18n('customer.profile.edit-settings.marketing.email');

        let marketing = hasEmail ? marketingEmail : '';
        marketing += hasEmail && hasSMS ? ', ' : '';
        marketing += hasSMS ? marketingSMS : '';

        marketing = marketing === '' ? i18n('customer.profile.edit-settings.marketing.none') : marketing;
        */
        const selectedLanguage = {
            'sv-FI': i18n('customer.profile.edit-settings.change-language.swedish'),
            'fi-FI': i18n('customer.profile.edit-settings.change-language.finnish'),
            'sv-SE': i18n('customer.profile.edit-settings.change-language.swedish'),
        };

        return (
            <Panel className="customer-settings-panel" noBorder paddingLeftRight="none">
                <h3 className="left">{i18n('customer.profile.edit-settings.header')}</h3>
                <div className="expanded-content">
                    <div className="mb-4x">{i18n('customer.profile.edit-settings.change-language.info')}</div>
                    {isEditMode && (
                        <ChangeLanguageContext.Consumer>
                            {changeLanguage => (
                                <RadioGroup
                                    selectedValue={locale}
                                    onChange={event => this.onChangeLanguage(event, changeLanguage)}
                                >
                                    <div className="flex">
                                        <span>{i18n('customer.profile.edit-settings.change-language.finnish')}</span>
                                        <Radio value="fi-FI" />
                                    </div>
                                    <div className="flex">
                                        <span>{i18n('customer.profile.edit-settings.change-language.swedish')}</span>
                                        <Radio value="sv-FI" />
                                    </div>
                                </RadioGroup>
                            )}
                        </ChangeLanguageContext.Consumer>
                    )}
                    {!isEditMode && (
                        <LabelValue
                            value={selectedLanguage[locale]}
                            label={i18n('customer.profile.edit-settings.change-language.header')}
                        />
                    )}
                </div>
                {/* HIDE MARKETING SETTINGS TEMPORARILY
                <hr />
                <div className="expanded-content">
                    <div className="mb-4x">{i18n('customer.profile.edit-settings.marketing.info')}</div>
                    {isEditMode && (
                        <>
                            <div className="flex">
                                <span>{i18n('customer.profile.edit-settings.marketing.sms')}</span>
                                <span>
                                    <Checkbox id="hasSMS" name="hasSMS" checked={hasSMS} onChange={this.onChange} />
                                </span>
                            </div>
                            <div className="flex">
                                <span>{i18n('customer.profile.edit-settings.marketing.email')}</span>
                                <span>
                                    <Checkbox
                                        id="hasEmail"
                                        name="hasEmail"
                                        checked={hasEmail}
                                        onChange={this.onChange}
                                    />
                                </span>
                            </div>
                        </>
                    )}
                    {!isEditMode && (
                        <LabelValue value={marketing} label={i18n('customer.profile.edit-settings.marketing.header')} />
                    )}
                </div> */}
            </Panel>
        );
    }
}
