import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogCloseButton,
    LabelValue,
    detectDevice,
} from '@ecster/components';

import { formatAmount, formatAccount } from '@ecster/util';
import { SimpleCopyToClipboardButton as CopyToClipboardButton } from '../../common/SimpleCopyToClipboardButton';

const steps = {
    START: 'START',
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
};

const {
    EcsterConfig: { paymentURL },
} = window;

export const PaymentDialog = ({ account, isOpen, onRequestClose }) => {
    const [step, setStep] = useState(steps.START);
    const linkRef = useRef();
    const { EcsterConfig: conf } = window;
    const { isDesktop } = detectDevice();

    const close = () => {
        onRequestClose(false);
    };

    useEffect(() => {
        let timeout;
        if (step === steps.LOADING) {
            // wait 2000 ms on LOADING step, then open URL in new tab
            timeout = setTimeout(() => {
                setStep(steps.SUCCESS);
                linkRef.current.click();
            }, 2000);
        }
        // prevent seeing switch to SUCCESS when closing modal on LOADING step during the modal fade out
        return () => {
            if (step === steps.LOADING) {
                clearTimeout(timeout);
            }
        };
    }, [step]);

    // reset step to START when closing modal, 500ms timeout to not show the change before modal fades out
    useEffect(() => {
        if (!isOpen) {
            setTimeout(() => {
                setStep(steps.START);
            }, 500);
        }
    }, [isOpen]);

    return (
        <div className="payment-dialog">
            <Dialog maxWidth="532px" open={isOpen} onRequestClose={() => close()}>
                <a
                    ref={linkRef}
                    className="payment-form-link"
                    href={paymentURL}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Link to payment form
                </a>
                {step === steps.START && (
                    <>
                        <DialogCloseButton onRequestClose={() => close()} />
                        <DialogBody>
                            <h3 className="payment-dialog-header">{i18n('account.overview.payment-dialog.header')}</h3>
                            <p>{i18n('account.overview.payment-dialog.info-text')}</p>
                            <p>{i18n('account.overview.payment-dialog.sub-header')}</p>
                            <LabelValue
                                label={
                                    <>
                                        <p>{i18n('account.overview.payment-dialog.account-number')}</p>
                                        <CopyToClipboardButton
                                            tooltip={i18n('account.overview.payment-dialog.copy-tooltip')}
                                            tooltipSuccess={i18n('account.overview.payment-dialog.copied-tooltip')}
                                            value={account.accountNumber}
                                        >
                                            <strong>{formatAccount(account.accountNumber)}</strong>
                                        </CopyToClipboardButton>
                                    </>
                                }
                                value={
                                    <>
                                        <p>{i18n('account.overview.payment-dialog.credit-balance')}</p>
                                        <strong>{formatAmount(-1 * account.used)}</strong>
                                    </>
                                }
                            />
                        </DialogBody>
                        <DialogFooter>
                            <ButtonGroup alignCenter>
                                <Button round iconRight="icon-external-link" onClick={() => setStep(steps.LOADING)}>
                                    {i18n('account.overview.payment-dialog.link-text')}
                                </Button>
                            </ButtonGroup>
                        </DialogFooter>
                    </>
                )}
                {step === steps.LOADING && (
                    <>
                        <DialogBody>
                            <div className="loading-body">
                                <h3 style={{ textAlign: 'center' }}>
                                    {i18n('account.overview.payment-dialog.loading-text')}
                                </h3>
                            </div>
                        </DialogBody>
                    </>
                )}
                {step === steps.SUCCESS && (
                    <>
                        <DialogCloseButton onRequestClose={() => close()} />
                        <DialogBody>
                            <h3 className="payment-dialog-header">{i18n('account.overview.payment-dialog.header')}</h3>
                            <p>{i18n('account.overview.payment-dialog.success-text')}</p>
                        </DialogBody>
                        <DialogFooter>
                            <ButtonGroup alignCenter>
                                <Button round onClick={() => close()}>
                                    {i18n('general.close')}
                                </Button>
                            </ButtonGroup>
                        </DialogFooter>
                    </>
                )}
            </Dialog>
        </div>
    );
};

PaymentDialog.propTypes = {
    account: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
};
