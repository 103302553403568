import React from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelContent } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { vouchersIcon } from '@ecster/icons/H80/green';
import { bonusIcon } from '@ecster/icons/H100/green';

import { LoginForm } from './LoginForm';

import './LoginInfoPanel.scss';
import { formatNumber } from '../../../common/util/format';

export const LoginInfoPanel = ({ loyaltyDetails }) => {
    const i18nKey = loyaltyDetails.bonusSiteType.toLowerCase().replace('_', '-');

    const icon = loyaltyDetails.bonusSiteType === 'BONUS_ECSTER' ? bonusIcon : vouchersIcon;

    const points =
        loyaltyDetails.bonusSiteType === 'BONUS_UTTAG'
            ? loyaltyDetails.loyaltyInformation
                ? formatNumber(Number.parseInt(loyaltyDetails.loyaltyInformation.points, 10), {
                      decimals: 0,
                  })
                : i18n('account.bonus-overview-page.info-panel.bonus-uttag.no-points-replacement')
            : '';

    return (
        <Panel paddingLeftRight="xxLarge" stretchInMobile className="bonus-login-info-panel">
            <PanelContent>
                <img src={icon} className="bonus-icon" alt="" />
                <h2 className="h3"> {i18n(`account.bonus-overview-page.info-panel.${i18nKey}.header`)}</h2>
                {loyaltyDetails.bonusSiteType === 'BONUS_ECSTER' && (
                    <p className="centered">{i18n('account.bonus-overview-page.info-panel.bonus-ecster.text')}</p>
                )}
                {loyaltyDetails.bonusSiteType === 'BONUS_UTTAG' && (
                    <p className="centered">
                        {i18n(`account.bonus-overview-page.info-panel.bonus-uttag.text`, { points })}
                    </p>
                )}
            </PanelContent>
            <PanelContent xNarrow>
                <LoginForm
                    loginType={loyaltyDetails.bonusSiteType}
                    username={loyaltyDetails.loyaltyNumber}
                    password={loyaltyDetails.loyaltyCredentials}
                    url={loyaltyDetails.bonusSiteUrl}
                />
            </PanelContent>
        </Panel>
    );
};

LoginInfoPanel.propTypes = {
    loyaltyDetails: PropTypes.object.isRequired,
};

LoginInfoPanel.defaultProps = {};
