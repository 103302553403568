import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboardButton, LabelValue } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { copyIcon } from '@ecster/icons/H15/green';

import './LoginDetils.scss';

const i18nPrefix = 'account.bonus-overview-page.login-details';

const LoginDetails = ({ username, password }) => {
    return (
        <div className="bonus-login-details">
            <p className="mtb-4x">{i18n('account.bonus-overview-page.login-details.header')}</p>
            <LabelValue
                label={i18n(`${i18nPrefix}.labels.account-no`)}
                value={
                    <CopyToClipboardButton
                        value={username}
                        icon={<img src={copyIcon} alt="" />}
                        tooltip={i18n(`${i18nPrefix}.tooltip.text`, {
                            item: i18n(`${i18nPrefix}.labels.account-no`).toLowerCase(),
                        })}
                        tooltipSuccess={i18n(`${i18nPrefix}.tooltip.success`)}
                    >
                        {username}
                    </CopyToClipboardButton>
                }
            />
            <LabelValue
                label={i18n(`${i18nPrefix}.labels.pin-code`)}
                value={
                    <CopyToClipboardButton
                        value={password}
                        icon={<img src={copyIcon} alt="" />}
                        tooltip={i18n(`${i18nPrefix}.tooltip.text`, {
                            item: i18n(`${i18nPrefix}.labels.pin-code`).toLowerCase(),
                        })}
                        tooltipSuccess={i18n(`${i18nPrefix}.tooltip.success`)}
                    >
                        {password}
                    </CopyToClipboardButton>
                }
            />
        </div>
    );
};

LoginDetails.propTypes = {
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
};
LoginDetails.defaultProps = {};

export default LoginDetails;
