import React from 'react';

import SendAMessage from './SendAMessage';
import { BlockCardInfo } from '../BlockCardInfo';
import { CallUs } from './CallUs';
import { CallUsComplaint } from './CallUsComplaint';
import { Hide } from '../../../common/Hide.jsx';

import './ContactUsInfoFI.scss';

const ContactUsInfoFI = () => {
    return (
        <div className="customer-contact-us-info">
            <CallUsComplaint />
            <Hide after="2024-05-01 00:00">
                <BlockCardInfo />
            </Hide>
            <SendAMessage />
            <CallUs />
        </div>
    );
};

ContactUsInfoFI.propTypes = {};

ContactUsInfoFI.defaultProps = {};

export default ContactUsInfoFI;
