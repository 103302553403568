import React from 'react';

import { i18n } from '@ecster/i18n';
import { Panel, LinkButton } from '@ecster/components';

const SecureEnrollmentMessage = () => {
    const url = '/secure-id/overview';

    return (
        <Panel className="secure-enrollment-message" blueBG paddingTopBottom="medium" paddingLeftRight="medium">
            <div className="secure-enrollment-message-text-container">
                <strong>{i18n('secure-id.secure-enrollment-message.heading')}</strong>
                <span>{i18n('secure-id.secure-enrollment-message.text')}</span>
            </div>
            <div className="secure-enrollment-message-cta-container">
                <LinkButton
                    to={url}
                    className="secure-enrollment-message-link"
                    ariaLabel={i18n('secure-id.secure-enrollment-message.link')}
                    round
                    blue
                    xSmall
                >
                    {i18n('secure-id.secure-enrollment-message.link')}
                </LinkButton>
            </div>
        </Panel>
    );
};

export default SecureEnrollmentMessage;
