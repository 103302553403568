import React from 'react';

import { detectDevice, Logo, Link, FullscreenDialog } from '@ecster/components';
import { useMyPagesConfig } from '../hooks/useMyPagesConfig';
import { i18n } from '@ecster/i18n';

import './AppIsClosedMessage.scss';

// TODO: 2024-03-01: keep or remove? Use later for closing Inloggat web?
export const AppIsClosedMessage = () => {
    const { EcsterConfig: conf } = window;
    const { isDesktop } = detectDevice();
    const [isOpen, setIsOpen] = React.useState(true);

    const myPagesConfig = useMyPagesConfig();

    const mobileAppShutdownConfig = myPagesConfig?.mobileAppShutdownConfig;

    const devOrTest = conf.env === 'dev' || conf.env === 'test';

    if (mobileAppShutdownConfig?.status === 'DISABLED' && (conf.isApp || (devOrTest && !isDesktop))) {
        return (
            <>
                <div className="app-is-closed">
                    <FullscreenDialog show={isOpen} onClose={() => {}} isCloseButtonVisible={false}>
                        <div className="app-closed-ctr flex flex-col items-center p-4x e-bg-beige20">
                            <div> </div>
                            <div className="app-closed-info">
                                <h3 className="e-green120 centered">{mobileAppShutdownConfig.title}</h3>
                                <div
                                    className="centered"
                                    dangerouslySetInnerHTML={{ __html: mobileAppShutdownConfig.text }}
                                />
                            </div>
                            <div className="flex flex-col items-center">
                                <Logo className="mb-4x" width="50px" />
                                {devOrTest && (
                                    <Link to="/authentication/help" onClick={() => setIsOpen(false)}>
                                        {i18n('authentication.app-is-closed.help')}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </FullscreenDialog>
                </div>
            </>
        );
    }

    return null;
};
