export const SECURE_ID_GET_ENROLLED_DEVICES_BEGIN = 'SECURE_ID_GET_ENROLLED_DEVICES_BEGIN';
export const SECURE_ID_GET_ENROLLED_DEVICES_SUCCESS = 'SECURE_ID_GET_ENROLLED_DEVICES_SUCCESS';
export const SECURE_ID_GET_ENROLLED_DEVICES_FAILURE = 'SECURE_ID_GET_ENROLLED_DEVICES_FAILURE';
export const SECURE_ID_GET_ENROLLED_DEVICES_DISMISS_ERROR = 'SECURE_ID_GET_ENROLLED_DEVICES_DISMISS_ERROR';
export const SECURE_ID_GET_SECURE_ACTIVATION_KEY_BEGIN = 'SECURE_ID_GET_SECURE_ACTIVATION_KEY_BEGIN';
export const SECURE_ID_GET_SECURE_ACTIVATION_KEY_SUCCESS = 'SECURE_ID_GET_SECURE_ACTIVATION_KEY_SUCCESS';
export const SECURE_ID_GET_SECURE_ACTIVATION_KEY_FAILURE = 'SECURE_ID_GET_SECURE_ACTIVATION_KEY_FAILURE';
export const SECURE_ID_GET_SECURE_ACTIVATION_KEY_DISMISS_ERROR = 'SECURE_ID_GET_SECURE_ACTIVATION_KEY_DISMISS_ERROR';
export const SECURE_ID_DELETE_SECURE_ID_BEGIN = 'SECURE_ID_DELETE_SECURE_ID_BEGIN';
export const SECURE_ID_DELETE_SECURE_ID_SUCCESS = 'SECURE_ID_DELETE_SECURE_ID_SUCCESS';
export const SECURE_ID_DELETE_SECURE_ID_FAILURE = 'SECURE_ID_DELETE_SECURE_ID_FAILURE';
export const SECURE_ID_DELETE_SECURE_ID_DISMISS_ERROR = 'SECURE_ID_DELETE_SECURE_ID_DISMISS_ERROR';
