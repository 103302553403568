import React, { lazy, Suspense } from 'react';

import { LabelValue, Link, Spinner, ExpandableContainer } from '@ecster/components';
import { i18n } from '@ecster/i18n';

const LicenseFile = lazy(() => import('./LicenseFile'));

const labelValue = (label, value, link) => {
    const linkText = link && value?.replace(/^http(s)?:\/\//, '');

    return (
        value && (
            <LabelValue
                label={label}
                value={
                    link ? (
                        <Link target="_blank" href={value}>
                            {linkText}
                        </Link>
                    ) : (
                        value
                    )
                }
            />
        )
    );
};

const LicenseInfo = ({ license }) => (
    <div className="license-info">
        <h3>{license.name}</h3>
        {labelValue(i18n('about.license-list.entry.license'), license.licenses)}
        {labelValue(i18n('about.license-list.entry.published-by'), license.publisher)}
        {labelValue(i18n('about.license-list.entry.email'), license.email)}
        {labelValue(i18n('about.license-list.entry.repo'), license.repository, true)}
    </div>
);

// export for test
export const LicenseGroup = ({ group, licenses }) => {
    if (licenses.length === 1) {
        return (
            <div className="ll-license-group single-license">
                <LicenseInfo license={licenses[0]}></LicenseInfo>
                <Suspense fallback={<Spinner id="license-list-spinner" isCenterX isVisible />}>
                    <LicenseFile path={licenses[0].licenseFile} licenseKey={licenses[0].name} />
                </Suspense>
            </div>
        );
    } else {
        return (
            <div className="ll-license-group multiple-licenses">
                <ExpandableContainer id={`license-group-${group}`} header={group} left>
                    <div className="ml-4x">
                        {licenses.map(license => (
                            <React.Fragment key={license.name}>
                                <LicenseInfo license={license}></LicenseInfo>
                                <Suspense fallback={<Spinner id="license-list-spinner" isCenterX isVisible />}>
                                    <LicenseFile path={license.licenseFile} licenseKey={license.name} />
                                </Suspense>
                            </React.Fragment>
                        ))}
                    </div>
                </ExpandableContainer>
            </div>
        );
    }
};
const GroupedLicenseList = ({ groups }) => {
    if (!groups) return <Spinner id="license-list-spinner" isCenterX isVisible />;
    return (
        <div className="about-license-list">
            {Object.keys(groups).map(group => (
                <LicenseGroup key={group} group={group} licenses={groups[group]}></LicenseGroup>
            ))}
        </div>
    );
};

export default GroupedLicenseList;
