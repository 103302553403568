import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import { InfoWidget, SlideShow } from '../common/customer-communication';

export default class CustomerInfoSlideShow extends React.Component {
    render() {
        const slides = [];
        const { showLoanProtection } = this.props;

        if (showLoanProtection) {
            slides.push(
                <InfoWidget
                    id="loan-protection-banner"
                    key="loan-protection-banner"
                    header={i18n('slide-show.loan-protection.header')}
                    bgImage="./images/backgrounds/se/loan-protection.jpg"
                    bgImageMobile="./images//backgrounds/se/loan-protection-1x1.jpg"
                    linkText={i18n('slide-show.loan-protection.link')}
                    linkTextScreenreader={i18n('slide-show.loan-protection.link-screenreader')}
                    linkTarget="blank"
                    linkUrl="https://www.ecster.se/forsakring"
                >
                    {i18n('slide-show.loan-protection.text')}
                </InfoWidget>
            );
        }

        slides.push(
            <InfoWidget
                id="filmstaden-banner"
                key="filmstaden-banner"
                header={i18n('slide-show.filmstaden-banner.header')}
                bgImage="./images/backgrounds/se/Salong_filmstadenlogga.jpg"
                bgImageMobile="./images/backgrounds/se/Salong_filmstadenlogga_mobile.jpg"
                linkText={i18n('slide-show.filmstaden-banner.link')}
                linkTarget="blank"
                linkUrl="https://deals.awardit.com?code=zrzy6w8sqi84wbsi7bab"
            >
                {i18n('slide-show.filmstaden-banner.text')}
            </InfoWidget>
        );

        return (
            <div className="customer-customer-info-slide-show">
                <SlideShow duration={7000} transitionDuration={500}>
                    {slides}
                </SlideShow>
            </div>
        );
    }
}

CustomerInfoSlideShow.propTypes = {
    showLoanProtection: PropTypes.bool.isRequired,
};

CustomerInfoSlideShow.defaultProps = {};
