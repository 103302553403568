import React from 'react';
import { Link, Message } from '@ecster/components';
import { feedbackIcon } from '@ecster/icons/H40/purple';
import { i18n } from '@ecster/i18n';

export const SurveyMessage = () => {
    const { EcsterConfig: conf } = window;
    const key = conf?.env === 'prod' ? 'prod' : 'test';

    return (
        <Message
            iconInMobile={false}
            purple
            whiteBg
            header={i18n('overview.survey-msg.header') as string}
            iconUrl={feedbackIcon}
        >
            <p>{i18n('overview.survey-msg.text')}</p>
            <p>
                <Link target="_blank" href={i18n(`overview.survey-msg.${key}.link`) as string}>
                    {i18n('overview.survey-msg.link-text')}
                </Link>
            </p>
        </Message>
    );
};
