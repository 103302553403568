import React, { ReactNode, useState } from 'react';
import IconButton from './IconButton';
import { Tooltip } from '../Tooltip';
import { copyIcon } from '@ecster/icons/H15/green';

import './CopyToClipboardButton.scss';

const { navigator: nav } = window;

const isIOS = !!/ipad|iphone|ipod/i.test(nav.userAgent);
const isIE11 = !!nav.userAgent.match(/trident\/7/i);

// note: cordova is not ready when bundle loads, can't do check then, so use functions
const supportsCordovaAPI = () => {
    const { cordova } = window;
    return !!cordova?.plugins?.clipboard;
};
const supportsClipboardAPI = () => !!nav.clipboard;
const supportsInputFieldCopy = () => !isIOS && !isIE11; // ios flickers onscreen keyboard, IE11 copies value but crashes
const noSupport = () => !supportsCordovaAPI() && !supportsClipboardAPI() && !supportsInputFieldCopy();

// copy with cordova plugin API in apps
const copyWithCordovaAPI = text => {
    window.cordova.plugins.clipboard.copy(text);
};

// copy with native API in newer browsers
const copyWithClipboardAPI = text => {
    nav.clipboard.writeText(text);
};

// copy with a tmp input field, fallback for browsers or apps not supporting APIs
const copyWithInputField = text => {
    const { document: doc } = window;

    const input = doc.createElement('input');

    input.setAttribute('value', text ? text.trim() : '');
    input.style.cssText = 'position: absolute; width: 10px; top: -25px; left: -25px;';

    doc.body.appendChild(input);
    input.select();

    document.execCommand('copy');

    setTimeout(() => {
        document.body.removeChild(input);
    }, 20);
};

export const copyToClipboard = text => {
    if (supportsCordovaAPI()) {
        copyWithCordovaAPI(text);
    } else if (supportsClipboardAPI()) {
        copyWithClipboardAPI(text);
    } else if (supportsInputFieldCopy()) {
        copyWithInputField(text);
    }
};

interface CopyToClipboardButtonProps {
    /**
     * Unique ID for this particular tip
     */
    id?: string;

    /**
     * Optional child elements, place to the left of the clipboard icon
     */
    children?: ReactNode;

    /**
     * Value to be copied
     */
    value: string;

    /**
     * Tooltip text
     */
    tooltip: string;

    /**
     * Tooltip text on copy success
     */
    tooltipSuccess?: string;

    /**
     * Icon class for the copy icon or a jsx node (img tag)
     */
    icon?: string;

    /**
     * No icon. If true, only child elements are clickable
     */
    noIcon?: boolean;
}

/**
 * An IconButton that takes a value as property.
 * Value is copied to clipboard when button is clicked
 */
const CopyToClipboardButton = ({
    children = null,
    id,
    noIcon = false,
    icon = '',
    value,
    tooltip,
    tooltipSuccess = '',
}: CopyToClipboardButtonProps) => {
    const [tooltipText, setTooltipText] = useState(tooltip);
    const onClick = () => {
        copyToClipboard(value);

        if (tooltipSuccess) setTooltipText(tooltipSuccess);
        return false;
    };
    const onClose = () => {
        setTooltipText(tooltip); // initial message for next time
    };

    if (noSupport()) {
        return <>{children}</>;
    }

    return (
        <Tooltip
            id={id}
            className="tooltip"
            position={['top center', 'right center']}
            events={['hover']}
            theme="light"
            onClose={onClose}
            trigger={
                <span className="ec-ctc-btn-tt-trigger" onClick={onClick}>
                    {children}
                    {!noIcon && (
                        <span className="ml-2x">
                            {!icon && <img src={copyIcon} alt="" />}
                            {typeof icon === 'string' && <IconButton onClick={() => {}} icon={icon} />}
                            {typeof icon !== 'string' && <>{icon}</>}
                        </span>
                    )}
                </span>
            }
        >
            <span>{tooltipText}</span>
        </Tooltip>
    );
};

export default CopyToClipboardButton;
