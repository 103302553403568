import React from 'react';
import PropTypes from 'prop-types';

import { LinkButton } from '@ecster/components';
import { i18n } from '@ecster/i18n';

import { BonusuttagLoginForm } from './BonusuttagLoginForm';
import { BonusShopLoginForm } from './BonusShopLoginForm';

import './LoginForm.scss';

const Wrap = ({ children }) => <div className="bonus-login-form">{children}</div>;

Wrap.propTypes = { children: PropTypes.node.isRequired };

const noEndingSlash = value => value.replace(/\/$/, '');

export const LoginForm = ({ loginType, username, password, url }) => {
    switch (loginType) {
        case 'BONUS_ECSTER':
            return (
                <Wrap>
                    <BonusShopLoginForm username={username} password={password} baseUrl={noEndingSlash(url)} />
                </Wrap>
            );
        case 'BONUS_UTTAG':
            return (
                <Wrap>
                    <BonusuttagLoginForm username={username} password={password} baseUrl={noEndingSlash(url)} />
                </Wrap>
            );
        case 'EXTERNAL':
            return (
                <Wrap>
                    <LinkButton round outline id="bonus-site-button" iconRight="icon-external-link" href={url}>
                        {i18n('account.bonus-overview-page.info-panel.bonus-page-link')}
                    </LinkButton>
                </Wrap>
            );
        default:
            return null;
    }
};

LoginForm.propTypes = {
    loginType: PropTypes.string.isRequired,
    username: PropTypes.string,
    password: PropTypes.string,
    url: PropTypes.string,
};

LoginForm.defaultProps = {
    username: '',
    password: '',
    url: '',
};
