/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-unused-vars */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    Dialog,
    DialogBody,
    DialogFooter,
    Button,
} from '@ecster/components';
import { Cookie } from '@ecster/net';
import { i18n } from '@ecster/i18n';

import { hideOnboarding } from '../redux/actions';
import { renderThisCountry } from '../i18n/renderThisCountry';
import SecureIdOnboardingDialog from '../../secure-id/components/SecureIdOnboardingDialog';

// increase value if we want all users to see the onboarding dialog again
const onboardingVersion = 4; // 4 for secure-id release

const seenAlreadyKey = (customerId, locale) => `onboarding-info-${customerId}-${locale}-${onboardingVersion}`;

const isIE11 = !!window.navigator.userAgent.match(/trident\/7/i);

// cookies for web, native storage for app
const setSeenAlready = customerId => {
    const {
        EcsterConfig: { locale },
    } = window;
    const key = seenAlreadyKey(customerId, locale);

    Cookie.create(key, true, { days: 365 * 50 });
};

const getSeenAlready = (customerId, callback) => {
    const {
        EcsterConfig: { locale },
    } = window;
    const key = seenAlreadyKey(customerId, locale);

    callback(Cookie.read(key) === true);
};

class OnboardingDialog extends Component {
    buttonRef = React.createRef();

    static propTypes = {
        hideOnboarding: PropTypes.func.isRequired,
        showDialogAgain: PropTypes.bool, // overrides cookie
        customerId: PropTypes.number.isRequired,
        showDialog: PropTypes.func, // optional function
    };

    static defaultProps = {
        showDialogAgain: false,
        showDialog: () => true,
    };

    state = {
        openDialog: false,
    };

    componentDidMount() {
        const { customerId } = this.props;

        getSeenAlready(customerId, seenAlready => {
            if (!seenAlready) {
                document.addEventListener('keydown', this.escFunction, false);
                this.setState({ openDialog: true });
            } else {
                document.removeEventListener('keydown', this.escFunction);
            }
        });
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showDialogAgain) {
            // show again after click on beta or beta label
            this.setState({ openDialog: true });
        }
    }

    componentDidUpdate() {
        const { openDialog } = this.state;
        if (openDialog && this.buttonRef.current) {
            // grab focus to avoid tabbing around in app behind overlay
            this.buttonRef.current.focus();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction);
    }

    escFunction = event => {
        if (event.key === 'Escape') {
            this.onClickGo();
        }
    };

    onBlur = () => {
        // re-grab focus to avoid tabbing again
        this.buttonRef.current.focus();
    };

    onClickGo = () => {
        const { hideOnboarding, customerId } = this.props;
        this.setState({ openDialog: false });
        hideOnboarding();
        setSeenAlready(customerId);
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    renderSE() {
        return this.renderDialog(
            <>
                <img className="w50-image" src="./images/bonus/bonus-news.svg" />
                <button type="button" className="close" onClick={this.onClickGo} aria-label={i18n('general.close')} />
                <div className="onboarding-content">
                    <h2>{i18n('common.onboarding.header')}</h2>
                    <div>{i18n('common.onboarding.text', 'p', { danger: true })}</div>
                </div>
            </>
        );
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    renderFI() {
        const { openDialog } = this.state;
        const { showDialog } = this.props;

        if (isIE11 || !openDialog || !showDialog()) return null;

        return <SecureIdOnboardingDialog isOpen={openDialog} onClose={this.onClickGo} buttonRef={this.buttonRef} />;
    }

    renderDialog = body => {
        const { openDialog } = this.state;
        const { showDialog } = this.props;

        if (isIE11 || !openDialog || !showDialog()) return null;

        return (
            <Dialog rounded5x maxWidth="500px" fullscreenMobile open={openDialog} className="common-onboarding-dialog">
                <DialogBody className="dialog">{body}</DialogBody>
                <DialogFooter>
                    <Button
                        round
                        onClick={this.onClickGo}
                        onBlur={this.onBlur}
                        name="onboarding-lets-go"
                        ref={this.buttonRef}
                    >
                        {i18n('common.onboarding.button-text')}
                    </Button>
                </DialogFooter>
            </Dialog>
        );
    };

    render() {
        return renderThisCountry(this);
    }
}

const mapStateToProps = ({ common, authentication }) => ({
    showDialogAgain: common.onboarding && common.onboarding.show,
    customerId: authentication.person && authentication.person.id,
    firstName: authentication.person && authentication.person.firstName,
});

const mapDispatchToProps = dispatch => ({
    hideOnboarding: () => dispatch(hideOnboarding()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingDialog);
