import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { MessageDefaultIcon } from './MessageDefaultIcon';
import './Message.scss';

interface MessageProps {
    /**
     * CSS id
     */
    id?: string;

    /**
     * Additional CSS classes
     */
    className?: string;

    /**
     * Message header
     */
    header?: string;

    /**
     * Text message
     */
    message?: string;

    /**
     * Alternate spec of message (with jsx)
     */
    children?: ReactNode;
    blue?: boolean;
    purple?: boolean;
    green?: boolean;
    pay?: boolean;
    red?: boolean;
    yellow?: boolean;
    beige?: boolean;

    /** White background */
    whiteBg?: boolean;

    /** Gray background, no border */
    grayBg?: boolean;

    /** Beige background, no border */
    beigeBg?: boolean;

    /** Transparent background, no border */
    transparent?: boolean;

    /** Use a border */
    border?: boolean;

    /**
     * Exclamation mark in circle instead of default (i) icon
     *
     * Note: use only one of exclamation and icon* properties
     */
    exclamation?: boolean;

    /**
     * Override the default icon with a font icon classname
     *
     * Note: use only one of exclamation and icon* properties
     */
    iconClass?: string;

    /**
     * Override the default icon with a URL to an img tag
     *
     * Note: use only one of exclamation and icon* properties
     */
    iconUrl?: string;

    /**
     * Override the default icon with an icon object, or false to not use an icon
     *
     * Note: use only one of exclamation and icon* properties
     */
    icon?: ReactNode | boolean;

    /**
     * Show icon in mobile or not
     */
    iconInMobile?: boolean;
}

class Message extends React.Component<MessageProps> {
    render() {
        const { blue, purple, green, pay, red, yellow, beige, id } = this.props;
        const {
            message,
            header,
            transparent,
            whiteBg,
            grayBg,
            beigeBg,
            exclamation,
            icon,
            iconClass,
            border,
            iconUrl,
            iconInMobile,
            children,
            className,
        } = this.props;

        const classes = classNames({
            'ec-message': true,
            'white-bg': whiteBg,
            'gray-bg': grayBg,
            'beige-bg': beigeBg,
            'no-bg': transparent,
            'no-icon': icon === false,
            'no-border': !border,
            'no-icon-in-mobile': !iconInMobile,
            [className]: className,
        });

        const colorClasses = classNames({
            blue,
            purple,
            green,
            pay,
            red,
            yellow,
            beige,
        });

        let theIcon = icon !== false && (
            <MessageDefaultIcon className={exclamation ? `${colorClasses} exclamation` : colorClasses} />
        );

        if (iconClass) theIcon = <div className={`ec-message-icon ${iconClass}`} />;

        if (iconUrl)
            theIcon = (
                <div className="ec-message-icon">
                    <img className="ec-message-icon" src={iconUrl} alt="" />
                </div>
            );

        if (icon) theIcon = <div className="ec-message-icon">{icon}</div>;

        return (
            <div id={id} className={`${classes} ${colorClasses}`}>
                {theIcon}
                <div className="ec-message-container">
                    <div className="ec-message-header">{header}</div>
                    <div className="ec-message-body">
                        {message ? <span dangerouslySetInnerHTML={{ __html: message }} /> : children}
                    </div>
                </div>
            </div>
        );
    }
}

// @ts-expect-error class defaultProps
Message.defaultProps = {
    id: '',
    className: undefined,
    header: undefined,
    message: undefined,
    children: undefined,
    blue: false,
    purple: false,
    green: false,
    pay: false,
    red: false,
    yellow: false,
    beige: false,
    whiteBg: false,
    grayBg: false,
    beigeBg: false,
    transparent: false,
    border: true,
    exclamation: false,
    iconClass: undefined,
    iconUrl: undefined,
    icon: undefined,
    iconInMobile: true,
};

export default Message;
