import { del } from '@ecster/net/v2'; // or post, put, del
import {
    SECURE_ID_DELETE_SECURE_ID_BEGIN,
    SECURE_ID_DELETE_SECURE_ID_SUCCESS,
    SECURE_ID_DELETE_SECURE_ID_FAILURE,
    SECURE_ID_DELETE_SECURE_ID_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';

import { getEnrolledDevices } from './getEnrolledDevices';
import { DELETE_SECURE_ID_URL } from './urls';

const CLEAR_DELETE_SECURE_ID_STATE = 'CLEAR_DELETE_SECURE_ID_STATE';

export const deleteSecureId = (customerId, deviceId) => async dispatch => {
    dispatch({
        type: SECURE_ID_DELETE_SECURE_ID_BEGIN,
    });

    try {
        const res = await del(DELETE_SECURE_ID_URL(customerId, deviceId));

        dispatch({
            type: SECURE_ID_DELETE_SECURE_ID_SUCCESS,
            data: res.response,
        });

        dispatch(getEnrolledDevices(customerId));
    } catch (err) {
        reportError(err, 'deleteSecureId');
        dispatch({
            type: SECURE_ID_DELETE_SECURE_ID_FAILURE,
            data: {
                error: {
                    message: err,
                    action: SECURE_ID_DELETE_SECURE_ID_FAILURE,
                },
            },
        });
    }
};

export const dismissDeleteSecureIdError = () => ({ type: SECURE_ID_DELETE_SECURE_ID_DISMISS_ERROR });

export const clearDeleteSecureIdState = () => ({ type: CLEAR_DELETE_SECURE_ID_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    switch (action.type) {
        case SECURE_ID_DELETE_SECURE_ID_BEGIN:
            return {
                ...state,
                deleteSecureIdPending: true,
                deleteSecureIdError: null,
            };

        case SECURE_ID_DELETE_SECURE_ID_SUCCESS:
            return {
                ...state,
                deleteSecureIdPending: false,
                deleteSecureIdError: null,
            };

        case CLEAR_DELETE_SECURE_ID_STATE:
            return {
                ...state,
            };

        case SECURE_ID_DELETE_SECURE_ID_FAILURE:
            return {
                ...state,
                deleteSecureIdPending: false,
                deleteSecureIdError: action.data.error,
            };

        case SECURE_ID_DELETE_SECURE_ID_DISMISS_ERROR:
            return {
                ...state,
                deleteSecureIdError: null,
            };

        default:
            return state;
    }
}
