import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { i18n } from '@ecster/i18n';
import { GeneralErrorMessagePanel, Spinner } from '@ecster/components';

import { reduxActionFailed, reduxActionSucceeded } from '@ecster/util';

import AuthenticatedSubPageTemplate from '../common/templates/AuthenticatedSubPageTemplate';
import { getAccountCards, getLoyaltyDetails } from './redux/actions';

import { BonusDetailsHeader, ExternalBonusSiteHeader, FaqPanel, LoginInfoPanel } from './bonus';

import { UserMessageInactiveCard } from './bonus/UserMessage';
import { getDebugInfo } from '../../common/util/getDebugInfo';

export class BonusOverviewPage extends React.Component {
    state = {
        dataAvailable: false,
        error: false,
        inactiveCard: false,
    };

    componentDidMount() {
        const {
            getLoyaltyDetails,
            customerId,
            getAccountCards,
            accountRef,
            loyaltyType,
            loyaltyId,
            // router: {
            //     params: { accountRef, loyaltyType, loyaltyId },
            // },
        } = this.props;
        getLoyaltyDetails(customerId, accountRef, loyaltyType, loyaltyId);
        getAccountCards(customerId, accountRef);
    }

    /* eslint-disable react/no-did-update-set-state */
    componentDidUpdate(prevProps) {
        // loyalty details success
        if (reduxActionSucceeded('getLoyaltyDetails', prevProps, this.props)) {
            this.setState({ dataAvailable: true });
        }
        // loyalty details fail
        if (reduxActionFailed('getLoyaltyDetails', prevProps, this.props)) {
            this.setState({ dataAvailable: false, error: true });
        }

        // account cards success
        if (reduxActionSucceeded('getAccountCards', prevProps, this.props)) {
            const { accountRef, accountCards } = this.props;

            const accountCard = accountCards[accountRef];
            this.setState({ inactiveCard: accountCard && accountCard.status === 'INACTIVE' });
        }
    }

    render() {
        const { dataAvailable, inactiveCard, error } = this.state;
        const { accountRef, loyaltyId, loyaltyDetails } = this.props;

        const noSite = dataAvailable && loyaltyDetails.bonusSiteType === 'NO_SITE';
        const externalSite = dataAvailable && loyaltyDetails.bonusSiteType === 'EXTERNAL';
        const showInfoPanel = dataAvailable && !noSite && !externalSite;

        return (
            <AuthenticatedSubPageTemplate
                className="account-bonus-overview-page"
                header={i18n('account.bonus-overview-page.header')}
            >
                {dataAvailable ? (
                    <>
                        {inactiveCard && <UserMessageInactiveCard accountRef={accountRef} />}

                        {!externalSite && <BonusDetailsHeader loyaltyDetails={loyaltyDetails} />}

                        {externalSite && (
                            <ExternalBonusSiteHeader loyaltyId={loyaltyId} loyaltyDetails={loyaltyDetails} />
                        )}

                        {showInfoPanel && <LoginInfoPanel loyaltyDetails={loyaltyDetails} />}

                        <FaqPanel loyaltyId={loyaltyId} bonusSiteType={loyaltyDetails.bonusSiteType} />
                    </>
                ) : error ? (
                    <GeneralErrorMessagePanel
                        header={i18n('account.bonus-overview-page.error.header')}
                        body={i18n('account.bonus-overview-page.error.text')}
                        buttonText={i18n('account.bonus-overview-page.error.link-text')}
                        isLink
                        onButtonClick={() => (window.location.href = '#/account/overview')}
                        getAppInfo={getDebugInfo}
                    />
                ) : (
                    <Spinner id="bonus-overview-spinner" isCenterX isVisible />
                )}
            </AuthenticatedSubPageTemplate>
        );
    }
}

BonusOverviewPage.propTypes = {
    accountRef: PropTypes.string.isRequired,
    loyaltyId: PropTypes.string.isRequired,
    loyaltyType: PropTypes.string.isRequired,
    // redux
    customerId: PropTypes.number.isRequired,
    // loyalty details
    loyaltyDetails: PropTypes.object,
    getLoyaltyDetails: PropTypes.func.isRequired,
    // eslint-disable-next-line
    getLoyaltyDetailsPending: PropTypes.bool.isRequired, // used by reduxActionSucceeded
    // eslint-disable-next-line
    getLoyaltyDetailsError: PropTypes.object, // used by reduxActionSucceeded
    // account cards
    accountCards: PropTypes.object,
    getAccountCards: PropTypes.func.isRequired,
    // eslint-disable-next-line
    getAccountCardsPending: PropTypes.bool.isRequired, // used by reduxActionSucceeded
    // eslint-disable-next-line
    getAccountCardsError: PropTypes.object, // used by reduxActionSucceeded
};

BonusOverviewPage.defaultProps = {
    accountCards: {},
    loyaltyDetails: null,
    getLoyaltyDetailsError: null,
    getAccountCardsError: null,
};

/* istanbul ignore next */
// eslint-disable-next-line
function mapStateToProps({ account, authentication }, { match }) {
    const { accountRef, loyaltyId, loyaltyType } = match.params;
    return {
        accountRef,
        loyaltyId,
        loyaltyType,
        // redux
        customerId: authentication.person.id,
        // loyalty details
        loyaltyDetails: account.loyaltyDetails,
        getLoyaltyDetailsPending: account.getLoyaltyDetailsPending,
        getLoyaltyDetailsError: account.getLoyaltyDetailsError,
        // account cards
        accountCards: account.accountCards,
        getAccountCardsPending: account.getAccountCardsPending,
        getAccountCardsError: account.getAccountCardsError,
    };
}

/* istanbul ignore next */
// eslint-disable-next-line
function mapDispatchToProps(dispatch) {
    return {
        // loyalty details
        getLoyaltyDetails: (customerId, accountRef, loyaltyType, loyaltyId) =>
            dispatch(getLoyaltyDetails(customerId, accountRef, loyaltyType.toUpperCase(), loyaltyId.toUpperCase())),
        // account cards
        getAccountCards: (customerId, accountRef) => dispatch(getAccountCards(customerId, accountRef)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BonusOverviewPage));
