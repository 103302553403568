import React from 'react';
import classNames from 'classnames';
import Slider from './Slider';
import { IconButton } from '../Clickable';
import './SliderPanel.scss';

interface SliderPanelProps {
    id?: string;
    className?: string;
    header: string;
    onChange?: Function;
    onAfterChange?: Function;
    min?: number;
    max?: number;
    defaultValue?: number;
    value?: number;
    step?: number;
    displayedValue?: string;
    labelLeft?: string;
    labelRight?: string;
    hideControls?: boolean;
    ariaLabel?: string;
    ariaLabelLeft?: string;
    ariaLabelRight?: string;
}

const SliderPanel = ({
    id = '',
    className = '',
    header,
    onChange = () => {},
    onAfterChange = () => {},
    min = 0,
    max = 100,
    defaultValue = 0,
    value = 0,
    displayedValue = '',
    step = 1,
    labelLeft,
    labelRight,
    ariaLabel = '',
    ariaLabelLeft = 'Minska värde',
    ariaLabelRight = 'Öka värde',
    hideControls = false,
}: SliderPanelProps) => {
    const classes = classNames({
        'ec-slider-panel': true,
        'hide-controls': hideControls,
        [className]: className,
    });

    const onIncrease = () => {
        const nextValue = value + step;
        const newValue = nextValue > max ? max : nextValue;
        onChange(newValue);
        onAfterChange(newValue);
    };

    const onDecrease = () => {
        const nextValue = value - step;
        const newValue = nextValue < min ? min : nextValue;
        onChange(newValue);
        onAfterChange(newValue);
    };

    const hasLabels = labelRight || labelLeft;

    const namePrefix = id || 'ec-slider';

    return (
        <div className={classes}>
            <h4>{header}</h4>
            <h5>{displayedValue}</h5>
            <div id={id} className="slider-controls">
                <IconButton
                    icon="icon-minus"
                    onClick={onDecrease}
                    name={`${namePrefix}-minus`}
                    ariaLabel={ariaLabelLeft}
                />
                <div className="slider-container">
                    <Slider
                        min={min}
                        max={max}
                        step={step}
                        defaultValue={defaultValue}
                        value={value || defaultValue}
                        onChange={onChange}
                        onAfterChange={onAfterChange}
                        aria-valuetext={displayedValue}
                        aria-label={ariaLabel || header}
                    />
                </div>
                <IconButton
                    icon="icon-plus"
                    onClick={onIncrease}
                    name={`${namePrefix}-plus`}
                    ariaLabel={ariaLabelRight}
                />
            </div>
            {hasLabels && (
                <div className="slider-labels">
                    <span className="label left">{labelLeft}</span>
                    <span className="label right">{labelRight}</span>
                </div>
            )}
        </div>
    );
};

export default SliderPanel;
