import { useEffect, useState } from 'react';
import { getConfig } from '../../../common/MyPagesConfig';

/**
 * Retrieves MyPagesConfig from sessionStorage
 * The hook is polling sessionStorage and returns MyPagesConfig
 * Returns new MyPagesConfig whenever a new one is found (locale changes)
 */
export const useMyPagesConfig = () => {
    const [config, setConfig] = useState();
    const [intervalId, setIntervalId] = useState();

    // Start new poller when a new config is detected
    useEffect(() => {
        const id = setInterval(() => {
            const newConfig = getConfig();
            // Update state only when a new config is detected
            if (!isConfigEqual(config, newConfig)) {
                setConfig(newConfig);
            }
        }, 300);

        setIntervalId(id);
    }, [config]);

    // Cleanup old poller when new poller is started
    useEffect(() => {
        return () => {
            clearInterval(intervalId);
        };
    }, [intervalId]);

    return config;
};

const isConfigEqual = (conf1, conf2) => {
    return JSON.stringify(conf1 || {}) === JSON.stringify(conf2 || {});
};
