/**
 * Determine if two unsorted arrays of objects are equal by comparing values of a given key.
 *
 * ex: same length, same values in "id", but different sorting.
 * arr1 = [{name:"alice", id:"123"}, {name:"bob", id:"456"}]
 * arr2 = [{name:"bob", id:"456"}, {name:"alice", id:"123"}]
 * ArraysOfObjectsAreEqualByKey(arr1, arr2, "id") // returns TRUE
 *
 * ex: same length, but different "id" values found.
 * arr1 = [{name:"alice", id:"123"}, {name:"bob", id:"456"}]
 * arr2 = [{name:"alice", id:"123"}, {name:"foo", id:"999"}]
 * ArraysOfObjectsAreEqualByKey(arr1, arr2, "id") // returns FALSE
 *
 * @param arr1 Array of objects
 * @param arr2 Array of objects
 * @param key Key in object to compare
 * @returns {boolean} Returns true if both arrays are equal by comparing the key
 */
export const ArraysOfObjectsAreEqualByKey = (arr1, arr2, key) => {
    if (arr1.length === 0 && arr2.length === 0) {
        return true;
    }

    if (arr1.length !== arr2.length) {
        return false;
    }

    let arraysAreEqual = true;

    for (let i = 0; i < arr2.length; i++) {
        let hasFoundSameKey = false;
        for (let j = 0; j < arr1.length; j++) {
            if (arr2[i][key] && arr1[j][key] && arr2[i][key] === arr1[j][key]) {
                hasFoundSameKey = true;
            }
        }
        if (!hasFoundSameKey) {
            arraysAreEqual = false;
            break;
        }
    }

    return arraysAreEqual;
};
