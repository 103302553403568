import PropTypes from 'prop-types';
import {
    Panel,
    PanelContent,
    LabelValue,
    IconButton,
    Button,
    PhoneNumberInput,
    ButtonGroup,
    Message,
} from '@ecster/components';
import { i18n } from '@ecster/i18n';
import React from 'react';
import './VerifyPhonePanel.scss';
import { validateMobilePhoneNumberIncludingOldFormatsFI } from '@ecster/validation';
import { connect } from 'react-redux';
import { reduxActionSucceeded, reduxActionFailed } from '@ecster/util';
import history from '../../../common/history';
import { getSecureActivationKey, dismissGetSecureActivationKeyError } from '../redux/actions';
import { updateCustomerContactInfo } from '../../customer/redux/updateCustomerContactInfo';

export class VerifyPhonePanel extends React.Component {
    activateUrl = '/secure-id/activate';

    state = {
        isSaveNumberError: null,
        isActivationKeyError: null,
        isEditing: false,
        phoneNumber: {
            countryCallingCode: '+358',
            number: '',
        },
        editedPhoneNumber: {
            countryCallingCode: '+358',
            number: '',
        },
    };

    phoneRef = React.createRef();

    componentDidMount() {
        const {
            phoneNumber: { number },
        } = this.props;

        if (number) {
            this.setState(state => ({
                phoneNumber: { countryCallingCode: state.phoneNumber.countryCallingCode, number },
            }));
        } else {
            this.onClickEdit();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { isEditing } = this.state;

        if (reduxActionSucceeded('getSecureActivationKey', prevProps, this.props)) {
            history.push(this.activateUrl);
        }
        if (reduxActionFailed('getSecureActivationKey', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isActivationKeyError: this.props.getSecureActivationKeyError });
        }

        // if we cant save number, show relevant error message
        if (reduxActionFailed('updateCustomerContactInfo', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ isSaveNumberError: this.props.updateCustomerContactInfoError });
        }

        // getCustomer is called in updateCustomerContactInfo action, after it succeeds.
        // update phoneNumber in state to display the number received from backend.
        if (reduxActionSucceeded('getCustomer', prevProps, this.props)) {
            const {
                phoneNumber: { number },
            } = this.props;
            this.setState(state => ({
                isEditing: false,
                phoneNumber: { countryCallingCode: state.phoneNumber.countryCallingCode, number },
                isSaveNumberError: null,
            }));
        }

        if (!prevState.isEditing && isEditing) {
            try {
                this.phoneRef.current.phoneRef.current.inputFieldRef.current.focus();
                // eslint-disable-next-line no-empty
            } catch (err) {}
        }
    }

    componentWillUnmount() {
        const { dismissGetSecureActivationKeyError } = this.props;
        dismissGetSecureActivationKeyError();
    }

    onChange(value) {
        this.setState({ editedPhoneNumber: value });
    }

    onClickEdit() {
        this.setState(state => ({ isEditing: !state.isEditing, editedPhoneNumber: state.phoneNumber }));
    }

    onClickCancel() {
        this.setState({ isEditing: false });
    }

    onClickSave() {
        const { editedPhoneNumber } = this.state;

        const { customerId, updateCustomerContactInfo } = this.props;

        if (this.phoneRef.current.doValidation()) {
            updateCustomerContactInfo(customerId, { phoneNumber: editedPhoneNumber });
        }
    }

    onClickActivate() {
        const { customerId, getSecureActivationKey } = this.props;
        const {
            phoneNumber: { countryCallingCode, number },
        } = this.state;

        const numberPayload = countryCallingCode + number;
        const data = { customerId, phoneNumber: numberPayload };

        if (number && validateMobilePhoneNumberIncludingOldFormatsFI(number)) {
            getSecureActivationKey(customerId, data);
        } else {
            this.setState({ isSaveNumberError: true });
            this.onClickEdit();
        }
    }

    render() {
        const { getSecureActivationKeyPending } = this.props;

        const {
            isEditing,
            phoneNumber: { countryCallingCode, number },
            editedPhoneNumber,
            isSaveNumberError,
            isActivationKeyError,
        } = this.state;

        return (
            <Panel className="verify-phone-panel" stretchInMobile>
                <PanelContent centered fullWidthPadded>
                    <h3 className="heading">{i18n('secure-id.verify.header')}</h3>
                    <p className="paragraph">{i18n('secure-id.verify.paragraph')}</p>

                    {!isEditing && (
                        <>
                            <p className="control ">{i18n('secure-id.verify.control')}</p>
                            <div className="label-value-wrapper">
                                <LabelValue
                                    label={i18n('secure-id.verify.mobile-nr')}
                                    value={countryCallingCode + number}
                                />
                                <IconButton
                                    green
                                    icon="icon-edit"
                                    onClick={() => this.onClickEdit()}
                                    ariaLabel={i18n('general.edit')}
                                />
                            </div>
                        </>
                    )}

                    {isEditing && (
                        <>
                            <PhoneNumberInput
                                ariaLabel={i18n('general.address.mobile')}
                                ariaLabelCountryCode={i18n('general.address.country-code')}
                                value={editedPhoneNumber}
                                onChange={value => this.onChange(value)}
                                language="fi"
                                countryCodeIsSelectable={false}
                                ref={this.phoneRef}
                                numberValidator={validateMobilePhoneNumberIncludingOldFormatsFI}
                                validationMessagePhone={i18n('general.validation.phone')}
                                required
                                validateOnBlur={false}
                            />

                            <ButtonGroup spaceBelow={false}>
                                <Button outline round onClick={() => this.onClickCancel()}>
                                    {i18n('general.cancel')}
                                </Button>
                                <Button round onClick={() => this.onClickSave()}>
                                    {i18n('general.save')}
                                </Button>
                            </ButtonGroup>
                        </>
                    )}

                    {!isEditing && (
                        <Button
                            onClick={() => this.onClickActivate()}
                            ariaLabel={i18n('secure-id.verify.button')}
                            round
                            green
                            disabled={getSecureActivationKeyPending}
                        >
                            {i18n('secure-id.verify.button')}
                        </Button>
                    )}

                    {isSaveNumberError && (
                        <Message
                            exclamation
                            purple
                            header={i18n('secure-id.verify.error.number.header')}
                            message={i18n('secure-id.verify.error.number.message')}
                        />
                    )}

                    {isActivationKeyError && (
                        <Message
                            exclamation
                            purple
                            header={i18n('secure-id.verify.error.activation-key.header')}
                            message={i18n('secure-id.verify.error.activation-key.message')}
                        />
                    )}
                </PanelContent>
            </Panel>
        );
    }
}

VerifyPhonePanel.propTypes = {
    phoneNumber: PropTypes.object.isRequired,
    customerId: PropTypes.string.isRequired,
    getSecureActivationKey: PropTypes.func.isRequired,
    getSecureActivationKeyError: PropTypes.bool.isRequired,
    getSecureActivationKeyPending: PropTypes.bool.isRequired,
    dismissGetSecureActivationKeyError: PropTypes.func.isRequired,
    updateCustomerContactInfo: PropTypes.func.isRequired,
    updateCustomerContactInfoPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    updateCustomerContactInfoError: PropTypes.bool.isRequired,
    getCustomerPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getCustomerInfoError: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
};

VerifyPhonePanel.defaultProps = {};

/* istanbul ignore next */
// eslint-disable-next-line
function mapStateToProps({ secureId, customer }) {
    return {
        getSecureActivationKeyError: secureId.getSecureActivationKeyError,
        getSecureActivationKeyPending: secureId.getSecureActivationKeyPending,
        updateCustomerContactInfoPending: customer.updateCustomerContactInfoPending,
        updateCustomerContactInfoError: customer.updateCustomerContactInfoError,
        getCustomerPending: customer.getCustomerPending,
        getCustomerInfoError: customer.getCustomerInfoError,
    };
}

/* istanbul ignore next */
// eslint-disable-next-line
function mapDispatchToProps(dispatch) {
    return {
        updateCustomerContactInfo: (customerId, data) => dispatch(updateCustomerContactInfo(customerId, true, data)),
        getSecureActivationKey: (customerId, data) => dispatch(getSecureActivationKey(customerId, data)),
        dismissGetSecureActivationKeyError: () => dispatch(dismissGetSecureActivationKeyError()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhonePanel);
