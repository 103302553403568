import React from 'react';
import { i18n } from '@ecster/i18n';
import PublicPageTemplate from '../common/templates/PublicPageTemplate';
import NoSupportPanel from '../common/NoSupportPanel';
import { isSE } from '../../common/country';
import { APP_STORE_URL_FI, APP_STORE_URL_SE, GOOGLE_PLAY_URL_FI, GOOGLE_PLAY_URL_SE } from './constants';

const NoSupportPage = () => {
    const iosUrl = isSE() ? APP_STORE_URL_SE : APP_STORE_URL_FI;
    const androidUrl = isSE() ? GOOGLE_PLAY_URL_SE : GOOGLE_PLAY_URL_FI;

    return (
        <PublicPageTemplate>
            <div className="home-no-support-page">
                <NoSupportPanel
                    forceShow
                    header={i18n('home.no-support.header')}
                    content={i18n('home.no-support.content')}
                    iosUrl={iosUrl}
                    androidUrl={androidUrl}
                />
            </div>
        </PublicPageTemplate>
    );
};

export default NoSupportPage;
