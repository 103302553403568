import { LabelValue, Panel, ResponsivePanel } from '@ecster/components';
import PropTypes from 'prop-types';
import { i18n } from '@ecster/i18n';
import React from 'react';
import classNames from 'classnames';
import './Header.scss';
import { secureAppIcon } from '@ecster/icons/secure';

const Header = ({ isDeviceEnrolled, hasError }) => {
    const labelValueClasses = classNames({
        'is-enrolled': isDeviceEnrolled,
    });

    const getStatusText = () =>
        hasError
            ? ''
            : i18n(isDeviceEnrolled ? 'secure-id.overview.header.active' : 'secure-id.overview.header.not-active');

    return (
        <Panel greenBG className="overview-header">
            <ResponsivePanel verticalLines greenLines alignItems="center">
                <div className="overview-header-title">
                    {isDeviceEnrolled && <img src={secureAppIcon} className="sca-icon" alt="" />}
                    <h3>{i18n('secure-id.overview.page-header')}</h3>
                </div>
                <div className="overview-header-status">
                    <LabelValue
                        label={i18n('secure-id.overview.header.status')}
                        value={getStatusText()}
                        className={labelValueClasses}
                    />
                </div>
            </ResponsivePanel>
        </Panel>
    );
};

Header.propTypes = {
    isDeviceEnrolled: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
};

export default Header;
