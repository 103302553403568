import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const isValidDateTime = dt =>
    // YYYY-MM-dd HH:mm:ss or
    // YYYY-MM-dd HH:mm
    dt?.match(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]) ([01]\d|2[0-3]):([0-5]\d)(?::([0-5]\d))?$/);

//
// Hide child elements after a given date and time
//
export const Hide = ({ children, after }) => {
    const now = dayjs();
    if (after && !isValidDateTime(after)) return null;
    if (after && now.isAfter(after)) return null;
    if (after && now.isBefore(after)) return <>{children}</>;
};

Hide.propTypes = {
    children: PropTypes.node.isRequired,
    after: PropTypes.string,
};
