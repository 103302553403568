import React from 'react';
import { i18n } from '@ecster/i18n';
import NoSupportPanel from '../common/NoSupportPanel';
import PublicPageTemplate from '../common/templates/PublicPageTemplate';
import { APP_STORE_URL, GOOGLE_PLAY_URL } from './constants';

export default class NoSupportPage extends React.Component {
    state = {};

    render() {
        return (
            <PublicPageTemplate>
                <div className="secure-id-no-support-page">
                    <NoSupportPanel
                        forceShow
                        header={i18n('secure-id.no-support.no-support-panel.header')}
                        content={i18n('secure-id.no-support.no-support-panel.content')}
                        iosUrl={APP_STORE_URL}
                        androidUrl={GOOGLE_PLAY_URL}
                    />
                </div>
            </PublicPageTemplate>
        );
    }
}
