import React from 'react';

import i18next, { InitOptions, Resource } from 'i18next';
import i18nextBE from 'i18next-http-backend';
import { checksum } from './checksum';

// texts for confirm external link in app mode
const i18n = {
    sv: {
        confirm: {
            msg: 'Sidan öppnas i webbläsaren - du lämnar appen',
            ok: 'Ok',
            cancel: 'Avbryt',
        },
    },
    en: {
        confirm: {
            msg: "The page opens in the browser - you're leaving the app",
            ok: 'Ok',
            cancel: 'Cancel',
        },
    },
    fi: {
        confirm: {
            msg: 'Sivu avautuu selaimessa - olet poistumassa sovelluksesta',
            ok: 'Jatka',
            cancel: 'Peruuta',
        },
    },
};

const defaultLang = 'en';
/**
 * Init i18next with all language data in a js object.
 *
 * See example here: https://www.i18next.com/how-to/add-or-load-translations
 *
 * Language data should be organized under namespaces, the easiest way is to wrap all keys under a "default" key
 * and specify { defaultNS: "default" } in optionalConfig
 *
 * @param data Required - language resources in a js object
 * @param language Required - language key used for loading language file(s)
 * @param country Optional - country key used for loading language file(s)
 * @param fallbackLang Optional - override default fallback language (en)
 * @param optionalConfig Optional - additional i18next.init config options
 * @returns a promise
 */
export const initWithData = (
    data: Resource,
    language: string,
    country?: string | undefined,
    fallbackLang = defaultLang,
    optionalConfig: InitOptions = {}
) => {
    if (!language) {
        throw new Error('Missing language');
    }

    const locale = country ? `${language}-${country}` : language;

    return new Promise(resolve => {
        i18next.init(
            {
                lng: locale,
                resources: data,
                fallbackLng: fallbackLang,
                saveMissing: true,
                missingKeyHandler: (lng, ns, key, fallbackValue) => {
                    console.warn('Missing language key!', { lng, ns, key, fallbackValue });
                },
                ...optionalConfig,
            },
            (err, data) => {
                if (err) {
                    console.error('i18next.init error: ', err);
                }
                resolve(data);
            }
        );
    });
};

const getCacheBust = () => {
    // @ts-expect-error global config
    const { EcsterConfig: conf } = window;
    // there's a version and it has at least one digit
    return conf && conf.version && conf.version.match(/[\d]+/) ? `?v=${conf.version}` : '';
};

/**
 * Init i18next with files in basePath directory
 *
 * @param basePath Required - relative path to where the application language files are stored
 * @param language Required - language key used for loading language file(s)
 * @param country Optional - country key used for loading language file(s)
 * @param fallbackLang Optional - override default fallback language (en)
 * @param optionalConfig Optional - additional i18next.init config options
 * @returns a Promise/A+ object
 */
export const init = (
    basePath: string,
    language: string,
    country?: string | undefined,
    fallbackLang = defaultLang,
    optionalConfig: InitOptions = {}
) => {
    if (!basePath) {
        throw new Error('Missing base path');
    }
    if (!language) {
        throw new Error('Missing language');
    }

    const locale = country ? `${language}-${country}` : language;

    const i18nextOptions: InitOptions = {
        lng: locale,
        saveMissing: true,
        missingKeyHandler: (lng, ns, key, fallbackValue) => {
            console.warn('Missing language key!', { lng, ns, key, fallbackValue });
        },
        backend: {
            loadPath: `${basePath}/{{lng}}.json${getCacheBust()}`,
            crossDomain: false,
        },
        fallbackLng: fallbackLang,
        // @ts-expect-error may not be working
        shortcutFunction: 'defaultValue', // allow for default values as a second string parameter
        ...optionalConfig,
    };

    return new Promise(resolve => {
        i18next.use(i18nextBE).init(i18nextOptions, (err, data) => {
            if (err) {
                console.error('i18next.init error: ', err);
            }
            resolve(data);
        });
    });
};

const functionOnClick = 'ecsterI18nLinkOnClick';
const functionFollowLink = 'ecsterI18nLinkFollowLink';
// @ts-expect-error move this out from i18n
window[functionOnClick] = (e, target, href, gaLabel) => {
    // app and external link?
    if (
        // @ts-expect-error move this out from i18n
        window.cordova &&
        href &&
        target === '_blank' &&
        // @ts-expect-error move this out from i18n
        window.cordova.InAppBrowser &&
        // @ts-expect-error move this out from i18n
        window.navigator.notification &&
        href.startsWith('http')
    ) {
        e.preventDefault();
        const lang = window.navigator.language || 'sv'; // sv if not set
        // @ts-expect-error move this out from i18n
        const texts = i18n[lang.substring(0, 2)] || i18n.sv; // sv if unsupported language
        const title = href.replace(/https?:\/\//, '').replace(/\/.*/, ''); // domain only

        // @ts-expect-error move this out from i18n
        const onConfirm = btnIndex => {
            if (btnIndex === 2) {
                // @ts-expect-error move this out from i18n
                window[functionFollowLink](href, gaLabel, true);
            }
        };

        // @ts-expect-error move this out from i18n
        window.navigator.notification.confirm(
            texts.confirm.msg, // message
            onConfirm,
            title,
            [texts.confirm.cancel, texts.confirm.ok]
        );
        return false;
    }

    // @ts-expect-error move this out from i18n
    if (window.EcsterConfig.isApp && href.indexOf('tel:') === 0) {
        // @ts-expect-error move this out from i18n
        window.EcsterConfig.isTelephoneLink = true;
    }

    // not an app or not an external link, just follow link
    // @ts-expect-error move this out from i18n
    window[functionFollowLink](href, gaLabel);
    return true;
};

// @ts-expect-error move this out from i18n
window[functionFollowLink] = (href, gaLabel, isApp) => {
    if (isApp) {
        // @ts-expect-error move this out from i18n
        window.cordova.InAppBrowser.open(href, '_system'); // open in device browser
    }
};

// parse a string value and convert [icon ...] and [link ...] parts
const parseString = (value: string): string => {
    //  do regexp replace on strings with at least one '['
    if (value?.indexOf('[') > -1) {
        // is there a GA event spec in string? ga:xyz
        const hasGA = value.match(/\[link.* ga:/);

        return (
            // replace icon shorthands, e.g. "... [icon-user] ..."
            value
                // blank in regex for icon-... allows for additional css classes
                .replace(/\[(icon-[0-9a-z- ]+)\]/g, "<i class='$1'></i>") // DON'T replace with shorthand tag: <i ... />, it breaks ko binding!
                // replace link shorthands, [link ... ] or [link-new-win ... ]
                //   e.g. "... [link https://www.ecster.se 'Click here for more info'] ..."
                //   or   "... [link https://www.ecster.se 'Click here for more info' ga:my-css-class] ..."
                //   or   "... [link-new-win https:// ... ] ..."

                // match and replace  [link(spaces)(the link)(spaces)'(link text)'(spaces)(optional GA event category, ga:cat)(spaces)]
                .replace(
                    /\[link +([^ ]+) +'([^\]]*)'(?: +ga:([^\]^ ]*))? *\]/g,
                    hasGA
                        ? `<a href="$1" class="ec-i18-link $3" onclick="window.${functionOnClick}(event, '','$1','$3')">$2</a>`
                        : '<a href="$1" class="ec-i18-link" >$2</a>'
                )
                // match and replace [link-new-win ... etc
                .replace(
                    /\[link-new-win +([^ ]+) +'([^\]]*)'(?: +ga:([^\]^ ]*))? *\]/g,
                    hasGA
                        ? `<a href="$1" class="ec-i18-link $3" rel="noopener" target="_blank" onclick="window.${functionOnClick}(event, '_blank','$1','$3')">$2</a>`
                        : `<a href="$1" class="ec-i18-link" rel="noopener" target="_blank" onclick="window.${functionOnClick}(event, '_blank','$1')">$2</a>`
                )
        );
    }

    return value;
};
/**
 * Parse and process a string or array value the same way as a looked up value is parsed and processed
 *
 * Used if lookup is done "outside of" this module
 *
 * @param options - same as in getText
 * @param value - the value to parse and process
 */
export const parseValue = (options: any | undefined, value: any): string | string[] | object => {
    let parsedValue;

    if (Array.isArray(value)) {
        parsedValue = value.map(thisValue => {
            const val = parseString(thisValue);
            if (typeof options?.wrapper === 'object' && options.wrapper.tag) {
                const Tag = options.wrapper.tag;
                const { props } = options.wrapper;
                return options.wrapper.dangerouslySetInnerHTML ? (
                    <Tag key={checksum(val)} {...props} dangerouslySetInnerHTML={{ __html: val }} />
                ) : (
                    <Tag key={checksum(val)} {...props}>
                        {val}
                    </Tag>
                );
            }
            return val;
        });
    } else if (options?.danger) {
        const val = parseString(value);
        const Tag = options.wrapper.tag;
        const { props } = options.wrapper;
        parsedValue = <Tag {...props} dangerouslySetInnerHTML={{ __html: val }} />;
    } else {
        parsedValue = parseString(value);
    }

    return parsedValue;
};

const lookup = (key: string, options?: any): string | string[] | object => {
    const value = i18next.t(key, options);

    if (Array.isArray(value) && value.length > 0) {
        // Array data - "key": [ "a", "b" ]
        //   i18next returns: [ [ "a", "b" ] ]
        //   format as proved by debugging
        if (Array.isArray(value[0])) {
            return parseValue(options, value[0]);
        }

        // Array data - "key": [ "a", "b" ]
        //   i18next returns: [ "a", "b" ]
        //   format as documented here: https://www.i18next.com/translation-function/objects-and-arrays
        if (typeof value[0] === 'string') {
            return parseValue(options, value);
        }

        // Array of objects - "key": [ { "a": "A", ... }, { ... } ]
        //   return value without parsing
        if (typeof value[0] === 'object') {
            return value;
        }
    }

    // Object data - "key": { "a": "A", "b": "B" }
    //   return value without parsing
    if (typeof value === 'object') {
        return value;
    }

    return parseValue(options, value);
};

/**
 * Lookup a translated string value
 *
 * @param key
 * @param param1 tag name OR options (optional) {lng: 'en', lngs: ['de', 'fr'], keySeparator: '#', defaultValue: 'fallback from defaultValue'}}
 *   lng provide language manually
 *   lngs supports fallback language look up
 *   keySeparator is a separator for nested keys
 *   defaultValue is a value used if lookup fails
 * None of the options are required
 * @param param2 if param2 is a jsx tag name
 * @returns a String value for the provided key or the key itself if no translation found
 */
export const getText = (key: string, param1?: string | any, param2?: any): string | string[] | object => {
    const options = typeof param1 === 'string' ? param2 : param1;
    const danger = options && options.danger;
    const tag = typeof param1 === 'string' ? param1 : danger ? 'span' : null;

    return tag
        ? lookup(key, { ...options, returnObjects: true, wrapper: { tag, dangerouslySetInnerHTML: true } })
        : lookup(key, options);
};
