import React from 'react';
import {
    ButtonGroup,
    Button,
    Select,
    Option,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from '@ecster/components';

import { setBaseUrl } from '@ecster/net';

import { isFI } from '../../../common/country';
import { createBackendCookie } from '../../../common/backendCookie';

const { EcsterConfig: conf } = window;

const urls = [
    ['<relative>', 'relative'],
    ['PR', 'https://secure.ecster.se'],
    ['PR LABS', 'https://labs.ecster.se'],
    ['FT', 'https://secure.ft.ecster.se'],
    ['FT1', 'https://secure1.ft.ecster.se'],
    ['FT2', 'https://secure2.ft.ecster.se'],
    ['FT3', 'https://secure3.ft.ecster.se'],
    ['FT4', 'https://secure4.ft.ecster.se'],
    ['FT5', 'https://secure5.ft.ecster.se'],
    ['LABS', 'https://labs.ft.ecster.se'],
    ['LABS 1', 'https://labs1.ft.ecster.se'],
    ['LABS 2', 'https://labs2.ft.ecster.se'],
    ['LABS 3', 'https://labs3.ft.ecster.se'],
    ['LABS 4', 'https://labs4.ft.ecster.se'],
    ['LABS 5', 'https://labs5.ft.ecster.se'],
    ['ST', 'https://secure.st.ecster.se'],
    ['PT', 'https://secure.pt.ecster.se'],
];

export default class SelectBackend extends React.Component {
    static propTypes = {};

    static defaultProps = {};

    state = {
        isOpen: false,
        baseURL: conf.baseURL || 'relative',
    };

    openDialog = () => {
        this.setState({ isOpen: true });
    };

    closeDialog = () => {
        this.setState({ isOpen: false });
    };

    onSelect = e => {
        const {
            target: { value },
        } = e;
        this.setState({ baseURL: value });
        window.scrollBy(0, 0);
    };

    saveAndCloseDialog = () => {
        const { baseURL } = this.state;
        conf.baseURL = baseURL === 'relative' ? '' : baseURL;
        setBaseUrl(conf.baseURL);

        if (isFI()) {
            // save value - setBaseUrl and conf again after Tupas redirect
            createBackendCookie(conf.baseURL);
        }
        this.closeDialog();
    };

    render() {
        const { isOpen, baseURL } = this.state;

        return (
            <div className="authentication-select-backend">
                <ButtonGroup>
                    <Button outline small blue onClick={this.openDialog}>
                        Select backend
                    </Button>
                </ButtonGroup>
                <Dialog open={isOpen} onRequestClose={this.closeDialog}>
                    <DialogHeader>Select backend</DialogHeader>
                    <DialogBody>
                        <div className="plr-10x ptb-6x">
                            <Select onChange={this.onSelect} value={baseURL}>
                                {urls.map(url => (
                                    <Option key={url[0]} label={url[0]} value={url[1]} />
                                ))}
                            </Select>
                        </div>
                    </DialogBody>
                    <DialogFooter>
                        <ButtonGroup alignCenter>
                            <Button round transparent blue onClick={this.closeDialog}>
                                Cancel
                            </Button>
                            <Button outline blue onClick={this.saveAndCloseDialog}>
                                Go
                            </Button>
                        </ButtonGroup>
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}
