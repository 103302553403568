import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './Icon.scss';

interface IconProps {
    /** font icon class */
    icon?: string;

    /** Alternative to icon. A (short) string, img, svg etc */
    children?: ReactNode;

    /** a beige icon */
    beige?: boolean;

    /** a black icon */
    black?: boolean;

    /** a blue icon */
    blue?: boolean;

    /** a gray icon */
    gray?: boolean;

    /** a green icon */
    green?: boolean;

    /** a lightGray icon */
    lightGray?: boolean;

    /** a purple icon */
    purple?: boolean;

    /** a red icon */
    red?: boolean;

    /** a small icon, same font size as default size, smaller circle with 'invert' */
    small?: boolean;

    /** a large icon */
    large?: boolean;

    /** an x-large icon */
    xLarge?: boolean;

    /** invert colors, a white icon on top of a colored circle */
    invert?: boolean;
}

const Icon = ({
    icon,
    children,
    invert,
    beige,
    black,
    blue,
    green,
    gray,
    lightGray,
    purple,
    red,
    ...rest
}: IconProps) => {
    const classes = classNames({
        'ec-icon': true,
        'is-text': typeof children === 'string',
        'is-markup': children && typeof children !== 'string',
        green: green && !beige && !blue && !black && !gray && !lightGray && !purple && !red,
        beige,
        black,
        blue,
        gray,
        lightGray,
        purple,
        red,
        invert: invert || children,
        [icon]: icon || (!children && 'icon-info'),
        ...rest,
    });

    return <i className={classes}>{!icon && <div className="ec-icon-ctr">{children}</div>}</i>;
};

Icon.defaultProps = {
    icon: '',
    children: null,

    beige: false,
    black: false,
    blue: false,
    gray: false,
    green: true,
    lightGray: false,
    purple: false,
    red: false,

    small: false,
    large: false,
    xLarge: false,

    invert: false,
};

export default Icon;
