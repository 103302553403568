import { get } from '@ecster/net/v2';
import {
    SECURE_ID_GET_ENROLLED_DEVICES_BEGIN,
    SECURE_ID_GET_ENROLLED_DEVICES_SUCCESS,
    SECURE_ID_GET_ENROLLED_DEVICES_FAILURE,
    SECURE_ID_GET_ENROLLED_DEVICES_DISMISS_ERROR,
} from './constants';
import { reportError } from '../../../common/reportError';

import { GET_ENROLLED_DEVICES_URL } from './urls';

const CLEAR_GET_ENROLLED_DEVICES_STATE = 'CLEAR_GET_ENROLLED_DEVICES_STATE';

export const getEnrolledDevices = customerId => async dispatch => {
    dispatch({
        type: SECURE_ID_GET_ENROLLED_DEVICES_BEGIN,
    });

    try {
        const res = await get(GET_ENROLLED_DEVICES_URL(customerId));

        dispatch({
            type: SECURE_ID_GET_ENROLLED_DEVICES_SUCCESS,
            data: res.response,
        });
    } catch (err) {
        reportError(err, 'getEnrolledDevices');
        dispatch({
            type: SECURE_ID_GET_ENROLLED_DEVICES_FAILURE,
            data: {
                error: {
                    message: err,
                    action: SECURE_ID_GET_ENROLLED_DEVICES_FAILURE,
                },
            },
        });
    }
};

export const dismissGetEnrolledDevicesError = () => ({ type: SECURE_ID_GET_ENROLLED_DEVICES_DISMISS_ERROR });

export const clearGetEnrolledDevicesState = () => ({ type: CLEAR_GET_ENROLLED_DEVICES_STATE });

// don't ES6 this one, rekit studio gets lost /joli44
// eslint-disable-next-line
export function reducer(state, action) {
    let isDeviceEnrolled;

    switch (action.type) {
        case SECURE_ID_GET_ENROLLED_DEVICES_BEGIN:
            return {
                ...state,
                getEnrolledDevicesPending: true,
                getEnrolledDevicesError: null,
            };

        case SECURE_ID_GET_ENROLLED_DEVICES_SUCCESS:
            isDeviceEnrolled = action.data.enrolled;

            return {
                ...state,
                enrolledDevices: action.data,
                isDeviceEnrolled,
                getEnrolledDevicesPending: false,
                getEnrolledDevicesError: null,
            };

        case CLEAR_GET_ENROLLED_DEVICES_STATE:
            return {
                ...state,
                enrolledDevices: null,
                isDeviceEnrolled: null,
            };

        case SECURE_ID_GET_ENROLLED_DEVICES_FAILURE:
            return {
                ...state,
                getEnrolledDevicesPending: false,
                getEnrolledDevicesError: action.data.error,
            };

        case SECURE_ID_GET_ENROLLED_DEVICES_DISMISS_ERROR:
            return {
                ...state,
                getEnrolledDevicesError: null,
            };

        default:
            return state;
    }
}
