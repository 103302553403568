import React from 'react';
import PropTypes from 'prop-types';

import { LabelValue, CopyToClipboardButton } from '@ecster/components';
import { i18n } from '@ecster/i18n';

export const AnyValue = ({ value, label, valueForCopy }) => (
    <LabelValue
        label={label}
        value={
            <CopyToClipboardButton
                noIcon
                tooltip={i18n('invoice.invoice-values.tooltip.copy-any-value', { label })}
                tooltipSuccess={i18n('invoice.invoice-values.tooltip.copy-success')}
                value={valueForCopy || value}
            >
                {value}
            </CopyToClipboardButton>
        }
    />
);

AnyValue.propTypes = {
    value: PropTypes.any.isRequired,
    valueForCopy: PropTypes.string,
    label: PropTypes.string.isRequired,
};

AnyValue.defaultProps = {
    valueForCopy: '',
};
