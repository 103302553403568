import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@ecster/i18n';
import { CopyToClipboardButton, Link, Panel, PanelContent } from '@ecster/components';
import { hasLengthMoreThanZero } from '../../../common/util/has-length-more-than-zero';
import { isNumber } from '../../../common/util/is-number';
import { formatAmount } from '../../../common/util/format-amount';
import { formatNumber } from '../../../common/util/format-number';
import { isFI, isSE } from '../../../common/country';
import { InfoItem } from './InfoItem';
import { Hide } from '../../../common/Hide.jsx';

export default class AccountInformationTab extends React.Component {
    render() {
        const {
            accountCard,
            terms: {
                pinCode,
                interestRate,
                depositRate,
                termsPDFURL,
                adminFee,
                withdrawalFeePercent,
                withdrawalFee,
                currencyExchangeFeeRate,
                yearlyFee,
                cardFee,
                atmWithdrawalFee,
                accountWithdrawalFee,
                amortisationFreeMonthFee,
                lateFee,
                overdraft,
                agreementPDFURL,
            },
        } = this.props;
        const { brickId } = accountCard;
        const hasCard = hasLengthMoreThanZero(brickId);

        const hasWithdrawalFeePercent = isNumber(withdrawalFeePercent) && withdrawalFeePercent > 0;

        return (
            <Panel className="account-info-panel" stretchInMobile paddingAll="small">
                <h2 className="h3">{i18n('account.account-information.information.header')}</h2>

                <div>
                    <PanelContent fullWidth horizontalLines>
                        <div>
                            <Hide after="2024-05-01 00:00">
                                {isSE() && pinCode && (
                                    <InfoItem
                                        value={
                                            <CopyToClipboardButton
                                                id="copy-pin-btn"
                                                noIcon
                                                value={pinCode}
                                                tooltip={i18n(
                                                    'account.account-information.information.tooltip.pin-code'
                                                )}
                                                tooltipSuccess={i18n(
                                                    'account.account-information.information.tooltip.copied'
                                                )}
                                            >
                                                {pinCode}
                                            </CopyToClipboardButton>
                                        }
                                        label={i18n('account.account-information.information.account-pin-code')}
                                    />
                                )}
                            </Hide>

                            <InfoItem
                                value={`${formatNumber(interestRate, { decimals: 2 })} %`}
                                label={i18n('account.account-information.information.interest-rate-label')}
                                description={i18n('account.account-information.information.interest-rate-description')}
                            />
                            {isSE() && (
                                <>
                                    {depositRate > 0 && (
                                        <InfoItem
                                            value={`${formatNumber(depositRate, { decimals: 2 })} %`}
                                            label={i18n('account.account-information.information.se.deposit-rate')}
                                            description={
                                                <span>
                                                    {i18n(
                                                        'account.account-information.information.se.deposit-rate-description'
                                                    )}{' '}
                                                    <Link
                                                        target="_blank"
                                                        href={termsPDFURL}
                                                        id="open-account-terms-pdf-link1"
                                                        rel="noreferrer"
                                                    >
                                                        pdf
                                                    </Link>
                                                </span>
                                            }
                                        />
                                    )}

                                    <InfoItem
                                        value={formatAmount(adminFee)}
                                        label={i18n('account.account-information.information.se.admin-fee')}
                                        description={i18n(
                                            'account.account-information.information.se.admin-fee-description'
                                        )}
                                    />

                                    {hasWithdrawalFeePercent ? (
                                        <InfoItem
                                            value={i18n(
                                                'account.account-information.information.se.withdrawal-fee-value',
                                                {
                                                    percentValue: `${formatNumber(withdrawalFeePercent, {
                                                        decimals: 2,
                                                    })} %`,
                                                    feeValue: formatAmount(withdrawalFee),
                                                }
                                            )}
                                            label={i18n('account.account-information.information.se.withdrawal-fee')}
                                            description={i18n(
                                                'account.account-information.information.se.withdrawal-fee-description'
                                            )}
                                        />
                                    ) : (
                                        <InfoItem
                                            value={formatAmount(withdrawalFee)}
                                            label={i18n('account.account-information.information.se.withdrawal-fee')}
                                            description={i18n(
                                                'account.account-information.information.se.withdrawal-fee-description'
                                            )}
                                        />
                                    )}
                                    {isNumber(currencyExchangeFeeRate) && (
                                        <InfoItem
                                            value={`${formatNumber(currencyExchangeFeeRate, {
                                                decimals: 2,
                                            })} %`}
                                            label={i18n('account.account-information.information.se.exchange-fee')}
                                            description={i18n(
                                                'account.account-information.information.se.exchange-fee-description'
                                            )}
                                        />
                                    )}

                                    <InfoItem
                                        value={formatAmount(yearlyFee)}
                                        label={i18n('account.account-information.information.se.yearly-fee')}
                                        description={i18n(
                                            'account.account-information.information.se.yearly-fee-description'
                                        )}
                                    />
                                </>
                            )}

                            {isFI() && (
                                <>
                                    {hasCard && isNumber(atmWithdrawalFee) && (
                                        <InfoItem
                                            value={formatAmount(atmWithdrawalFee)}
                                            label={i18n(
                                                'account.account-information.information.fi.atm-withdrawal-fee'
                                            )}
                                            description={i18n(
                                                'account.account-information.information.fi.atm-withdrawal-fee-description'
                                            )}
                                        />
                                    )}
                                    {isNumber(accountWithdrawalFee) && (
                                        <InfoItem
                                            value={formatAmount(accountWithdrawalFee)}
                                            label={i18n(
                                                'account.account-information.information.fi.account-withdrawal-fee'
                                            )}
                                            description={i18n(
                                                'account.account-information.information.fi.account-withdrawal-fee-description'
                                            )}
                                        />
                                    )}
                                    {isNumber(amortisationFreeMonthFee) && (
                                        <InfoItem
                                            value={formatAmount(amortisationFreeMonthFee)}
                                            label={i18n(
                                                'account.account-information.information.fi.amortisation-free-month-fee'
                                            )}
                                            description={i18n(
                                                'account.account-information.information.fi.amortisation-free-month-fee-description'
                                            )}
                                        />
                                    )}
                                </>
                            )}

                            {hasCard && (
                                <InfoItem
                                    value={formatAmount(cardFee)}
                                    label={i18n('account.account-information.information.card-fee')}
                                    description={i18n('account.account-information.information.card-fee-description')}
                                />
                            )}

                            {isNumber(lateFee) && (
                                <InfoItem
                                    value={formatAmount(lateFee)}
                                    label={i18n('account.account-information.information.late-fee')}
                                    description={i18n('account.account-information.information.late-fee-description')}
                                />
                            )}
                            {isNumber(overdraft) && (
                                <InfoItem
                                    value={formatAmount(overdraft)}
                                    label={i18n('account.account-information.information.overdraft-fee')}
                                    description={i18n(
                                        'account.account-information.information.overdraft-fee-description'
                                    )}
                                />
                            )}
                            {isSE() && (
                                <p className="pt-5 pl-6">
                                    {i18n('account.account-information.information.agreement-info', { danger: true })}
                                </p>
                            )}
                        </div>
                    </PanelContent>
                </div>
            </Panel>
        );
    }
}

/* istanbul ignore next */
// eslint-disable-next-line

AccountInformationTab.propTypes = {
    // ajax action and its async states
    accountCard: PropTypes.shape(),
    terms: PropTypes.object,
};

AccountInformationTab.defaultProps = {
    accountCard: {},
    terms: null,
};
