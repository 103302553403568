import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GeneralErrorMessagePanel, Message, Spinner } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { renderThisCountry } from '../common/i18n/renderThisCountry';
import { createSession, deleteSession, dismissCreateSessionError, getSession, resetLoginState } from './redux/actions';
import { LoginFormFI, LoginFormSENETS, LoginFormSE } from './index';
import { getCustomerProperty } from '../customer/redux/getCustomerProperty';
import { LoginResponsivePanel } from './components/LoginResponsivePanel';
import { getDebugInfo } from '../../common/util/getDebugInfo';

import { GatewayContext } from '../../context/gateway-context';
import { isSE } from '../../common/country';

class LoginForm extends Component {
    static contextType = GatewayContext;

    renderSE() {
        const { createSessionError, dismissCreateSessionError, ...rest } = this.props;

        const { value } = this.context;

        const { isNewGui, isLoading } = value;
        return isLoading ? (
            <Spinner isVisible isCenterX id="login--gui-spinner" />
        ) : isNewGui ? (
            this.renderForm(<LoginFormSENETS {...rest} />)
        ) : (
            this.renderForm(<LoginFormSE {...rest} />)
        );
    }

    renderFI() {
        const { createSessionError, dismissCreateSessionError, ...rest } = this.props;

        return this.renderForm(<LoginFormFI {...rest} />);
    }

    renderForm(loginForm) {
        const { createSessionError, getCustomerPropertyLoginMypagesError } = this.props;

        if (createSessionError || getCustomerPropertyLoginMypagesError) {
            return this.renderSessionError();
        }

        return <>{loginForm}</>;
    }

    renderSessionError() {
        const { createSessionError, getCustomerPropertyLoginMypagesError, clearState } = this.props;

        const error = createSessionError || getCustomerPropertyLoginMypagesError;

        const errorMessagePanel = (
            <GeneralErrorMessagePanel
                header={i18n('general.error.general.header')}
                body={i18n('general.error.general.body')}
                error={error}
                isFatal
                dismissError={clearState}
                onButtonClick={clearState}
                buttonText={i18n('general.back')}
                isLink
                getAppInfo={getDebugInfo}
            />
        );

        return isSE() ? (
            errorMessagePanel
        ) : (
            <LoginResponsivePanel id="login-page-panel">{errorMessagePanel}</LoginResponsivePanel>
        );
    }

    renderConfigError(applicationCountry) {
        return (
            this && ( // avoid eslint static
                <Message
                    purple
                    header="Oops... something is wrong!"
                    message={`Unknown country configuration ${applicationCountry}`}
                />
            )
        );
    }

    render() {
        return renderThisCountry(this);
    }
}

LoginForm.propTypes = {
    resetLoginState: PropTypes.func.isRequired,
    person: PropTypes.object,

    loginProgress: PropTypes.object.isRequired,
    loginStatus: PropTypes.object.isRequired,

    createSession: PropTypes.func.isRequired,
    dismissCreateSessionError: PropTypes.func.isRequired,

    createSessionPending: PropTypes.bool.isRequired,
    createSessionError: PropTypes.object,

    getSession: PropTypes.func.isRequired,
    getSessionPending: PropTypes.bool.isRequired,
    getSessionError: PropTypes.object,

    deleteSession: PropTypes.func.isRequired,
    clearState: PropTypes.func.isRequired,

    getCustomerProperty: PropTypes.func.isRequired,
    getCustomerPropertyLoginMypagesError: PropTypes.object,

    userIsCustomer: PropTypes.bool,
    isReviewMode: PropTypes.bool.isRequired,
};

LoginForm.defaultProps = {
    person: undefined,
    userIsCustomer: undefined,
    createSessionError: undefined,
    getSessionError: undefined,
    getCustomerPropertyLoginMypagesError: null,
};

/* istanbul ignore next */
function mapStateToProps({
    authentication: {
        createSessionError,
        createSessionPending,
        getSessionError,
        getSessionPending,
        loginProgress,
        loginStatus,
        person,
    },
    common: { isReviewMode },
    customer: { LOGIN_MYPAGES, getCustomerPropertyLoginMypagesError },
}) {
    return {
        isReviewMode,
        loginProgress,
        loginStatus,
        person,
        userIsCustomer: LOGIN_MYPAGES,
        getCustomerPropertyLoginMypagesError,
        createSessionPending,
        createSessionError,
        getSessionPending,
        getSessionError,
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        createSession: (data, canaryValue) => {
            dispatch(createSession(data, canaryValue));
        },
        deleteSession: key => {
            dispatch(deleteSession(key));
        },
        dismissCreateSessionError: () => {
            dispatch(dismissCreateSessionError());
        },
        resetLoginState: () => {
            dispatch(resetLoginState());
        },
        getSession: bankIdSuccessData => {
            dispatch(getSession(bankIdSuccessData));
        },
        getCustomerProperty: (customerId, propertyName) => {
            dispatch(getCustomerProperty(customerId, propertyName));
        },
        clearState: function clearState(isReviewMode) {
            return dispatch({
                type: 'CLEAR_STATE',
                isCustomer: true,
                justLoggedOut: false,
                isReviewMode,
            });
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
