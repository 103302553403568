const getState = () => {
    const state = JSON.parse(window.sessionStorage['persist:root']);
    if (state) {
        Object.keys(state).forEach(key => (state[key] = JSON.parse(state[key])));
        return state;
    }
    return { state: 'State could not be found' };
};

const getStateInfo = () => {
    try {
        const state = getState();

        const errors = [];

        const iterate = obj => {
            for (let [key, value] of Object.entries(obj)) {
                if (value && typeof value == 'object') {
                    iterate(value);
                } else {
                    if ((key.match(/.*Error$/) || key.match(/.*IsDone$/) || key.match(/.*Error$/)) && value) {
                        errors.push(key);
                    }
                }
            }
        };

        iterate(state);
        return errors;
    } catch (e) {
        return 'Could not parse state';
    }
};

export const getDebugInfo = () => {
    return {
        state: getStateInfo(),
    };
};
