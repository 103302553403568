import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { isSE, isFI } from '../../../../common/country';
import { getAccounts } from '../../../account/redux/getAccounts';
import { getInvoices } from '../../../invoice/redux/getInvoices';
import { getContracts } from '../../../contract/redux/getContracts';
import {
    mapAccountInvoices,
    mapContractInvoices,
    mapOneOffInvoices,
    separate,
} from '../../../invoice/v2/mapInvoicesv2';
import { getAccountBillOverview } from '../../../account/redux/getAccountBillOverview';

/**
 * Hook to manage common state for AuthenticatedPageTemplate and AuthenticatedSubPageTemplate
 */
export const useAuthenticatedPageTemplateState = () => {
    const dispatch = useDispatch();

    const {
        customerId,
        accounts,
        hasZeroAccounts,
        getAccountsIsDone,
        isDeviceEnrolled,
        getEnrolledDevicesError,
        getEnrolledDevicesPending,
        bills,
        invoices,
        contracts,
        location,
        previousPath,
    } = useSelector(state => {
        const {
            authentication: {
                person: { id: customerId = 0 },
            },
            customer: { getCustomerPropertyPending, getCustomerPropertyError },
            account: { accounts, hasZeroAccounts, accountBillOverview, getAccountsIsDone },
            common: { previousPath },
            secureId,
            invoice: { invoices = [] },
            contract: { contracts },
            router: { location },
        } = state;

        return {
            customerId,
            getCustomerPropertyPending,
            getCustomerPropertyError,
            accounts,
            hasZeroAccounts,
            getAccountsIsDone,
            previousPath,
            isDeviceEnrolled: secureId.isDeviceEnrolled,
            getEnrolledDevicesError: secureId.getEnrolledDevicesError,
            getEnrolledDevicesPending: secureId.getEnrolledDevicesPending,
            bills: accountBillOverview,
            invoices,
            contracts,
            location,
        };
    });

    useEffect(() => {
        let timeout;
        if (isSE()) {
            const isOverviewPage = () => {
                return location.pathname === '/account/overview';
            };

            const fetchDataForMenu = () => {
                dispatch(getAccounts(customerId));
                dispatch(getInvoices(customerId));
                dispatch(getContracts(customerId));
            };

            timeout = setTimeout(() => {
                if (!isOverviewPage()) fetchDataForMenu();
            }, 200);
        }
        return () => clearTimeout(timeout);
    }, [customerId, dispatch, location.pathname]);

    useEffect(() => {
        if (getAccountsIsDone) {
            accounts.forEach(account => {
                dispatch(getAccountBillOverview(customerId, account.reference));
            });
        }
    }, [accounts, customerId, dispatch, getAccountsIsDone]);

    const showSecureIdNotification = () => {
        return (
            // stop showing hamburger notification after 1/5 2024
            dayjs().isBefore('2024-05-01 00:00') &&
            !getEnrolledDevicesError &&
            !getEnrolledDevicesPending &&
            !isDeviceEnrolled
        );
    };

    const showInvoiceNotification = () => {
        if (isFI()) return false;

        const accountInvoices = mapAccountInvoices(bills, accounts);
        const oneOffInvoices = mapOneOffInvoices(invoices);
        const contractInvoices = mapContractInvoices(contracts);

        const allInvoices = [...accountInvoices, ...oneOffInvoices, ...contractInvoices];

        const { unpaidInvoices } = separate(allInvoices);

        return unpaidInvoices.length > 0;
    };

    return {
        showSecureIdNotification: showSecureIdNotification(),
        showInvoiceNotification: showInvoiceNotification(),
        hasZeroAccounts,
        customerId,
        previousPath,
    };
};
